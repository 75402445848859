import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Select from "react-dropdown-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";

import icon14 from '../../../assets/images/star-glyphs-wht.svg';


import { faUser, faClock, faCalendarCheck, faBookmark, faRectangleList, faChartBar,  faEdit } from '@fortawesome/free-regular-svg-icons';
import { faKey, faArrowRightFromBracket,faCheck, faPencil, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Step2 = () => {
  const { t } = useTranslation();
  const options = [
    {
      value: 1,
      label: "Leanne Graham"
    },
    {
      value: 2,
      label: "Ervin Howell"
    }
  ];
  const [startDate, setStartDate] = useState(new Date());
  return <>
    <Container fluid id="main-area">
      <Row className="p-10 main-lft">
        <Col lg={12}>
          <Card className='border-0 p-15'> 
          <div class="award-inner">
          
          <div class="edit-sec">
          I want to send an email for awards and recognitions...
          <span><FontAwesomeIcon icon={faPencil} style={{color:'#000000', fontSize:'20px', marginLeft:'1px'}} /></span>
          </div>
         <div class="award-inner-pad">
          <div class="main-title"><span>K</span> <div class="award-rgt">Please select from the options below</div></div>

          <div class="award-list">
            <div class="award-title">
              <h5><span>1</span>Type of Award</h5>
            </div>
            <div class="award-cnt">
              <ul>
                <li class="active">Employee of the month <span><FontAwesomeIcon icon={faCheck} style={{color:'#F96815', fontSize:'20px', marginLeft:'1px'}} /></span></li>
                <li>Excellence in Leadership</li>
                <li>Innovation Award</li>
                <li>Outstanding Team Achievement</li>
                <li>Customer Service Excellence</li>
                <li>Long Service Award</li>
              </ul>
            </div>  
          </div>

          <div class="award-list">
            <div class="award-title">
              <h5><span>2</span>Reason for Recognition</h5>
            </div>
            <div class="award-cnt">
              <ul>
                <li>Exceptional project delivery</li>
                <li class="active">Significant contributions to company growth <span><FontAwesomeIcon icon={faCheck} style={{color:'#F96815', fontSize:'20px', marginLeft:'1px'}} /></span></li>
                <li>Going above and beyond</li>
                <li>Consistent high performance</li>
                <li>Demonstrating company values</li>
                <li>Inspiring collaboration and teamwork</li>
              </ul>
            </div>  
          </div>

          <div class="award-list">
            <div class="award-title">
              <h5><span>3</span>Event/Presentation Context</h5>
            </div>
            <div class="award-cnt">
              <ul>
                <li class="active">Internal email announcement <span><FontAwesomeIcon icon={faCheck} style={{color:'#F96815', fontSize:'20px', marginLeft:'1px'}} /></span></li>
                <li>Company-wide event or ceremony</li>
                <li>Team or department meeting</li>
                <li>Annual performance review</li>
                <li>Special recognition day/week</li>
              </ul>
            </div>  
          </div>
          </div>
          <div class="show-btn"><button type="button" class="btn btn-primary ideate-btn active-btn">Show Plot Cards <span> <img src={icon14} alt="" /> </span></button></div>
          </div> 
          </Card>
        </Col>
      </Row>
    </Container></>;
};

export default Step2; 