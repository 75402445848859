import { compareAsc, format } from "date-fns";
import { getTokenInfo } from '../helpers/jwt-token-access/auth-token';
import { useTranslation } from "react-i18next";


const statusList = { 0 : 'Active', 1 : 'In-Active' }; 
const statusListLabel = { 0 : 'activate',1 : 'inactivate' }; 

//const statusList = t('statusList'); 
/*
const statusEnumList = { 'in-active': false, 'active': true }; 
const messageList = { 
                     'process_message':'Please wait. Your request is processing...',
                     'create_message':'{NAME} has been created successfully',
                     'updated_message':'{NAME} has been updated successfully',
                     'delete_message':'{NAME} has been deleted successfully',
                     'active_message':'{NAME} has been activated successfully',
                     'in_active_message':'{NAME} has been inactivated successfully',
                     'confirm_message':'Are you sure you want to {ACTION-NAME} the {NAME}?',
                    }; 
*/

export const searchTimeoutTime = 300;
export const pageLimit = 15;
export const PaginationList = [{id:15,name:'15'},{id:25,name:'25'},{id:50,name:'50'},{id:100,name:'100'}];
export const defaultPaginationList = PaginationList.find(item => item.id === pageLimit);
export const pageRangeDisplayed = 5;
export const defaultSortingData = { "action":'sorting',"sort_name":'',"sort_type":'normal' };
export const defaultFilterRequest = { "pageNumber": 1, "pageSize": pageLimit, "searchTerm": "", "sortColumn": "", "sortDirection":""};

export const manpowerRequisitionStatusList = [{id:1,name:'Open'},{id:2,name:'Waiting for Approval'},{id:3,name:'On Hold'},{id:4,name:'Approved'},{id:5,name:'Rejected'}];

export const getMessage = ( msg, data='') => { 
    msg = data && data.name ? msg.replace("{NAME}", data.name) : msg;
    if(data && (data.status===0 || data.status===1))
    {
        msg = data.status===0 ? msg.replace("{ACTION-NAME}", statusLabel(1,true)) : msg.replace("{ACTION-NAME}", statusLabel(0,true));
    }else if(data && data.delete){
        msg = msg.replace("{ACTION-NAME}", data.delete);
    }    
    return msg;
}

export const findReplaceText = (text,findText,relaceText) => {
    return text.replace(findText, relaceText);
}

export const getValidationRequiredMessage = ( msg, data='') => { 
    msg = data && data.name ? msg.replace("{NAME}", data.name) : msg;    
    return msg;
}

export const getValidationCharacterLimitMessage = ( msg, data='') => { 
    msg = data && data.name ? msg.replace("{NAME}", data.name) : msg;   
    msg = data && data.limit ? msg.replace("{LIMIT}", data.limit) : msg;  
    return msg;
}

export const statusLabel = ( status, labelOnly=false ) => {
    //const { t } = useTranslation();
    //console.log(bActive);
    //const statusList = t('statusList');  
    let label = statusList[status];
    if(labelOnly===true)
    {
        return statusListLabel[status];
    }
    return <span className={( status===0 ? 'pt-1 pb-1 pl-10 pr-10 rounded bg-success text-bg-primary pl-5 w-auto fw-medium' : 'pt-1 pb-1 pl-10 pr-10 rounded bg-danger text-bg-primary pl-5 w-auto fw-medium')}>{label}</span>;
}

export const taskStatus = ( status, color_code, labelOnly=false ) => {
    if(labelOnly===true)
    {
        return status;
    }
    return <span class="pt-1 pb-1 pl-10 pr-10 rounded  pl-5 w-auto fw-medium" style={{color:'#fff',backgroundColor: color_code}}>{status}</span>;
}

export const priorityLabel = ( priority, color_code, labelOnly=false ) => {
    if(labelOnly===true)
    {
        return priority;
    }
    return <span class="pt-1 pb-1 pl-10 pr-10 rounded  pl-5 w-auto fw-medium" style={{color:'#fff',backgroundColor: color_code}}>{priority}</span>;
}

export const manpowerRequisitionStatusLabel = ( currentStatus, labelOnly=false ) => {
    //currentStatus = currentStatus === undefined ? currentStatus=1 : currentStatus;
    //console.log('----'+currentStatus);
    /*
    const label = manpowerRequisitionStatusList[currentStatus];
    if(labelOnly===true)
    {
        return label;
    }
    return <span className={( currentStatus===1 ? 'pt-1 pb-1 pl-10 pr-10 rounded bg-success text-bg-primary pl-5 w-auto fw-medium' : 'pt-1 pb-1 pl-10 pr-10 rounded bg-danger text-bg-primary pl-5 w-auto fw-medium')}>{label}</span>;
    */

    let currentStatusLebel = manpowerRequisitionStatusList.find(x => x.id === currentStatus).name;
    return currentStatusLebel;
}

export const formatDate = ( dateInput ) => { 
    let formtdate = 'NA';
    if(dateInput)
    {
        //formtdate = format(new Date(dateInput), "MMM dd, yyyy");
        formtdate = format(new Date(dateInput), getTokenInfo().Date_Format);        
    }    
    return formtdate;
}

export const dateConvertToStoreFormat = ( dateInput ) => { 
    let formtdate = 'NA';
    if(dateInput)
    {
        formtdate = format(new Date(dateInput), "yyyy-MM-dd");        
    }    
    return formtdate;
}

export const dateTimeConvertToStoreFormat = ( dateInput ) => { 
    let formtdate = 'NA';
    if(dateInput)
    {
        formtdate = format(new Date(dateInput), "yyyy-MM-dd HH:mm:ss");        
    }    
    return formtdate;
}

export const getHoursMinutes  = ( dateInput ) => { 
    let formtdate = 'NA';
    if(dateInput)
    {
        formtdate = format(new Date(dateInput), "HH:mm");        
    }    
    return formtdate;
}

export const copyObject = (obj) => {
    return JSON.parse(JSON.stringify(obj,null,4)); 
}

export const addDoubleQuotesAroundKeys = (originalString) => {
    var objKeysRegex = /({|,)(?:\s*)(?:')?([A-Za-z_$\.][A-Za-z0-9_ \-\.$]*)(?:')?(?:\s*):/g;// look for object names
    return originalString.replace(objKeysRegex, "$1\"$2\":")
}


export const setPageSize = (obj,pageSize) => {
    obj.pageSize = pageSize;
    return obj; 
}

export const setPageNumber = (obj,pageNumber) => {
    obj.pageNumber = pageNumber; 
    return obj;
}

export const currentDate = () => {
    return new Date();
}

export const firstDateOfMonth = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export const culturalReferences = () => {
  //return ['Greek Mythology','Popular Books','Nature','Well-Known Movies','Pop Culture'];
  return ['Greek Mythology','Popular Books','Nature','Well-Known Movies','Pop Culture'];
}

/*
export const additionalCulturalReferences = () => {
    //return ['Greek Mythology','Popular Books','Nature','Well-Known Movies','Pop Culture'];
    return ['Popular Books','Nature','Well-Known Movies','Pop Culture'];
}
*/

export const quoteList = () => {
    //return ['Greek Mythology','Popular Books','Nature','Well-Known Movies','Pop Culture'];
    return ["Hold onto your seats. <br><b>The seismic shift is about to begin.<span></span></b>",
            "kathai. It's coming. <br><b>And it's rewriting the rules.<span></span></b>",
            "To write or not to write? <br><b>Let kathai do it right.<span></span></b>",
            "HR emails aren't just information. <br><b>They are a conversation.<span></span></b>",
            "Because HR emails deserve <b>a story worth reading.<span></span></b>",
            "Scientific studies show zero <b>yawns detected with kathai emails!<span></span></b>"
        ];
}

export const shuffleArrayValues = (contentArray) => {
    let shuffleArrayContent = contentArray;
    let currentIndex = contentArray.length;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [shuffleArrayContent[currentIndex], shuffleArrayContent[randomIndex]] = [
        shuffleArrayContent[randomIndex], shuffleArrayContent[currentIndex]];
    }
    return shuffleArrayContent;
}
  
export const getKeyByValue = (object, value) => {  
    console.log('Innnnn');
    console.log(object[value]);
    console.log(value);
    return Object.keys(object).find(key => object[key] === value);
}

export const getSessionID = () => {
    return localStorage.getItem("kathaisession");
};

/*
import { compareAsc, format } from "date-fns";
import { getTokenInfo } from '../helpers/jwt-token-access/auth-token';
import { useTranslation } from "react-i18next";

export const Config = props => {
    const statusList = { false : 'In-Active', true : 'Active' }; 
    //const statusList = t('statusList'); 
    const statusEnumList = { 'in-active': false, 'active': true }; 
    const messageList = { 
                        'process_message':'Please wait. Your request is processing...',
                        'create_message':'{NAME} has been created successfully',
                        'updated_message':'{NAME} has been updated successfully',
                        'delete_message':'{NAME} has been deleted successfully',
                        'active_message':'{NAME} has been activated successfully',
                        'in_active_message':'{NAME} has been de activated successfully',
                        'confirm_message':'Are you sure, do you want to {ACTION-NAME} the {NAME}?',
                        }; 

    const searchTimeoutTime = 300;
    const pageLimit = 15;
    const PaginationList = [{id:15,name:'15'},{id:25,name:'25'},{id:50,name:'50'},{id:100,name:'100'}];
    const defaultPaginationList = PaginationList.find(item => item.id === pageLimit);
    const pageRangeDisplayed = 5;
    const defaultSortingData = { "action":'sorting',"sort_name":'',"sort_type":'normal' };
    const defaultFilterRequest = { "pageNumber": 1, "pageSize": pageLimit, "searchTerm": "", "sortColumn": "", "sortDirection":""};

    var getMessage = function ( msg, data='') { 
        //let msg = messageList[msgCode];
        msg = data && data.name ? msg.replace("{NAME}", data.name) : msg;
        msg = data && data.bActive ? msg.replace("{ACTION-NAME}", statusLabel(data.bActive)) : msg;
        msg = data && data.delete ? msg.replace("{ACTION-NAME}", data.delete) : msg;    
        return msg;
    }
    
    var findReplaceText = function (text,findText,relaceText) {
        return text.replace(findText, relaceText);
    }
    
    var getValidationRequiredMessage = function( msg, data='') { 
        //let msg = messageList[msgCode];
        msg = data && data.name ? msg.replace("{NAME}", data.name) : msg;    
        return msg;
    }
    
    var statusLabel = function( bActive, labelOnly=false ){
        //const { t } = useTranslation();
    
        //const statusList = t('statusList');  
        const label = statusList[bActive];
        if(labelOnly===true)
        {
            return label;
        }
        return <span className={( bActive===true ? 'pt-1 pb-1 pl-10 pr-10 rounded bg-success text-bg-primary pl-5 w-auto fw-medium' : 'pt-1 pb-1 pl-10 pr-10 rounded bg-danger text-bg-primary pl-5 w-auto fw-medium')}>{label}</span>;
    }
    
    var formatDate = function ( dateInput ) { 
        let formtdate = 'NA';
        if(dateInput)
        {
            //formtdate = format(new Date(dateInput), "MMM dd, yyyy");
            formtdate = format(new Date(dateInput), getTokenInfo().Date_Format);        
        }    
        return formtdate;
    }
    
    var copyObject = function (obj) {
        return JSON.parse(JSON.stringify(obj,null,4)); 
    }
    
    var addDoubleQuotesAroundKeys = function (originalString) {
        var objKeysRegex = /({|,)(?:\s*)(?:')?([A-Za-z_$\.][A-Za-z0-9_ \-\.$]*)(?:')?(?:\s*):/g;// look for object names
        return originalString.replace(objKeysRegex, "$1\"$2\":")
    }
    
    
    var setPageSize = function (obj,pageSize) {
        obj.pageSize = pageSize;
        return obj; 
    }
    
    var setPageNumber = function(obj,pageNumber) {
        obj.pageNumber = pageNumber; 
        return obj;
    }
}
*/