import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate  } from "react-router-dom";

//import PoppinsFont from './assets/fonts/Poppins-Regular.woff2';

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout";
import AuthLayout from "./components/AuthLayout";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import { AuthProvider } from "./components/Context/AuthContext";
import { PermissionsProvider  } from './components/PermissionsContext';
import { getTokenInfo,removeAuthToken } from './helpers/jwt-token-access/auth-token';
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';

import Clarity from '@microsoft/clarity';

import './App.css';

//Below code deployment
//<Router basename="/frontend"> - Relace it in <Router> in the same page 
//"homepage" : "/frontend", - add next to name in package.json
//console.log('token');
//console.log(localStorage.getItem("token"));

/*
let permissions = {};
if(localStorage.getItem("token"))
{
  permissions = getTokenInfo()?.rules;  
}
*/



const permissions = {
  resource_access:1
  // Add other permissions here
};

function App() {  

  /*
  // ---------------- Disable Copy Code Start Here ------------------------
  //Disable Right Click
  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
  }, []);

  //Disable Keyboard Shortcuts
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.ctrlKey && (event.key === "u" || event.key === "c" || event.key === "Shift")) || 
        event.key === "F12"
      ) {
        event.preventDefault();
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //Disable Copy and Cut Events
  useEffect(() => {
    const disableCopyCut = (event) => event.preventDefault();
  
    document.addEventListener("copy", disableCopyCut);
    document.addEventListener("cut", disableCopyCut);
  
    return () => {
      document.removeEventListener("copy", disableCopyCut);
      document.removeEventListener("cut", disableCopyCut);
    };
  }, []);
  // ---------------- Disable Copy Code End Here ------------------------
  */

  useEffect(() => {
    clearCache();
    //Clarity.init(process.env.REACT_APP_CLARITY_PROJECT_ID);      
    //Clarity.init("q93w3tq3xg");
    Clarity.init("qbqivq3f7l");
  }, []); 
  
  const clearCache = () => {
       
    const scripts = document.querySelectorAll('script');
    const links = document.querySelectorAll('link[rel="stylesheet"]');
    const images = document.querySelectorAll('img');
  
    [scripts, links, images].forEach(nodes => {
      nodes.forEach(node => {
        const src = node.getAttribute('src') || node.getAttribute('href');
        if (src) {
          node.setAttribute('src', `${src}?v=${new Date().getTime()}`);
        }
      });
    });
  };
    
  //const clientId = "423633483595-i5736vs2eupcu631k0g53pnre5eah8b2.apps.googleusercontent.com";
  //const clientId = "423633483595-ucv6udj2v0m72ntvq8phmcvnh32sgvsk.apps.googleusercontent.com"; // Fr0m Vasu's gmail
  const clientId = "1043308588064-17lc5fqd0mm7dq1d3mu7pmmhun87o0us.apps.googleusercontent.com";

  //const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;  

  //console.log('----------------clientId-------------------');
  //console.log(clientId);

  return (  
    <> 
    {
      /*
      <style>
        {`
          @font-face {
            font-family: 'Poppins-Regular';
            src: url(${require('./assets/fonts/Poppins-Regular.woff2')}) format('woff2');
            font-display: swap;
          }
          
          body {
            font-family: 'Poppins-Regular', sans-serif;
          }
        `}
      </style>
    
      <style>
        {`
          @font-face {
            font-family: 'Poppins-Regular';
            src: url(${PoppinsFont}) format('woff2');
            font-display: swap;
          }
          body {
            font-family: 'Poppins-Regular', sans-serif;
          }
        `}
      </style>
      */
    }    
    <React.Fragment>
      <Router basename="/">
        <Routes>                     
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={ 
                <GoogleOAuthProvider clientId={clientId}>  
                  <NonAuthLayout>
                    <route.component />
                  </NonAuthLayout>
                </GoogleOAuthProvider>  
              }
              key={idx}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            
            <Route
              path={route.path}
              element={   
                <AuthProvider>  
                  <GoogleOAuthProvider clientId={clientId}>    
                    <PermissionsProvider permissions={permissions}>
                      <AuthLayout>
                        <route.component />
                      </AuthLayout>
                    </PermissionsProvider>
                  </GoogleOAuthProvider>  
                </AuthProvider>  
              }
              key={idx}
            />
          ))}
        </Routes>
      </Router>
    </React.Fragment>  
    </> 
  );
}

export default App;
