import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Select from "react-dropdown-select";
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";

import { faUser, faClock, faCalendarCheck, faBookmark, faRectangleList, faChartBar, faHeart, faCopy,  faEdit } from '@fortawesome/free-regular-svg-icons';
import { faKey, faArrowRightFromBracket,faCheck, faPencil, faChevronLeft, faArrowRotateRight, faShareFromSquare, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Step5 = () => {
  const { t } = useTranslation();
  const options = [
    {
      value: 1,
      label: "Leanne Graham"
    },
    {
      value: 2,
      label: "Ervin Howell"
    }
  ];
  const [startDate, setStartDate] = useState(new Date());
  return <>
    <Container fluid id="main-area">
      <Row className="p-10 main-lft">
        <Col lg={12}>
          <Card className='border-0 p-15'> 
          
          <div class="award-inner">
          
          <div class="edit-sec">
          I want to send an email for awards and recognitions...
          <span><FontAwesomeIcon icon={faPencil} style={{color:'#000000', fontSize:'20px', marginLeft:'1px'}} /></span>
          </div>
         <div class="award-inner-pad">
          <div class="main-title"><span>K</span>Here's a sample email for an awards and recognition event using the "Athena’s Counsel" theme. </div>

          <Scrollbars className="scroll-div">
            <div class="subject-sec">
              <div class="tone-lst">
                <h3>Tone :</h3>
                <ul>
                  <li class="active">Celebratory and Motivational</li>
                  <li>Formal and Professional</li>
                  <li>Heartfelt and Appreciative</li>
                  <li>Inspirational and Reflective</li>
                </ul>
              </div>

              <div class="subject-cnt">
                <h6>Subject: Honoring Courage and Determination: The Gryffindor Award for [Employee's Name]!</h6>
                <h5>Dear Team,</h5>
                <p>As we continue to recognize the outstanding contributions of our team members, we are excited to introduce a new creative theme for this month’s Employee of the Month award—The Gryffindor Award. Inspired by the courageous and determined characters of Gryffindor House in Harry Potter, this award highlights the bravery, resilience, and unwavering determination shown by an exceptional employee who stands strong in the face of challenges and adversity.</p>
                <p>Today, we are proud to present <span>[Employee’s Name]</span> with The Gryffindor Award, recognizing their incredible strength, courage, and dedication to our company. Much like the members of Gryffindor House, [Employee’s Name] has consistently demonstrated bravery and resilience, rising to every challenge with a spirit that inspires everyone around them.</p>
                <p>Through their determination and leadership, [Employee’s Name] has not only overcome difficult obstacles but has also led the team through complex projects with confidence and composure. Whether it was [mention specific project or challenge] or <span>[mention another key contribution], [Employee’s Name]</span> showed the kind of fortitude that drives our team forward and sets a shining example for others.</p>
                <p>This recognition is more than just an acknowledgment of hard work—it’s a celebration of the courage that <span>[Employee’s Name] </span> embodies daily. Just as Gryffindors stand firm in their values and rise to every occasion, <span>[Employee’s Name]</span> has led with heart and conviction, making them a true hero of our team. Please join me in congratulating , <span>[Employee’s Name]</span> for their well-deserved recognition as our Gryffindor Award recipient! Their courage and determination are an inspiration to us all, and we look forward to many more successes together.</p>
              </div>
            </div>
          </Scrollbars>

          </div>
            <div class="show-btn full-width">
            <div class="show-lft"><button type="button" class="btn btn-primary ideate-btn">Go back <span><FontAwesomeIcon icon={faChevronLeft} style={{color:'#fff', fontSize:'14px', marginLeft:'5px'}} /></span></button></div>
            <div class="show-rgt">
               <button type="button" class="btn btn-primary share-btn"><span><FontAwesomeIcon icon={faHeart} style={{color:'#000', fontSize:'14px'}} /></span></button>
               <button type="button" class="btn btn-primary share-btn"><span><FontAwesomeIcon icon={faShareFromSquare} style={{color:'#000', fontSize:'14px'}} /></span></button>
               <button type="button" class="btn btn-primary share-btn"><span><FontAwesomeIcon icon={faArrowRotateRight} style={{color:'#000', fontSize:'14px'}} /></span></button>
              <button type="button" class="btn btn-primary ideate-btn">Copy <span><FontAwesomeIcon icon={faCopy} style={{color:'#fff', fontSize:'14px', marginLeft:'5px'}} /></span></button></div>
            </div>
          </div> 
            
          </Card>
        </Col>
      </Row>
    </Container></>;
};

export default Step5; 