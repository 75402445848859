let subUrl = "";
var serviceUrl = "";

serviceUrl = "/master";

// Get All Privileges
export const GET_USER_PRIVILEGES = serviceUrl + subUrl + "/role/privileges";

// Get All Role Type
export const GET_ROLE_TYPE = serviceUrl + subUrl + "/role/get-role-type";

// Get All Country
export const GET_COUNTRY = serviceUrl + subUrl + "/country/all";

// Get All State
export const GET_STATE = serviceUrl + subUrl + "/state/all";

// Get All City
export const GET_CITY = serviceUrl + subUrl + "/city/all";

// Get All Service
export const GET_SERVICE = serviceUrl + subUrl + "/service/all"; 

// Get All Question Response Format
export const GET_QUESTION_RESPONSE_FORMAT = serviceUrl + subUrl + "/question/question-response-format";

// Get All Question Response Type
export const GET_QUESTION_RESPONSE_TYPE = serviceUrl + subUrl + "/question/question-response-type";

// Get All Modules for Reference Definition Headers
export const GET_QUESTION_DROPDOWN_SELECTION_TYPE = serviceUrl + subUrl + "/question/question-dropdown-selection-type";

// Get All Modules for Reference Definition Headers
export const GET_QUESTIONS = serviceUrl + subUrl + "/question/get-questions";

// Get All Modules for Reference Definition Headers
export const GET_DEPARTMENT = serviceUrl + subUrl + "/department/all";

// Get All Modules for Reference Definition Headers
export const GET_EMPLOYEE_TYPE = serviceUrl + subUrl + "/employee-type/all";

// Get All Modules for Reference Definition Headers
export const GET_DESIGNATION = serviceUrl + subUrl + "/designation/all";

// Get All Modules for Reference Definition Headers
export const GET_ROLE = serviceUrl + subUrl + "/role/all";

// Get All Modules for Reference Definition Headers
export const GET_LOCATION = serviceUrl + subUrl + "/location/all";

// Get All Modules for Reference Definition Headers
export const GET_CLIENT = serviceUrl + subUrl + "/client/all";

//TASK TYPE
export const GET_TASK_TYPE = serviceUrl + subUrl + "/task-type/all";

//TASK CATEGORY
export const GET_TASK_CATEGORY = serviceUrl + subUrl + "/task-category/all";

//TASK NATURE
export const GET_TASK_NATURE = serviceUrl + subUrl + "/task-nature/all";

//TASK PRIORITY
export const GET_TASK_PRIORITY = serviceUrl + subUrl + "/task-priority/all";

//REQUEST MODE
export const GET_REQUEST_MODE = serviceUrl + subUrl + "/request-mode/index";

//GET ALL USERS
export const GET_USER = serviceUrl + subUrl + "/user/all";

//Project Status
export const GET_PROJECT_STATUS = serviceUrl + subUrl + "/project-status/all";

//Task Status
export const GET_TASK_STATUS = serviceUrl + subUrl + "/task-status/all";

//Project Type
export const GET_PROJECT_TYPE = serviceUrl + subUrl + "/project-type/all";

//Task Point
export const GET_TASK_POINT = serviceUrl + subUrl + "/task-point/all";

//Task Related To
export const GET_TASK_RELATED_TO = serviceUrl + subUrl + "/task-related-to/all";

//Service based on client
export const GET_SERVICE_BASED_ON_CLIENT = serviceUrl + subUrl + "/client/service";

//Service based on client
export const GET_LOCATION_BASED_ON_SERVICE = serviceUrl + subUrl + "/location/location-based-on-service";

//Task Point
export const GET_SHIFT_TYPE = serviceUrl + subUrl + "/shift-type/all";


serviceUrl = "/project";

// Get All Modules for Reference Definition Headers
export const GET_PROJECT = serviceUrl + subUrl + "/project/all";

export const GET_PROJECT_ASSIGN_TO = serviceUrl + subUrl + "/project/project-assign-to";

export const GET_PROJECT_BASED_FILTER = serviceUrl + subUrl + "/project/project-based-filter";

export const GET_PROJECT_TASK_DATA = serviceUrl + subUrl + "/project-task/task-data";

export const GET_PROJECT_TASK_BASED_FILTER = serviceUrl + subUrl + "/project-task/task-based-filter";

export const GET_PROJECT_TASK_ASSIGN_TO = serviceUrl + subUrl + "/project-task/task-assign-to";

export const GET_PROJECT_MILESTONE = serviceUrl + subUrl + "/project-milestone/project-based-milestone";

export const GET_PROJECT_MODULE = serviceUrl + subUrl + "/project-module/project-based-module";


// Get All Reference Definition Details based on Code for Employee
export const GET_REFERENCE_DEFINITION_DETAIL_BY_REF_DEF_CODE = serviceUrl + subUrl + "/RefDefinitionDtl/GetRefDefinitionDtlByRefDefCode";

// Get All Modules for Reference Definition Headers
export const GET_MODULES = serviceUrl + subUrl + "/Module/GetModules";

// Get Job Description for Manpower Requisition
export const GET_JOB_DESCRIPTION = serviceUrl + subUrl + "/JobDescription/GetJobDescriptions";

// Get All Reference Definition Headers for Left Menu
export const GET_REFERENCE_DEFINITION_HEADERS = serviceUrl + subUrl + "/RefDefinitionHdr/GetRefDefinitionHdrs";

serviceUrl = "/employee";
// Get All Employee for User Type
export const GET_EMPLOYEES =  serviceUrl + subUrl + "/Employee/GetEmployee";

serviceUrl = "/recruitment";
//INTERVIEW PANEL
export const GET_INTERVIEW_PANELS = serviceUrl + subUrl + "/InterviewPanel/GetInterviewPanels";

serviceUrl = "/site";

export const CONTACT_US = serviceUrl + subUrl + "/contact-us";

