import React from "react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotFound = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/');
  }

  return <>
    <Container fluid id="main-area">
      <Row className="p-10">

        <Row className="pt-15">
          <Col lg={12}>
            <Card className='border-0 p-15'>
              <div id="notfound">
                <div class="notfound">
                  <div class="notfound-404">
                    <div></div>
                    <h1>404</h1>
                  </div>
                  <h2>Page not found</h2>
                  <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                  <a href="#" onClick={() => handleRedirect()}>home page</a>
                </div>
              </div>
             {/*  <h2 class="title"><strong>404 Page Error</strong></h2>
              <Row className="pt-20">
                <Col lg={12}>
                  <Card className="shadow-sm mb-4">
                    <Card.Body>
                      <Card.Text className="text-secondary">
                        <Row className="mb-10 text-center">
                          <Col xs={12}><strong>OPPS! PAGE NOT FOUND</strong> </Col>
                          <Col xs={12}>Sorry, the page you're looking for doesn't exist.</Col>
                          <Col xs={12}>You may have mistyped the address or the page may have moved.</Col>
                        </Row>
                        <Row className="mb-10 text-center">
                          <Col xs={12}>
                            <Button variant="primary" className="ml-10 mr-10" onClick={() => handleRedirect()}><FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} /> GO HOME </Button>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row> */}
            </Card>
          </Col>
        </Row>
      </Row>
    </Container>
  </>;
};

export default NotFound; 