import React, { useState, useEffect, useRef } from 'react';
import { Link, BrowserRouter, useNavigate, useLocation  } from "react-router-dom";
import { post } from '../../helpers/api/api_helper.js';
import * as url from '../../helpers/api-url/common_url_helper';
import { usePermissions } from '../../components/PermissionsContext';
import { useTranslation } from "react-i18next";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { removeAuthToken } from '../../helpers/jwt-token-access/auth-token';

import { faUser, faClock, faCalendarCheck, faBookmark, faRectangleList, faChartBar, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faKey, faArrowRightFromBracket, faHouse, faClockRotateLeft, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import icon1 from '../../assets/images/menu-1.svg'
import icon2 from '../../assets/images/menu-2.svg'
import icon3 from '../../assets/images/menu-3.svg'
import icon4 from '../../assets/images/menu-4.svg'
import icon5 from '../../assets/images/menu-5.svg'
import clientIcon from '../../assets/images/client.png'
import icon6 from '../../assets/images/menu-6.svg'
import logosvg from '../../assets/images/kathai-logo.svg'
import moblogosvg from '../../assets/images/mob-logo.svg'
import icon11 from '../../assets/images/close.svg'
import icon21 from '../../assets/images/contact-icon-h.svg'
import icon22 from '../../assets/images/hme-icon-h.svg'
import icon23 from '../../assets/images/like-icon-h.svg'



const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { hash, pathname, search } = location;
  let pathnamesplit = pathname.split("/");
  const permissions = usePermissions();
  
  const [selectedItem, setSelectedItem] = useState();
  const [selectedChildItem, setSelectedChildItem] = useState();
  const [selectedInnerChildItem, setSelectedInnerChildItem] = useState();
  
  const [miscellaneousTypeListData, setMiscellaneousTypeListData] = useState([{}]);

  const selectedItemRef = useRef(selectedItem);
  const miscellaneousTypeListDataRef = useRef(miscellaneousTypeListData);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //addBodyClass();
  });

  const toggleSubmenu = () => {
    setIsOpen(!isOpen);
  };

  const addBodyClass = () => { 
    var presentOpenClass = document.body.classList.contains('open');
    if(!presentOpenClass)
    {
      document.body.classList.remove('no-sidebar');      
      document.getElementById("head-lft-logo-div").style.display = "none";
    }
    document.body.classList.toggle('open'); // Add the class to the body    
  }

  const handleItemClick = (index) => {    

    setSelectedItem(index);     
    navigate("/"+index);  

    /*
    if(index=='home')
    {
      setSelectedItem(index);     
      navigate("/html/step1");      
    }
    */

    /*
    if(selectedItem===index ){
      addBodyClass();         
    }else if(selectedItem!==index){      
      if(document.body.classList.contains('open'))
      {
        addBodyClass();
        //document.body.classList.add('open');        
      }      
    }

    setSelectedItem(index);     
    */   
  };

  const handleChildItemClick = (data) => {
    setSelectedChildItem(data);
    navigate("/" + data);
  };

  const handleInnerChildItemClick = (data) => {
    let citem = data;
    if(selectedInnerChildItem)
    {
      citem='';
    }
        
    setSelectedInnerChildItem(citem);
  }  

  const fetchData = (arg) => {
    //let mTLDSize = Object.keys(miscellaneousTypeListData).length;
    //if(mTLDSize<=1)
    //{
    if(arg==1)
    {
      setIsLoading(true);
      const res = post(url.GET_REFERENCE_DEFINITION_HEADERS);
      res.then((d) => {
        setMiscellaneousTypeListData(d['data']);
        setIsLoading(false);
      });
    }
    //}
  };

  const handleSignout = (index) => {
    //localStorage.removeItem('token')
    if (window.confirm("Are you sure do you want to Logout?"))
    {
      removeAuthToken();
      navigate("/login");
    }
  };

  useEffect(() => {
    let sltItem = pathnamesplit[1];
    let sltInnerChildItem = '';
    setSelectedItem(sltItem);
    
    if(pathnamesplit.length>2){
      let subMenuURL = pathname.substring(1);
      setSelectedChildItem(subMenuURL);
      sltInnerChildItem = pathnamesplit[2];
      //setSelectedInnerChildItem(sltInnerChildItem);      
      //toggleSubmenu();
    }else{
      setSelectedChildItem(sltItem);
    }
    
   

  }, [selectedItemRef, miscellaneousTypeListDataRef,location.key]);

  return (
    <div class="sidebar shadow">
      <div class="head-lft">
        <div class="logo">
            <img src={logosvg} class="desktop" alt="" />
            <img src={moblogosvg} class="mob" alt="" />            
        </div>              
      </div>
      
      <div class="main-bar">
        <div class="m-icons">
          <ul>
            <li key="dashboard" onClick={() => handleItemClick('dashboard')} class={selectedItem=='dashboard' ? "active" : ""}><a href="javascript:void(0);" title='Home'><div class="hme-icon"></div><span>Home</span></a></li>
            <li key="favourites" onClick={() => handleItemClick('favourites')} class={selectedItem=='favourites' ? "active" : ""}><a href="javascript:void(0);" title='Favourites'><div class="like-icon"></div><span>Favourites</span></a></li>
            <li key="contact" onClick={() => handleItemClick('contact-us')} class={selectedItem=='contact-us' ? "active" : ""}><a href="javascript:void(0);" title='Contact Me'><div class="contact-icon"></div><span>Contact Me</span></a></li>
            {
              /*
               {
                (permissions?.privileges?.home || permissions?.resource_access==1) && 
                <li key="home" onClick={() => handleItemClick('home')} className={'home' === selectedItem ? 'active' : ''}>
                  <a href="javascript:void(0);" title={t('left_menu.home.title.text')}>  <img src={icon1} alt="" /></a>              
                </li>
              }

              {
                (permissions?.privileges?.employee_management || permissions?.resource_access==1) && 
                <li key="user" onClick={() => handleItemClick('history')} className={'history' === selectedItem ? 'active' : ''}>
                  <a href="javascript:void(0);" title={t('left_menu.history.title.text')}>  <img src={icon5} alt="" /></a>              
                </li>
              }

              {
                (permissions?.privileges?.client_management || permissions?.resource_access==1) && 
                <li key="client" onClick={() => handleItemClick('favourites')} className={'favourites' === selectedItem ? 'active' : ''}>
                  <a href="javascript:void(0);" title={t('left_menu.favourites.title.text')}>  <FontAwesomeIcon icon={faUser} style={{color:'#ffffff', fontSize:'22px', marginTop:'7px',marginLeft:'1px'}} /></a>
                </li>
              } 
              */
            }
                     

          </ul>
        </div>
        <div className="menu">
          <a href='javascript:void(0);' onClick={addBodyClass}><span></span></a>
        </div>
      </div>
      <div class="det-bar">
        <div className="content">

          <div class="m-cnt">
            <h2>Home</h2>
            <ul>
              <li>
                <a href="javascript:void(0);" key="steps"  title={"Home"} className={'active'}>Home</a>
              </li>
            </ul>
          </div>           
          
        </div>
      </div>

    </div>
  )
}

export default Navbar;