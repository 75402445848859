import React, { useState, useRef, Suspense, useEffect  } from "react";
import { useParams } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import bootstrapForm from 'react-bootstrap/Form';
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';

import { useFormik, Field, Formik, Form  } from 'formik';
import * as Yup from 'yup';

import * as url from '../../helpers/api-url/authentication_url_helper';
import * as auth_url from '../../helpers/api-url/authentication_url_helper';
import { encrypt,decrypt } from "../../common/Crypto";
import { post } from '../../helpers/api/api_helper.js';
import { getMessage } from "../../common/Config";
import icon01 from '../../assets/images/contact-arw.svg';
import { useAuth } from "../../components/Context/AuthContext";
import { setAuthToken } from '../../helpers/jwt-token-access/auth-token';

import loadingicon from '../../assets/images/loadinggif.gif';

const AccountActivation = () => {

  const { isAuthenticated, login, logout } = useAuth();
  const { key } = useParams();
  const [showError, setShowError] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
    
  useEffect(() => {
    setSuccessMessage();
    setErrorMessage();
    setIsLoading(true);
    document.body.classList.remove('no-sidebar');
    let values = {'activation_key':key};      
    values.action = 'Val1DaTeLinkforCCCE@Katha12025';
    const encryptData = encrypt(values);      
    const res = post(auth_url.VALIDATE_LINK,JSON.parse(encryptData.toString()));
    res.then((data) => {
      const responseData = decrypt(data);
      const responseDetails = JSON.parse(responseData);
      if(responseDetails.status==1)
      {
        setAuthToken(responseDetails.token);
        login();
        setSuccessMessage(responseDetails.message);
      }else{
        setErrorMessage(responseDetails.message);
      }

      setTimeout(function(){        
        setIsLoading(false);
      },1000);      
    });
        
  }, []);

  return <>
  <Container fluid id="main-area">
      <div className="p-10">        
        <Col lg={12}>
          <div className="get-touch">  
            <h2>Account Verification/Activation <span><img src={icon01} alt="" /></span></h2>

            
            {
              !isLoading && successMessage &&
              <div className="pt-50">
                <div class="alert alert-success" role="alert">{successMessage}</div>
              </div>
            }

            {
              !isLoading && errorMessage &&  
              <div className="pt-50">            
                <div class="alert alert-danger" role="alert">{errorMessage}</div>
              </div>
            }  
            {
              isLoading &&                   
              <div class="award-inner-pad">
                <div class="main-title mb-0">
                  <div class="loader-sec">
                  <div class="loading">
                      <img src={loadingicon} alt="kath.ai" />
                  </div>
                  <div class="loading-cnt">
                    <p>Please wait, your request is being processed...</p>
                  </div>
                  </div>
                </div>
              </div>
            }
          </div>     
        </Col>          
      </div>
    </Container>    
    </>;
};

export default AccountActivation; 