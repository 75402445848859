import { createContext, useContext, useState, useEffect,useCallback  } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// Create Auth Context
const AuthContext = createContext();

const SESSION_DURATION = 30 * 60 * 1000; // 30 minutes // 1 minute = 60,000

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tokenInfo, setTokenInfo] = useState({});
  const navigate = useNavigate();

  // ---------------- Session Code Start Here ----------------------
  const [kathaiSession, setKathaiSession] = useState(() => {
    const savedKathaiSession = JSON.parse(localStorage.getItem("kathaisession"));
    if (savedKathaiSession)
    {
      return savedKathaiSession;            
    } 
    return null;   
  });
  
  const [session, setSession] = useState(() => {
    const savedSession = JSON.parse(localStorage.getItem("session"));
    if (savedSession)
    {
      if(new Date().getTime() < savedSession?.expiry)
      {
        return savedSession;
      }      
    } 
    return null;
       
    /*
    if (savedSession && new Date().getTime() < savedSession?.expiry) {
      return savedSession;
    }
    return null;
    */
  });

  const resetSessionTimeout = useCallback(() => {
    if (session) {
      const newExpiry = new Date().getTime() + SESSION_DURATION;
      setSession((prevSession) => ({ ...prevSession, expiry: newExpiry }));
      localStorage.setItem("session", JSON.stringify({ ...session, expiry: newExpiry }));
    }
  }, [session]);

  useEffect(() => {
    if (session) {
      const timeout = setTimeout(() => logoutSession(), session?.expiry - new Date().getTime());

      // Event listeners for activity detection
      const events = ["mousemove", "keydown", "click"];
      events.forEach((event) => window.addEventListener(event, resetSessionTimeout));

      return () => {
        clearTimeout(timeout);
        events.forEach((event) => window.removeEventListener(event, resetSessionTimeout));
      };
    }
  }, [session, resetSessionTimeout]);

  // Listen for logout in other tabs
  
  useEffect(() => {
    //console.log('Innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn111');
    const handleStorageChange = (event) => {
      //console.log('Innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn222'+event.key);
        if (event.key === "session" && !event.newValue) {
          logoutSession();
          //console.log('Innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn333');
        }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
        window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  
  
  const loginSession = () => {  
    if(!localStorage.getItem("kathaisession"))
    {
      const expiry = new Date().getTime() + SESSION_DURATION;
      setSession({ expiry });
      setKathaiSession({ expiry });
      localStorage.setItem("kathaisession", JSON.stringify({ expiry }));
      localStorage.setItem("session", JSON.stringify({ expiry }));
    }   
  };

  const logoutSession = () => {    
    setSession(null);
    setKathaiSession(null);
    localStorage.removeItem("kathaisession");
    localStorage.removeItem("session");

    loginSession();
    //setIsAuthenticated(false);
    //localStorage.removeItem("isKathAIAuthenticated");
    //localStorage.removeItem('token');

    //localStorage.removeItem('default_messsage_loading_status_for_keyword');
    //localStorage.removeItem('default_messsage_loading_status_for_plot_cards');
    //localStorage.removeItem('default_messsage_loading_status_for_generate_email');
    //logout();
  };
  // ---------------- Session Code End Here ------------------------

  // Load authentication state from localStorage to persist login
  useEffect(() => {
    const storedAuth = localStorage.getItem("isKathAIAuthenticated") === "true" && localStorage.getItem("token")!=='';
    setIsAuthenticated(storedAuth);
    setTokenInfo(getTokenInfo());    
  }, []);

  // Login Function
  const login = () => {
    setIsAuthenticated(true);
    localStorage.setItem("isKathAIAuthenticated", "true"); // Persist login
    setTokenInfo(getTokenInfo());
  };

  /*
  const getTokenInfo = () => {   
    setTokenInfo(jwtDecode(getAuthToken()));
  }
  */

  const getTokenInfo = () => {    
    try {
      const token = getToken();       
      return jwtDecode(token);
    } catch (error) {
      return null;
    }
  }


  const getToken = () => {
    return localStorage.getItem("token");
    //return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MzAxMTgwMDYuNTI1ODY1LCJuYmYiOjE3MzAxMTgwMDYuNTI1ODY1LCJleHAiOjMwMjYxMTgwMDYuNTI1ODY1LCJ1aWQiOjEsImVtYWlsIjoiYWRtaW5AYWVzLmluIiwiRGlzcGxheU5hbWUiOiJNb25pIFJveSIsInJlc291cmNlX2FjY2VzcyI6MSwiUm9sZSI6IkFkbWluIiwiQ29tcGFueU5hbWUiOiJBRVMiLCJydWxlcyI6eyJyZXNvdXJjZV9hY2Nlc3MiOjEsInByaXZpbGVnZXMiOltdfSwicm9sZV9pZCI6MX0.3CnHqSCROmrxLKpl2xZmlIZkd7YjPU1nIzQGF3G31lI';
  }  

  // Logout Function
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isKathAIAuthenticated");

    setSession(null);
    setKathaiSession(null);
    localStorage.removeItem("kathaisession");
    localStorage.removeItem("session");
    setIsAuthenticated(false);
    localStorage.removeItem("isKathAIAuthenticated");
    localStorage.removeItem('token');
    
    localStorage.removeItem('default_messsage_loading_status_for_keyword');
    localStorage.removeItem('default_messsage_loading_status_for_plot_cards');
    localStorage.removeItem('default_messsage_loading_status_for_generate_email');
    //logoutSession();

    loginSession();
  };

  return (
    <AuthContext.Provider value={{ session, kathaiSession, loginSession, logoutSession, isAuthenticated, tokenInfo, getToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom Hook for Easy Access
export const useAuth = () => useContext(AuthContext);