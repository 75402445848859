import React from "react";
import { Navigate } from "react-router-dom";

// Authentication Pages
//import Login from "../pages/Authentication/Login";
//import Register from "../pages/Authentication/Register";
//import ForgotPassword from "../pages/Authentication/ForgotPassword";
//import ResetPassword from "../pages/Authentication/ResetPassword";
//import ChangePassword from "../pages/Authentication/ChangePassword";
//import Logout from "../pages/Authentication/Logout";

// Dashboard Page
import Dashboard from "../pages/Dashboard/Dashboard";

//Favourites Page
import Favourites from "../pages/Favourites/Favourites";

//Contact Us Page
import ContactUs from "../pages/ContactUs/ContactUs";

//Unlock Email, Sign In, Sign Up, Sign Out
import AccountActivation from "../pages/Authentication/AccountActivation";

//import UserList from "../pages/User/User/UserList";
//import UserRoleList from "../pages/User/UserRole/UserRoleList";
//import Profile from "../pages/User/UserInfo/Profile";

// HTML Pages
import Step1 from "../pages/design/Html/Step1";
import Step2 from "../pages/design/Html/Step2";
import Step3 from "../pages/design/Html/Step3";
import Step4 from "../pages/design/Html/Step4";
import Step5 from "../pages/design/Html/Step5";

// 404 Page
import NotFound from "../components/Common/NotFound/NotFound";

const authProtectedRoutes = [
  { path: "/404", component: NotFound }, 
  { path: "/dashboard", component: Dashboard }, 
  //{ path: "/frontend/ver5/dashboard", component: Dashboard },
  //{ path: "/user/user/index", component: UserList }, 
  //{ path: "/user/user-role/index", component: UserRoleList }, 
  //{ path: "/user/profile", component: Profile }, 
  //{ path: "/user/change-password", component: ChangePassword },
  { path: "/activate/:key", component: AccountActivation }, 
  { path: "/favourites", component: Favourites },  
  { path: "/contact-us", component: ContactUs },    
  { path: "/html/step1", component: Step1 },
  { path: "/html/step2", component: Step2 },
  { path: "/html/step3", component: Step3 },
  { path: "/html/step4", component: Step4 },
  { path: "/html/step5", component: Step5 },

  //{ path: "/btrack/*", exact: true, component: () => <Navigate to="https://kath.ai/btrack/" replace  /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Navigate to="/dashboard" /> },
  { path: "/dashboard", exact: true, component: () => <Navigate to="/dashboard" /> },
  { path: "*", exact: true, component: () => <Navigate to="/404" /> },  
];

const publicRoutes = [
  //{ path: "/logout", component: Logout },
  //{ path: "/sign-in", component: Login },
  //{ path: "/sign-up", component: Register },
  //{ path: "/forgot-password", component: ForgotPassword }, 
  //{ path: "/reset-password", component: ResetPassword },    

 // { path: "/html/login-design", component: LoginDesign },
];

export { authProtectedRoutes, publicRoutes };
