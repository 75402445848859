import * as CryptoJS from 'crypto-js';

const JsonFormatter = {
  stringify: function (cipherParams) {
      // create json object with ciphertext
      var jsonObj = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };

      // optionally add iv or salt
      if (cipherParams.iv) {
          jsonObj.iv = cipherParams.iv.toString();
      }

      if (cipherParams.salt) {
          jsonObj.s = cipherParams.salt.toString();
      }

      // stringify json object
      return JSON.stringify(jsonObj);
  },
  parse: function (jsonStr) {
      // parse json string
      var jsonObj = JSON.parse(jsonStr);

      // extract ciphertext from json object, and create cipher params object
      var cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct)
      });

      // optionally extract iv or salt

      if (jsonObj.iv) {
          cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
      }

      if (jsonObj.s) {
          cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
      }

      return cipherParams;
  }
};

const secretKey = process.env.REACT_APP_SECRET_KEY;
export const encrypt = ( plainText ) => { 
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(plainText), secretKey, {
      format: JsonFormatter
  }).toString()
    return cipherText
}

export const decrypt = ( cipherText ) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey, {
      format: JsonFormatter
  })
    const plainText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return plainText
}   