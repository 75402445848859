import React, { createContext, useContext } from 'react';

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children, permissions }) => (
  <PermissionsContext.Provider value={permissions}>
    {children}
  </PermissionsContext.Provider>
);

export const usePermissions = () => useContext(PermissionsContext);