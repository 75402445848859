import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Select from "react-dropdown-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";

import icon14 from '../../../assets/images/star-glyphs-wht.svg';

const Step1 = () => {
  const { t } = useTranslation();
  const options = [
    {
      value: 1,
      label: "Leanne Graham"
    },
    {
      value: 2,
      label: "Ervin Howell"
    }
  ];
  const [startDate, setStartDate] = useState(new Date());
  return <>
    <Container fluid id="main-area">
      <Row className="p-10 main-lft">
        <Col lg={12}>
          <Card className='border-0 p-15'> 
            
          <div class="welcome-cnt">
           <h2>Welcome <span>Loren</span></h2>
           <p>Lorem ipsum dolor sit amet consectetur. Integer risus ac egestas sit augue adipiscing<br></br> orci pellentesque. Est tincidunt nisi sagittis eros cras.</p>
           <div class="ideate-inner">
            <textarea>I want to send an email for awards and recognitions...|</textarea>
            <Button variant="primary ideate-btn active-btn">Ideate <span> <img src={icon14} alt="" /> </span></Button>
           </div>
          </div>
         
           
          </Card>
        </Col>
      </Row>
    </Container></>;
};

export default Step1; 