import axios from "axios";
//import accessToken from "./jwt-token-access/accessToken";
import { getAuthToken } from '../jwt-token-access/auth-token';
import { Navigate } from "react-router-dom";

//import { useAuth } from "../../components/Context/AuthContext"; // Adjust path as needed

//pass new generated access token here
//const token = getAuthToken();
//const token = 'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJDb21wYW55TmFtZSI6IlBBUCBDb21tdW5pdHkgRm91bmRhdGlvbiIsIkNvbXBhbnlJRCI6IjEiLCJJc3N1ZXIiOiJIUmlRIiwiVXNlcm5hbWUiOiJSYWphLkR1cmFpcmFqIiwiVXNlcklEIjoiMSIsIkRpc3BsYXlOYW1lIjoiUmFqYSBEIiwiZXhwIjoxNzc0NTI2Mzg0LCJpYXQiOjE3MTE0NTQzODR9.CYOFEaJ6RMah8e3dvX0OT32EU7N0MLoBfh-Qc3bQiKc';
const token = localStorage.getItem("token");

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL
});

/*
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    //"Content-Type": "application/json",
    //"Accept": "application/json",
    //"access-control-allow-credentials": true,
    //"access-control-allow-methods": "*",
    //"access-control-allow-origin": "*",
    //"access-control-allow-headers": "*",
    //"cache-control": "no-cache",
    //"cors-enabled": false,
    //"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    //"Access-Control-Allow-Methods": "GET, POST, PUT, DELETE"
  },
});
*/

//axiosApi.defaults.headers.common["Content-Type"] = "multipart/form-data";
//axiosApi.defaults.headers.common["Accept"] = "application/json";
//axiosApi.defaults.headers.common["Authorization"] = 'Basic ' + base64.encode(username + ":" +  password);
//axiosApi.defaults.headers.common["Origin"] = 'http://localhost:3000';

/*
if (token) {  
  //axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token;
}
*/

/*
axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);
*/

/*
axios.interceptors.response.use(
  (response) => {
      //return response
  },
  (error) => {
      if (error.response.status === 404) {
        <Navigate to="/error" replace={true} />
      } else {
          return Promise.reject(error)
      }
  }
);
*/

axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error.response.status === 404) {
      <Navigate to="/error" replace={true} />
    } else {
        return Promise.reject(error)
    }
  }
);

export async function get(url, config = {}) {
  return await axiosApi
  .get(url, { ...config })
  //.catch(function (error) { console.log(error.status); })
  .then(response => response.data);
}

export async function getResBlob(url, data, config = {}) {
  //console.log(data)
  // if (config.multipart === true) {
  //   axiosApi.defaults.headers.common["Content-Type"] = "multipart/form-data";
  // }

  return await fetch(API_URL+url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ token
    },
    body: JSON.stringify(data),
  })
  .then(response => {
      return response.blob();
  });

  /*
  return await fetch(url, { ...config })
  //.catch(function (error) { console.log(error.status); })
  .then((res) => {
    return res.json();
  });

  fetch('/report/download-report', {
      method: 'GET',
  })
  .then(response => {
      return response.blob();
  });
  */

  /*
  return axiosApi
    .get(url, { ...data }, { ...config })
    .then(response => {
      return response.blob(); // Convert response to a blob for file download
      
    });
    */
}

export async function post(url, data, config = {}) {

  //console.log(data);
  // if (config.multipart === true) {
  //   axiosApi.defaults.headers.common["Content-Type"] = "multipart/form-data";
  // }
  
  /*
  console.log('----------------Post----------------------');
  console.log(localStorage.getItem("token"));
  console.log('----------------Authorization----------------------');
  console.log(axiosApi.defaults.headers.common["Authorization"]);
  //console.log(axiosApi.defaults.headers.common["Authorization"]);
  //console.log(Object.keys(config).length);
  //console.log(config?.token);
  if(axiosApi.defaults.headers.common["Authorization"]!==undefined  && Object.keys(config).length>0 && config?.token)
  {
    console.log('Innnnnnnnnnnnnnnnnnnnnnnn');
    axiosApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");   
    delete config.token; 
  } 
  */ 
  
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);  
  
  /*
  return await fetch(url, {
    method: 'POST',
    cache: 'no-store',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  .then((res) => {
    return res.json();
  });
  */
}



export async function postApi(url, data, config = { headers: {
  'Content-Type': 'multipart/form-data'
}}) {
  console.log(data)
  // if (config.multipart === true) {
  //   axiosApi.defaults.headers.common["Content-Type"] = "multipart/form-data";
  // }
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data);  
}

export async function put(url, data, config = { headers: {
  'Accept': 'application/json',
  'Content-Type': 'application/vnd.example.v1+json'
  } }) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, data, config = {}) {
  return await axiosApi
    .delete(url, { ...data }, { ...config })
    .then(response => response.data);
}
