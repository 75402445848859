import React, { useState, useRef, Suspense, useEffect, useCallback  } from "react";
import { useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Select from "react-dropdown-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import ico1 from "../../assets/images/d-ico-1.svg";
import ico2 from "../../assets/images/d-ico-2.svg";
import ico3 from "../../assets/images/d-ico-3.svg";
import ico4 from "../../assets/images/d-ico-4.svg";
import dum from "../../assets/images/dummy.svg";
import { culturalReferences,shuffleArrayValues,getSessionID } from "../../common/Config";
import { get,post,put,del } from '../../helpers/api/api_helper';
import * as url from '../../helpers/api-url/claude_url_helper';
import * as user_url from '../../helpers/api-url/user_url_helper';
import { encrypt,decrypt } from "../../common/Crypto";
import { useAuth } from "../../components/Context/AuthContext"; // Adjust path as needed
const Authentication = React.lazy(() => import("../../components/Common/Authentication/Authentication"));

const Step1 = React.lazy(() => import("./Step1"));
const Step2 = React.lazy(() => import("./Step2"));
const Step3 = React.lazy(() => import("./Step3"));
const Step4 = React.lazy(() => import("./Step4"));

const Dashboard = () => {

  const childContinueOnMobileRef = useRef(null);    
  const { session, kathaiSession, loginSession, tokenInfo } = useAuth();
  const childRefForStep1 = useRef(null);
  const childRefForStep2 = useRef(null);
  const childRefForStep3 = useRef(null);
  const childRefForStep4 = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [step1Status, setStep1Status] = useState(true);
  const [step2Status, setStep2Status] = useState(false);
  const [step3Status, setStep3Status] = useState(false);
  const [step4Status, setStep4Status] = useState(false);

  const [userPromptID, setUserPromptID] = useState();
  const [userKeywordID, setUserKeywordID] = useState();
  const [userPlotCardID, setUserPlotCardID] = useState();
  const [userEmailID, setUserEmailID] = useState();

  const currentUserPromptID = useRef();
  const currentUserKeywordID = useRef();
  const currentUserPlotCardID = useRef();
  const currentUserEmailID = useRef();

  const [data, setData] = useState();
  const [step1Data, setStep1Data] = useState();
  const [step2Data, setStep2Data] = useState();
  const [step3Data, setStep3Data] = useState();
  const [step4Data, setStep4Data] = useState();

  const [count, setCount] = useState(1);

  //const derivedValue = count + 1; // Derived value

  const stepData = useRef({});

  const location = useLocation();
  const [key, setKey] = useState(location.key);
  const [activeChild, setActiveChild] = useState("Dashboard");

  useEffect(() => {
    if(window.innerWidth<640)
    {
      setTimeout(() => {
        childContinueOnMobileRef.current.continueOnMobile(); 
      }, 200);
    }

    let contentLT = 'I want to write an email on awards and recognitions';
    stepData.current.step1_data = {'loading':false,'request':'','response':''};
    stepData.current.step2_data = {'loading':false,'request':{'selected_topics':[],'cultural_references':[],'additional_cultural_references':[],'selected_cultural_reference':'','selected_cultural_reference_index':'-1','selected_adjectives':[],'selected_more_cultural_reference':'','selected_more_cultural_reference_index':'-1','selected_future_adjectives':'','removed_topics':[],'selected_more_cultural_reference_scroll_left':'','show_more_cultural_reference_status':false,'selected_cultural_reference_for_more_theme':''},'response':''};
    stepData.current.step3_data = {'loading':false,'request':{'plot_card':'','creative_theme':'','theme_description':'','theme_adjectives':'','no_of_tones':'four','other_possible_tones':''},'response':{'data':'','tones':''}};
    /*
    stepData.current = {
        "step1_data": {
            "loading": false,
            "request": "Create an email inviting a candidate for an interview",
            "response": {
                "generated_keywords": {
                    "Keywords": [
                        {
                            "Keyword": "Email",
                            "Subtopics": [
                                "Subject line",
                                "Greeting",
                                "Body",
                                "Closing",
                                "Signature"
                            ]
                        },
                        {
                            "Keyword": "Candidate",
                            "Subtopics": [
                                "Name",
                                "Position applied for",
                                "Application date",
                                "Resume reference"
                            ]
                        },
                        {
                            "Keyword": "Interview",
                            "Subtopics": [
                                "Date",
                                "Time",
                                "Location",
                                "Duration",
                                "Format"
                            ]
                        },
                        {
                            "Keyword": "Company",
                            "Subtopics": [
                                "Name",
                                "Address",
                                "Department",
                                "Contact person"
                            ]
                        },
                        {
                            "Keyword": "Instructions",
                            "Subtopics": [
                                "What to bring",
                                "Dress code",
                                "Parking information",
                                "Security procedures"
                            ]
                        },
                        {
                            "Keyword": "Next steps",
                            "Subtopics": [
                                "Confirmation request",
                                "Questions encouragement",
                                "Follow-up process"
                            ]
                        }
                    ]
                },
                "prompt_content": "John is an HR Communications Manager. They have asked for Create an email inviting a candidate for an interview.Can you identify the keywords from this? For the keywords can you prompt them with sub-topics so that they dont have to fill any information but just have to choose more keywords to make the brief more detailed?.",
                "status": 1
            }
        },
        "step2_data": {
            "loading": false,
            "request": {
                "selected_topics": [],
                "cultural_references": [
                    "Pop Culture"
                ],
                "additional_cultural_references": [
                    "Popular Books",
                    "Well-Known Movies",
                    "Greek Mythology",
                    "Nature"
                ],
                "selected_cultural_reference": "Pop Culture",
                "selected_cultural_reference_index": 0,
                "selected_adjectives": [],
                "selected_more_cultural_reference": "Well-Known Movies",
                "selected_more_cultural_reference_index": 0,
                "selected_future_adjectives": [],
                "removed_topics": [],
                "selected_more_cultural_reference_scroll_left": 0,
                "show_more_cultural_reference_status": true,
                "selected_cultural_reference_for_more_theme": ""
            },
            "response": {
                "res": {
                    "generated_plot_cards": {
                        "cultural_references": [
                            {
                                "name": "Pop Culture",
                                "themes": [
                                    {
                                        "adjectives": "EPIC, POWERFUL, HEROIC",
                                        "description": "Frame the interview as a recruitment for a superhero team. Use comic book-inspired language and imagery, comparing the candidate's skills to superpowers and the company to a league of extraordinary individuals.",
                                        "title": "Superhero Recruitment"
                                    },
                                    {
                                        "adjectives": "DRAMATIC, EXCITING, COMPETITIVE",
                                        "description": "Style the email as if the candidate has made it to the next round of a popular reality TV show. Use catchphrases from shows like 'The Voice' or 'America's Got Talent' to create anticipation and excitement.",
                                        "title": "Reality TV Show Callback"
                                    },
                                    {
                                        "adjectives": "TRENDY, ENGAGING, INTERACTIVE",
                                        "description": "Present the interview invitation as a viral social media challenge. Incorporate hashtags, emojis, and internet slang to make the email feel current and appealing to a tech-savvy audience.",
                                        "title": "Viral Social Media Challenge"
                                    }
                                ]
                            }
                        ],
                        "future_cultural_references": [
                            "Classic Literature",
                            "Video Games",
                            "Science Fiction",
                            "Historical Eras",
                            "Culinary Arts",
                            "Sports"
                        ]
                    },
                    "prompt_content": "They have asked for Create an email inviting a candidate for an interview, can you generate creative themes that are inspired from diverse cultural references like Pop Culture. Compulsory show up to three creative themes for each of the cultural references, with rich, detailed descriptions for each of them. Show it as a table please. In the table also include a column to highlight adjectives that best suit the theme and for that adjectives all letters should be completely in capital. Apart from this table, can you also recommend six other possible cultural references for the future?"
                },
                "status": 1,
                "more_plot_cards_res": {
                    "generated_plot_cards": {
                        "cultural_references": [
                            {
                                "name": "Well-Known Movies",
                                "themes": [
                                    {
                                        "adjectives": "MAGICAL, TRANSFORMATIVE, ADVENTUROUS",
                                        "description": "Create an email that takes the candidate on a journey down the yellow brick road to their interview. Use emerald green accents and encourage them to follow their dreams. Mention that the company values courage, heart, and wisdom, drawing parallels to the movie's main characters.",
                                        "title": "The Wizard of Oz Interview"
                                    },
                                    {
                                        "adjectives": "EPIC, FUTURISTIC, INNOVATIVE",
                                        "description": "Design an email that invites the candidate to join the 'rebel alliance' (your company) in its mission to revolutionize the industry. Use a space-themed layout with star-studded backgrounds and iconic quotes. Emphasize the force of teamwork and the power of innovation.",
                                        "title": "Star Wars Galactic Recruitment"
                                    },
                                    {
                                        "adjectives": "EXCITING, GROUNDBREAKING, DYNAMIC",
                                        "description": "Craft an email that presents the interview as an expedition into a world of professional possibilities. Use prehistoric imagery and emphasize the candidate's potential to uncover game-changing ideas. Highlight the company's commitment to pushing boundaries and exploring new territories in the industry.",
                                        "title": "Jurassic Park Discovery Interview"
                                    }
                                ]
                            }
                        ]
                    },
                    "prompt_content": "Using the Create an email inviting a candidate for an interview, can you generate creative themes that are inspired from diverse cultural references like Well-Known Movies. Compulsory show up to 3 creative themes for each of the cultural references, with rich, detailed descriptions for each of them. Show it as a table please. In the table also include a column to highlight adjectives that best suit the theme and for that adjectives all letters should be completely in capital."
                },
                "more_plot_cards_status": 1
            },
            "more_cultural_reference_loading": false
        },
        "step3_data": {
            "loading": false,
            "request": {
                "plot_card": "Well-Known Movies",
                "creative_theme": "Star Wars Galactic Recruitment",
                "theme_description": "Design an email that invites the candidate to join the 'rebel alliance' (your company) in its mission to revolutionize the industry. Use a space-themed layout with star-studded backgrounds and iconic quotes. Emphasize the force of teamwork and the power of innovation.",
                "theme_adjectives": "EPIC, FUTURISTIC, INNOVATIVE",
                "no_of_tones": "four",
                "other_possible_tones": ""
            },
            "response": {
                "data": {
                    "generate_email": {
                        "email": {
                            "body": "Greetings, brave candidate!\n\nA disturbance in the Force has led us to you. The Rebel Alliance (aka [Company Name]) is seeking a new hero to join our ranks in our epic quest to revolutionize the [Industry] galaxy.\n\nWe sense great potential in you and wish to meet for a strategic briefing (interview) at our secret base. Will you answer the call and help us bring balance to the industry?\n\nMission Details:\nStardate: [Interview Date]\nCoordinates: [Interview Location\/Video Call Link]\nMission Objective: Discuss your role in our rebellion against outdated industry practices\n\nRemember, 'Do. Or do not. There is no try.' We're looking for those who dare to innovate and aren't afraid to challenge the Empire of conventional thinking.\n\nMay the Force of innovation be with you!\n\n[Your Name]\nRebel Commander (Hiring Manager)\n[Company Name]",
                            "subject": "Join the Rebel Alliance: Your Galactic Interview Awaits!"
                        },
                        "other_possible_tones": [
                            "professional and formal",
                            "adventurous and bold",
                            "mysterious and intriguing",
                            "friendly and casual"
                        ],
                        "tone": "playful and enthusiastic"
                    },
                    "genrated_emails_additional": {
                        "adventurous and bold": {
                            "email": {
                                "body": "Greetings, brave candidate!\n\nA disturbance in the Force has led us to you. The Rebel Alliance (aka [Company Name]) is seeking a new hero to join our epic quest to revolutionize the [Industry] galaxy!\n\nWe've sensed your potential and invite you to an interview at our secret base. Will you answer the call to adventure?\n\nMission Details:\nStardate: [Interview Date]\nCoordinates: [Interview Location\/Video Call Link]\nMission Objective: Showcase your powers and join our crew\n\n'Do. Or do not. There is no try.' - Yoda\n\nPrepare to demonstrate your innovative spirit and the strength of your teamwork skills. Together, we'll harness the Force of creativity to bring balance to the industry!\n\n'Never tell me the odds!' - Han Solo\n\nReady to embark on this stellar journey? Transmit your confirmation to this comm channel.\n\nMay the Force be with you,\n\n[Your Name]\nRebel Recruiter\n[Company Name]",
                                "subject": "Join the Rebel Alliance: Your Galactic Interview Awaits!"
                            },
                            "tone": "adventurous and bold"
                        },
                        "friendly and casual": {
                            "email": {
                                "body": "Greetings, brave recruit!\n\nA disturbance in the Force has led us to you. The Rebel Alliance (aka [Company Name]) is seeking a new hero to join our mission to revolutionize the galaxy of [industry].\n\nWe've sensed your potential and would be thrilled if you'd hop aboard the Millennium Falcon for an interview. Here are the details:\n\nDate: [Interview Date]\nTime: [Interview Time]\nLocation: Our secret base at [Company Address]\n\nRemember, 'Do. Or do not. There is no try.' We're looking for someone who's ready to use the force of teamwork and wield the lightsaber of innovation.\n\nMay the force be with you, and we hope to see you soon!\n\nYours in rebellion,\n[Your Name]\nRebel Recruiter",
                                "subject": "Join the Rebel Alliance: Your Interview Awaits!"
                            },
                            "tone": "friendly and casual"
                        },
                        "mysterious and intriguing": {
                            "email": {
                                "body": "Greetings, Esteemed Candidate,\n\nIn a galaxy not so far away, a powerful alliance is forming. We sense a great disturbance in the Force\u2014your potential to join our ranks.\n\nThe Rebel Alliance (our company) invites you to a clandestine meeting. We seek those with the courage to challenge the status quo and the wisdom to innovate beyond the stars.\n\nOur mission: to revolutionize the industry and restore balance to the galaxy of [industry name].\n\nDetails of this covert operation:\nStardate: [Interview Date]\nLocation: [Interview Location\/Method]\nMission Briefing: [Interview Time]\n\n'Do. Or do not. There is no try.' - Master Yoda\n\nWill you answer the call? The future of the galaxy may depend on it.\n\nMay the Force be with you,\n\n[Your Name]\nRebel Commander (Hiring Manager)",
                                "subject": "A Disturbance in the Force: Your Presence is Requested"
                            },
                            "tone": "mysterious and intriguing"
                        },
                        "professional and formal": {
                            "email": {
                                "body": "Dear [Candidate Name],\n\nI hope this message finds you well. We at [Company Name] have reviewed your application and are impressed with your qualifications and experience. We would like to invite you to interview for the [Position] role.\n\nOur company is committed to innovation and excellence in our field, and we believe your skills could contribute significantly to our mission. We are seeking individuals who can bring fresh perspectives and collaborate effectively within our team.\n\nThe interview details are as follows:\nDate: [Interview Date]\nTime: [Interview Time]\nLocation: [Interview Location\/Video Call Link]\n\nPlease confirm your availability for this interview at your earliest convenience. If you have any questions or require any accommodations, do not hesitate to contact us.\n\nWe look forward to meeting you and discussing how you could contribute to our organization's goals.\n\nBest regards,\n[Your Name]\n[Your Title]\n[Company Name]",
                                "subject": "Invitation to Interview: Join Our Mission to Transform the Industry"
                            },
                            "tone": "professional and formal"
                        }
                    },
                    "prompt_content": "Using the Create an email inviting a candidate for an interview, can you generate an email using the creative theme of Star Wars Galactic Recruitment such that it Design an email that invites the candidate to join the 'rebel alliance' (your company) in its mission to revolutionize the industry. Use a space-themed layout with star-studded backgrounds and iconic quotes. Emphasize the force of teamwork and the power of innovation.. After the email is generating, also show me the tone of this email. In addition to that recommend four other possible tones. In that one of the other possible tone should be Professional and formal in which this email can be modified."
                },
                "status": 1
            }
        }
    };
    */

    setData(stepData.current);
    setCount(count + 1);
    setKey(location.key); // Update key whenever location changes

    //callbackData({'action':'step3'});
    //console.log(stepData.current);

    setStep1Status(true);
    setStep2Status(false);
    setStep3Status(false);
    setStep4Status(false);
    handleHeaderGreyBar(0);
    handleRemoveDefaultSlideBar(1);
    let generateNewIdeaIDObj = document.getElementById("generate-new-idea-id");
    if(generateNewIdeaIDObj)
    {
      generateNewIdeaIDObj.style.display = 'none';  
    }

    /*
    let generateNewIdeaMbIDObj = document.getElementById("generate-new-idea-mb-id");
    if(generateNewIdeaMbIDObj)
    {
      generateNewIdeaMbIDObj.style.display = 'none';  
    }
    */
    
  },[location]);

  useEffect(() => {    
    if (window.clarity) {
      window.clarity("set", { active_child: activeChild }); // ✅ Correct way to send custom tracking      
    }
  },[activeChild]);

  const handleComfirm = useCallback((arg) => {  
    childContinueOnMobileRef.current.continueOnMobilePopupClose();   
  }, []);

  const handleHeaderGreyBar = (arg) => {
    document.getElementById("head-rgt-lft-id").style.display=(arg==1 ? 'block' : 'none');
  }

  const handleRemoveDefaultSlideBar = (arg=0) => {

    var bodyClassListObj = document.body.classList;
    var headerLeftLogoDivObj = document.getElementById("head-lft-logo-div");

    var presentOpenClass = bodyClassListObj.contains('open');
    var presentNoSideBar = bodyClassListObj.contains('no-sidebar');
      
    if(arg==1)
    {
      if(!presentNoSideBar)
      {
        bodyClassListObj.add('no-sidebar');
      }      
      headerLeftLogoDivObj.style.display = 'block';
      if(presentOpenClass)
      {
        bodyClassListObj.remove('open');
      } 
    }else{
      bodyClassListObj.remove('no-sidebar');
      if(!presentOpenClass)
      {
        bodyClassListObj.add('open');   
      }     
      headerLeftLogoDivObj.style.display = 'none';
    } 
    
    if(window.innerWidth<=1024)
    {
      bodyClassListObj.remove('no-sidebar');
      headerLeftLogoDivObj.style.display = 'none';
    }
  }
    
  const callbackData = (data) => {
    handleHeaderGreyBar(0);
    handleRemoveDefaultSlideBar(0);
    //document.getElementById("generate-new-idea-id").style.display = 'block'; 
    let generateNewIdeaIDObj = document.getElementById("generate-new-idea-id");
    if(generateNewIdeaIDObj)
    {
      generateNewIdeaIDObj.style.display = 'block';  
    }

    //var width = $(window).width();   
    /* 
    var innerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if(innerWidth<=767)
    {
      let generateNewIdeaMbIDObj = document.getElementById("generate-new-idea-mb-id");
      if(generateNewIdeaMbIDObj)
      {
        generateNewIdeaMbIDObj.style.display = 'block';  
      }  
    }
    */
     
    if(data.action==='step1' || data.action==='back_to_step1')
    {  
      handleRemoveDefaultSlideBar(1);    
      if(data.action==='step1')
      {
        let user_input='';
        let submitData = {"user_name": "John","user_role": "HR Communications Manager"};
        submitData.session_id=kathaiSession?.expiry ? kathaiSession?.expiry : '';
        submitData.user_id=tokenInfo && tokenInfo?.uid ? tokenInfo?.uid : '';               

        if(data?.type==='keyword'){
          if(data?.data?.searchKeyword)
          {
            stepData.current.step1_data.request = data?.data?.searchKeyword;
          }
          submitData.user_input = stepData.current.step1_data.request;
          submitData.prompt_type = 0;
        }else if(data?.type==='prompt'){
          submitData.prompt_changed = data?.data?.promptContent;          
          submitData.prompt_type = 1;
          delete submitData.user_name;
          delete submitData.user_role;
        }else{
          if(data?.data?.searchKeyword)
          {
            stepData.current.step1_data.request = data?.data?.searchKeyword;
          } 
          submitData.user_input = stepData.current.step1_data.request; 
          submitData.prompt_type = 0;
        }      
        //submitData.user_input = data.data.searchKeyword;
        //submitData.user_input = stepData.current.step1_data.request;        
       // submitData.user_input = user_input;

        //submitData.action = 'GetNa6r0wD03nT0PICF0rCrEa1I0NkAthA12025';
        
        let step1Result = {};
        stepData.current.step1_data.response=step1Result; 
        stepData.current.step1_data.loading=true;
        stepData.current.step1_data.response='';

        stepData.current.step2_data.request.selected_topics=[];
        stepData.current.step2_data.request.removed_topics=[];
        stepData.current.step2_data.request.cultural_references=[];
        stepData.current.step2_data.request.additional_cultural_references=[];        
        stepData.current.step2_data.request.selected_cultural_reference='';
        stepData.current.step2_data.request.selected_cultural_reference_index='-1';        
        stepData.current.step2_data.request.selected_adjectives=[];
        stepData.current.step2_data.request.selected_future_adjectives=[];
        stepData.current.step2_data.request.selected_more_cultural_reference='';
        stepData.current.step2_data.request.selected_more_cultural_reference_index='-1';  
        stepData.current.step2_data.response='';        

        setData(stepData.current);
        //setCount(count + 1);  
        setStep1Status(false);
        setStep2Status(true);
        setStep3Status(false);
        setStep4Status(false);
                
        //------------------------Narrow Down Topic Dynamic Code Start Here --------------------------   
              
        //const encryptData = encrypt(submitData); 
        //const result = post(url.GET_NARROW_DOWN_TOPIC,JSON.parse(encryptData.toString()));
        const result = post(url.GET_NARROW_DOWN_TOPIC, submitData);
        result.then((resdata) => {
          //const decryptData = decrypt(resdata);
          //const res = JSON.parse(decryptData.toString());  
          //const res = {"status": 0,"message":'Server too busy. Please try again after few minutes.'};
          const res = resdata;
          if(res.status==1){
            step1Result=res.data;
            step1Result.status = res.status;  

            //----------- User Activity Tracking Code Start Here ------------
            userTracking({action:data.action,user_input:stepData.current.step1_data.request}); 

            setTimeout(() => {
              userInputOutputTokensTracking({request_for:'keyword',input_tokens:res?.input_tokens,output_tokens:res?.output_tokens,prompt:res.data.prompt_content,request_json:submitData,response_json:res});
            }, 100);            
            //----------- User Activity Tracking Code End Here ------------

          }else{
            step1Result.message = res.message;
            step1Result.status = res.status;                                      
          }

          stepData.current.step1_data.loading=false;
          localStorage.setItem("default_messsage_loading_status_for_keyword", true);
          //stepData.current.step1_data.request=data.data.searchKeyword; 
          stepData.current.step1_data.request=stepData.current.step1_data.request;            
          stepData.current.step1_data.response=step1Result;              
                      
          setData(stepData.current);
          setIsLoading(false);   
          setCount(count + 1); 
          
          //------User Activity Tracking--------
        });             
        
        //------------------------Narrow Down Topic Dynamic Code End Here --------------------------
        
        //------------------------Narrow Down Topic Static Code Start Here --------------------------  
        /*

        //----------- User Activity Tracking Code Start Here ------------
        userTracking({action:data.action,user_input:stepData.current.step1_data.request});
        //----------- User Activity Tracking Code End Here ------------

        step1Result={
          "generated_keywords": {
              "Keywords": [
                  {
                      "Keyword": "Good person",
                      "Subtopics": [
                          "Ethical behavior",
                          "Kindness",
                          "Empathy",
                          "Integrity",
                          "Honesty",
                          "Respect for others",
                          "Self-improvement"
                      ]
                  },
                  {
                      "Keyword": "Personal development",
                      "Subtopics": [
                          "Self-awareness",
                          "Goal setting",
                          "Emotional intelligence",
                          "Continuous learning",
                          "Mindfulness",
                          "Positive habits",
                          "Time management"
                      ]
                  },
                  {
                      "Keyword": "Social skills",
                      "Subtopics": [
                          "Active listening",
                          "Effective communication",
                          "Conflict resolution",
                          "Teamwork",
                          "Networking",
                          "Cultural sensitivity",
                          "Empathy"
                      ]
                  },
                  {
                      "Keyword": "Moral values",
                      "Subtopics": [
                          "Compassion",
                          "Fairness",
                          "Responsibility",
                          "Generosity",
                          "Humility",
                          "Forgiveness",
                          "Gratitude"
                      ]
                  },
                  {
                      "Keyword": "Community involvement",
                      "Subtopics": [
                          "Volunteering",
                          "Charity work",
                          "Environmental awareness",
                          "Social activism",
                          "Mentoring",
                          "Civic engagement",
                          "Supporting local businesses"
                      ]
                  }
              ]
          },
          "prompt_content": "John is an HR Communications Manager. They have asked for I want to create an email series on company values and culture.Can you identify the keywords from this? For the keywords can you prompt them with sub-topics so that they dont have to fill any information but just have to choose more keywords to make the brief more detailed?."
        };

        let keywordsList=[];
        let childCount=0;
        let childLimit=10;
        let exitLoop=false;
        for (const [parentKey, row] of Object.entries(step1Result?.generated_keywords?.Keywords)) 
        {
          if (exitLoop) break;
          
          //keywordsList.current.push(<h3 key={parentKey}>{parentKey}</h3>);
          let innerObj = {};
          innerObj.Keyword=row.Keyword;
          let subtopicsArray = [];
          for (const [childKey, childrow] of Object.entries(row["Subtopics"])) {            
            if (exitLoop) break;  
            
            subtopicsArray.push(childrow);         

            childCount++;
            if (childCount >= childLimit) {
              exitLoop = true;
            } 

          }
          innerObj.Subtopics=subtopicsArray;
          keywordsList.push(innerObj);
        }

        let mainObj = {
          "generated_keywords": {
            "Keywords":keywordsList
          },
          "prompt_content":step1Result?.prompt_content
        };

        //console.log(step1Result);
        step1Result = mainObj;
        //console.log(step1Result);
        //return;

        step1Result.status = 1;  

        stepData.current.step1_data.loading=false;
        if(data?.data?.searchKeyword)
        {
          stepData.current.step1_data.request=data?.data?.searchKeyword; 
        }
        stepData.current.step1_data.response=step1Result;              
                   
        setData(stepData.current);
        setIsLoading(false);   
        setCount(count + 1);  
        */
        //------------------------ Narrow Down Topic Static Code End Here --------------------------                     

      }else{
        setStep1Status(true);
        setStep2Status(false);
        setStep3Status(false);
        setStep4Status(false);

        let generateNewIdeaIDObj = document.getElementById("generate-new-idea-id");
        if(generateNewIdeaIDObj)
        {
          generateNewIdeaIDObj.style.display = 'none';  
        }

        /*
        let generateNewIdeaMbIDObj = document.getElementById("generate-new-idea-mb-id");
        if(generateNewIdeaMbIDObj)
        {
          generateNewIdeaMbIDObj.style.display = 'none';  
        } 
        */

        stepData.current.step2_data.loading = false;
        stepData.current.step2_data.request.selected_topics=[];
        stepData.current.step2_data.request.cultural_references=[];
        stepData.current.step2_data.request.additional_cultural_references=[]; 
        stepData.current.step2_data.request.selected_cultural_reference='';
        stepData.current.step2_data.request.selected_cultural_reference_index='-1';        
        stepData.current.step2_data.request.selected_adjectives=[];
        stepData.current.step2_data.request.selected_future_adjectives=[];
        stepData.current.step2_data.request.selected_more_cultural_reference='';
        stepData.current.step2_data.request.selected_more_cultural_reference_index='-1';         
        stepData.current.step2_data.request.removed_topics=[];
        stepData.current.step2_data.response=''; 
        setData(stepData.current);   
      }
    }else if(data.action==='step2' || data.action==='back_to_step2'){
        handleHeaderGreyBar(1);
        //handleRemoveDefaultSlideBar(0);
        if(data.action==='step2'){  
            //handleRemoveDefaultSlideBar(1);
            if(stepData.current.step2_data.request.selected_topics.length>0 || stepData.current.step2_data.request.selected_topics.length==0)
            {
                let step2Result = {};
                let res = {};

                if(stepData.current.step2_data.request.selected_future_adjectives && data?.type==='future-adjective' && data.plot_card!='')
                {
                  stepData.current.step3_data.request= {'creative_theme':'','theme_description':'','theme_adjectives':'','no_of_tones':'four','other_possible_tones':''};                  
                  let objKeyIndex = stepData.current.step2_data.request.selected_future_adjectives.findIndex(obj => obj.plot_card === data.plot_card);
                  if(objKeyIndex>-1 && stepData.current.step2_data.request.selected_future_adjectives.length>0 && stepData.current.step2_data.request.selected_future_adjectives[objKeyIndex]['adjectives'].length>0)
                  {                
                    let selectPlotCard = stepData.current.step2_data.request.selected_future_adjectives[objKeyIndex]['plot_card'];
                    let selectedAdjectives = stepData.current.step2_data.request.selected_future_adjectives[objKeyIndex]['adjectives'].join(', ');
                    // let objCulturalReferencesIndex = stepData.current.step2_data.response.res.generated_plot_cards.cultural_references.findIndex(obj => obj.name === data.plot_card)
                    let objMoreCulturalReferencesIndex = stepData?.current?.step2_data?.request?.selected_more_cultural_reference_index;

                    /*
                    stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['message']='';
                    stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['status']=1;
                    stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['loading']=true;
                    */

                    stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['loading']=true;
                    stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['status']=1;
                    stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['message']='';

                    let selected_keywords_separated_by_comma = '';
                    if(stepData.current.step2_data.request.selected_topics.length>0)
                    {
                      let selectedKeywords = stepData.current.step2_data.request.selected_topics.flatMap(a => a['Subtopics']);  
                      selected_keywords_separated_by_comma = selectedKeywords.join(', ');
                    }

                    let submitData = {};
                    submitData.session_id=kathaiSession?.expiry ? kathaiSession?.expiry : '';
                    submitData.user_id=tokenInfo && tokenInfo?.uid ? tokenInfo?.uid : '';
                    if(data?.prompt_type==='prompt') {
                      stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['prompt_content_edit_status']=0;                      
                      submitData.prompt_changed = data?.prompt_content;   
                      submitData.option="b";       
                      submitData.prompt_type = 1;                      
                    }else{
                      
                      let no_of_selected_themes = stepData.current.step2_data?.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['themes'].length>3 ? "6" : "3";
                      
                      submitData.selected_keywords=selected_keywords_separated_by_comma;
                      submitData.selected_reference=data.plot_card;
                      submitData.option="b";
                      submitData.selected_adjective=selectedAdjectives;
                      submitData.no_of_themes=no_of_selected_themes;
                      submitData.no_of_adjectives="3";
                      submitData.prompt_type = 0;
                      submitData.user_input=stepData.current.step1_data.request;
                    }
                    //submitData.action = 'GetF1LT3RPl0TCarD5F0rCrEa1I0NkAthA12025';

                    const result = post(url.GET_FILTER_PLOT_CARDS, submitData);
                    //const encryptData = encrypt(submitData); 
                    //const result = post(url.GET_FILTER_PLOT_CARDS,JSON.parse(encryptData.toString()));
                    result.then((resdata) => {
                        //const decryptData = decrypt(resdata);
                        //const res = JSON.parse(decryptData.toString());
                        //const res = {"status": 0,"message":'Server too busy. Please try again after few minutes.'};
                        const res = resdata;   
                        if(res.status==1){
                          /*
                          stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['plot_cards']=res.data.more_plot_cards.plot_cards;
                          stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['loading']=false;
                          stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['status']=1;
                          stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['prompt_content']=res.data.prompt_content;
                          stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['prompt_content_edit_status']=0;
                          */

                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['themes']=res.data.more_plot_cards.plot_cards;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['loading']=false;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['status']=1;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['prompt_content']=res.data.prompt_content;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['prompt_content_edit_status']=0;
                          
                          stepData.current.step3_data.request = {'creative_theme':'','theme_description':'','theme_adjectives':'','no_of_tones':'four','other_possible_tones':''};

                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1); 

                          //----------- User Activity Tracking Code Start Here ------------  
                          setTimeout(() => {
                            userInputOutputTokensTracking({request_for:'adjective_for_show_more_cultural_references',input_tokens:res?.input_tokens,output_tokens:res?.output_tokens,prompt:res.data.prompt_content,request_json:submitData,response_json:res});
                          }, 100);                           
                          //----------- User Activity Tracking Code End Here ------------
                        }else{
                          
                          /*
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['loading']=false;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['status']=res.status;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['message']=res.message;
                          */
                          step2Result.message = res.message;  
                          step2Result.status = res.status;                      
                          stepData.current.step2_data.response=step2Result;
                          stepData.current.step2_data.loading=false;  

                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1); 
                        }
                    });

                  }else{
                   
                    //let objCulturalReferencesIndex = stepData.current.step2_data.response.res.generated_plot_cards.cultural_references.findIndex(obj => obj.name === data.plot_card)
                    //let objCulturalReferencesIndex=0;
                    let objMoreCulturalReferencesIndex = stepData?.current?.step2_data?.request?.selected_more_cultural_reference_index;
                    /*
                    stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['message']='';
                    stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['status']=1;
                    stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['loading']=true;
                    */

                    let selected_keywords_separated_by_comma = '';
                    if(stepData.current.step2_data.request.selected_topics.length>0)
                    {
                      let selectedKeywords = stepData.current.step2_data.request.selected_topics.flatMap(a => a['Subtopics']);  
                      selected_keywords_separated_by_comma = selectedKeywords.join(', ');
                    }

                    stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['loading']=true;
                    stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['status']=1;
                    stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['message']='';
                   
                    let submitData = {};
                    submitData.session_id=kathaiSession?.expiry ? kathaiSession?.expiry : '';
                    submitData.user_id=tokenInfo && tokenInfo?.uid ? tokenInfo?.uid : '';
                    if(data?.prompt_type==='prompt') {
                      stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['prompt_content_edit_status']=0;                      
                      submitData.prompt_changed = data?.prompt_content;   
                      submitData.option="a";       
                      submitData.prompt_type = 1; 
                    }else{
                      /*
                      submitData.selected_reference=data.plot_card;
                      submitData.option="a";
                      submitData.more_plot_cards="3";
                      submitData.prompt_type = 0;
                      submitData.user_input=stepData.current.step1_data.request;   
                      */
                      let no_of_selected_themes = stepData.current.step2_data?.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['themes'].length>3 ? "6" : "3";
                      
                      submitData.user_input=stepData.current.step1_data.request; 
                      submitData.selected_keywords=selected_keywords_separated_by_comma;
                      submitData.cultural_references=stepData.current.step2_data.request.selected_more_cultural_reference;
                      submitData.option="a";
                      submitData.no_of_creative_themes=no_of_selected_themes;
                      submitData.prompt_type = 0;                   
                    }
                    //submitData.action = 'GetF1LT3RPl0TCarD5F0rCrEa1I0NkAthA12025';

                    const result = post(url.GET_FILTER_PLOT_CARDS, submitData);
                    //const encryptData = encrypt(submitData); 
                    //const result = post(url.GET_FILTER_PLOT_CARDS,JSON.parse(encryptData.toString()));
                    result.then((resdata) => {
                        //const decryptData = decrypt(resdata);
                        //const res = JSON.parse(decryptData.toString());
                        //const res = {"status": 0,"message":'Server too busy. Please try again after few minutes.'};
                        const res = resdata;
                        if(res.status==1){
                          /*
                          stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['plot_cards']=res.data.more_plot_cards.plot_cards;
                          stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['loading']=false;
                          stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['status']=1;
                          stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['prompt_content']=res.data.prompt_content;
                          stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['prompt_content_edit_status']=0;
                          */

                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['themes']=res.data.generated_plot_cards.cultural_references[0]['themes'];
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['loading']=false;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['status']=1;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['prompt_content']=res.data.prompt_content;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['prompt_content_edit_status']=0;
                          
                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1); 

                          //----------- User Activity Tracking Code Start Here ------------
                          setTimeout(() => {
                            userInputOutputTokensTracking({request_for:'show_more_cultural_references',input_tokens:res?.input_tokens,output_tokens:res?.output_tokens,prompt:res.data.prompt_content,request_json:submitData,response_json:res});
                          }, 100);                             
                          //----------- User Activity Tracking Code End Here ------------

                        }else{
                          /*
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['loading']=false;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['status']=res.status;
                          stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[objMoreCulturalReferencesIndex]['message']=res.message;
                          */
                          step2Result.message = res.message;  
                          step2Result.status = res.status;                      
                          stepData.current.step2_data.response=step2Result;
                          stepData.current.step2_data.loading=false;  

                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1); 
                        }
                    });
                  }  

                }else if(stepData.current.step2_data.request.selected_adjectives && data?.type==='adjective' && data.plot_card!=''){

                  stepData.current.step3_data.request = {'creative_theme':'','theme_description':'','theme_adjectives':'','no_of_tones':'four','other_possible_tones':''};
                  let objKeyIndex = stepData.current.step2_data.request.selected_adjectives.findIndex(obj => obj.plot_card === data.plot_card);
                  if(stepData.current.step2_data.request.selected_adjectives.length>0 && objKeyIndex>-1)
                  {  
                    let selected_keywords_separated_by_comma = '';
                    if(stepData.current.step2_data.request.selected_topics.length>0)
                    {
                      let selectedKeywords = stepData.current.step2_data.request.selected_topics.flatMap(a => a['Subtopics']);  
                      selected_keywords_separated_by_comma = selectedKeywords.join(', ');
                    }
                                        
                    let selectPlotCard = stepData.current.step2_data.request.selected_adjectives[objKeyIndex]['plot_card'];
                    let selectedAdjectives = stepData.current.step2_data.request.selected_adjectives[objKeyIndex]['adjectives'].join(', ');
                    
                    //let objCulturalReferencesIndex = stepData.current.step2_data.response.res.generated_plot_cards.cultural_references.findIndex(obj => obj.name === data.plot_card)
                    let objCulturalReferencesIndex = stepData?.current?.step2_data?.request?.selected_cultural_reference_index;

                    /*
                    stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['message']='';
                    stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['status']=1;
                    stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['loading']=true;

                    console.log('---------stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]------');
                    console.log(stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]);
                    console.log('objCulturalReferencesIndex:'+objCulturalReferencesIndex);
                                 
                    setData(stepData.current);
                    setCount(count + 1); 
                    */

                    //let submitData = {"references": stepData.current.step2_data.request.selected_cultural_reference,"option": "a","more_plot_cards":"4" };
                    /*
                    let submitData = {
                      "selected_reference": selectPlotCard,
                      "option": "b",
                      "selected_adjective": selectedAdjectives,
                      "more_plot_cards": 4
                    };
                    */

                    let submitData = {};
                    submitData.session_id=kathaiSession?.expiry ? kathaiSession?.expiry : '';
                    submitData.user_id=tokenInfo && tokenInfo?.uid ? tokenInfo?.uid : '';
                    if(data?.prompt_type==='prompt') {
                      stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['prompt_content_edit_status']=0;                      
                      submitData.prompt_changed = data?.prompt_content;   
                      submitData.option="b";       
                      submitData.prompt_type = 1;                      
                    }else{
                      
                      let no_of_selected_themes = stepData.current.step2_data?.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['themes'].length>3 ? "6" : "3";

                      submitData.selected_keywords=selected_keywords_separated_by_comma;
                      submitData.selected_reference=data.plot_card;
                      submitData.option="b";
                      submitData.selected_adjective=selectedAdjectives;
                      submitData.no_of_themes=no_of_selected_themes;
                      submitData.no_of_adjectives="3";
                      submitData.prompt_type = 0;
                      submitData.user_input=stepData.current.step1_data.request;   
                    }
                    //submitData.action = 'GetF1LT3RPl0TCarD5F0rCrEa1I0NkAthA12025';
                    const result = post(url.GET_FILTER_PLOT_CARDS, submitData);
                    //const encryptData = encrypt(submitData); 
                    //const result = post(url.GET_FILTER_PLOT_CARDS,JSON.parse(encryptData.toString()));
                    result.then((resdata) => {
                        //const decryptData = decrypt(resdata);
                        //const res = JSON.parse(decryptData.toString());
                        //const res = {"status": 0,"message":'Server too busy. Please try again after few minutes.'};
                        const res = resdata;
                        if(res.status==1){
                          
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['themes']=res.data.more_plot_cards.plot_cards;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['loading']=false;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['status']=1;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['prompt_content']=res.data.prompt_content;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['prompt_content_edit_status']=0;

                          stepData.current.step3_data.request = {'creative_theme':'','theme_description':'','theme_adjectives':'','no_of_tones':'four','other_possible_tones':''};

                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 2); 
                               
                          //----------- User Activity Tracking Code Start Here ------------  
                          setTimeout(() => {
                            userInputOutputTokensTracking({request_for:'adjective_for_cultural_references',input_tokens:res?.input_tokens,output_tokens:res?.output_tokens,prompt:res.data.prompt_content,request_json:submitData,response_json:res});
                          }, 100);                           
                          //----------- User Activity Tracking Code End Here ------------

                          /*
                          setData(prevState => {
                            // Ensure deep copying to avoid direct mutations
                            const newState = JSON.parse(JSON.stringify(prevState));
                            
                            newState.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['themes'] = res.data.more_plot_cards.plot_cards;
                            newState.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['loading'] = false;
                            newState.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['status'] = 1;
                            newState.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['prompt_content'] = res.data.prompt_content;
                            newState.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['prompt_content_edit_status'] = 0;
                            
                            return newState; // Return the updated object
                          });
                  
                          setIsLoading(false);     
                          setCount(prevCount => prevCount + 2);

                          console.log('New code------------------------');
                          */                          
                          
                        }else{                          
                          /*
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['loading']=false;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['status']=res.status;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['message']=res.message;
                          */
                          step2Result.message = res.message;  
                          step2Result.status = res.status;                      
                          stepData.current.step2_data.response=step2Result;
                          stepData.current.step2_data.loading=false;  
                          //stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['status']=0;
                          //stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['message']='Invalid';

                          //stepData.current.step2_data.loading=false;  
                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1); 
                        }
                    });                   

                  }else{
                   
                    //stepData.current.step2_data.loading=true;
                    //setData(stepData.current);
                    let objCulturalReferencesIndex = stepData.current.step2_data.response.res.generated_plot_cards.cultural_references.findIndex(obj => obj.name === data.plot_card)
                   
                    let selected_keywords_separated_by_comma = '';
                    if(stepData.current.step2_data.request.selected_topics.length>0)
                    {
                      let selectedKeywords = stepData.current.step2_data.request.selected_topics.flatMap(a => a['Subtopics']);  
                      selected_keywords_separated_by_comma = selectedKeywords.join(', ');
                    }

                    stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['message']='';
                    stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['status']=1;
                    stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['loading']=true;
                    //setData(stepData.current);
                    //setCount(count + 1); 

                    //let submitData = {"selected_reference": data.plot_card,"option": "a","more_plot_cards":"4" };
                    let submitData = {};
                    submitData.session_id=kathaiSession?.expiry ? kathaiSession?.expiry : '';
                    submitData.user_id=tokenInfo && tokenInfo?.uid ? tokenInfo?.uid : '';
                    if(data?.prompt_type==='prompt') {
                      stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['prompt_content_edit_status']=0;                      
                      submitData.prompt_changed = data?.prompt_content;   
                      submitData.option="a";       
                      submitData.prompt_type = 1; 
                    }else{
                      /*
                      submitData.selected_reference=data.plot_card;
                      submitData.option="a";
                      submitData.more_plot_cards="3";
                      submitData.prompt_type = 0;
                      submitData.user_input=stepData.current.step1_data.request;
                      */
                      let no_of_selected_themes = stepData.current.step2_data?.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['themes'].length>3 ? "6" : "3";
                      
                      submitData.user_input=stepData.current.step1_data.request; 
                      submitData.selected_keywords=selected_keywords_separated_by_comma;
                      submitData.cultural_references=stepData.current.step2_data.request.selected_cultural_reference;
                      submitData.option="a";
                      submitData.no_of_creative_themes=no_of_selected_themes;
                      submitData.prompt_type = 0;   
                    }
                    //submitData.action = 'GetF1LT3RPl0TCarD5F0rCrEa1I0NkAthA12025';

                    const result = post(url.GET_FILTER_PLOT_CARDS, submitData);
                    //const encryptData = encrypt(submitData); 
                    //const result = post(url.GET_FILTER_PLOT_CARDS,JSON.parse(encryptData.toString()));
                    result.then((resdata) => {
                        //const decryptData = decrypt(resdata);
                        //const res = JSON.parse(decryptData.toString());
                        //const res = {"status": 0,"message":'Server too busy. Please try again after few minutes.'};
                        const res = resdata;
                        if(res.status==1){
                          //stepData.current.step2_data.response.more_plot_cards_res = res.data;
                          //stepData.current.step2_data.response.more_plot_cards_status = 1;                          
                          //stepData.current.step2_data.loading=false;
                          
                          //stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['themes']=res.data.more_plot_cards.plot_cards;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['themes']=res.data.generated_plot_cards.cultural_references[0]['themes'];
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['loading']=false;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['status']=1;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['prompt_content']=res.data.prompt_content;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['prompt_content_edit_status']=0;

                          //stepData.current.step2_data.loading=false;  
                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1); 

                          //----------- User Activity Tracking Code Start Here ------------ 
                          setTimeout(() => {
                            userInputOutputTokensTracking({request_for:'cultural_references',input_tokens:res?.input_tokens,output_tokens:res?.output_tokens,prompt:res.data.prompt_content,request_json:submitData,response_json:res});
                          }, 100);                           
                          //----------- User Activity Tracking Code End Here ------------

                        }else{
                          /*
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['loading']=false;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['status']=res.status;
                          stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['message']=res.message;
                          */

                          step2Result.message = res.message;  
                          step2Result.status = res.status;                      
                          stepData.current.step2_data.response=step2Result;
                          stepData.current.step2_data.loading=false;  
                          
                          //stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['status']=0;
                          //stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[objCulturalReferencesIndex]['message']='Invalid';

                          //stepData.current.step2_data.loading=false;  
                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1); 
                        }
                    });
                  }  


                }else if(stepData.current.step2_data.request.selected_more_cultural_reference && data?.type==='show_more_plot_cards'){

                    /*
                    stepData.current.step2_data.response.more_plot_cards_status = 0;
                    stepData.current.step2_data.more_cultural_reference_loading=true;
                    stepData.current.step2_data.response.more_plot_cards_res = [];
                    stepData.current.step2_data.response.more_plot_cards_status = 0;
                    */ 

                    let selected_keywords_separated_by_comma = '';
                    if(stepData.current.step2_data.request.selected_topics.length>0)
                    {
                      let selectedKeywords = stepData.current.step2_data.request.selected_topics.flatMap(a => a['Subtopics']);  
                      selected_keywords_separated_by_comma = selectedKeywords.join(', ');
                    }
                                        
                    //let submitData = {"references": stepData.current.step2_data.request.selected_more_cultural_reference,"option": "a","more_plot_cards":"4" };
                    let submitData = {};
                    submitData.session_id=kathaiSession?.expiry ? kathaiSession?.expiry : '';
                    submitData.user_id=tokenInfo && tokenInfo?.uid ? tokenInfo?.uid : '';
                    if(data?.type==='prompt') {
                      submitData.prompt_changed = data?.data?.promptContent;          
                      submitData.prompt_type = 1;
                    }else{
                      //submitData.references=stepData.current.step2_data.request.selected_more_cultural_reference;
                      submitData.user_input=stepData.current.step1_data.request; 
                      submitData.selected_keywords=selected_keywords_separated_by_comma;
                      submitData.cultural_references=stepData.current.step2_data.request.selected_more_cultural_reference;
                      submitData.option="a";
                      submitData.no_of_creative_themes="3";
                      submitData.prompt_type = 0;              
                    }
                    //submitData.action = 'GetF1LT3RPl0TCarD5F0rCrEa1I0NkAthA12025';
                    
                    //------------------------ More Plot Cards Dynamic Code Start Here --------------------------  
                                                                                         
                    const result = post(url.GET_FILTER_PLOT_CARDS, submitData);
                    //const encryptData = encrypt(submitData); 
                    //const result = post(url.GET_FILTER_PLOT_CARDS,JSON.parse(encryptData.toString()));
                    result.then((resdata) => {
                        //const decryptData = decrypt(resdata);
                        //const res = JSON.parse(decryptData.toString());
                        //const res = {"status": 0,"message":'Server too busy. Please try again after few minutes.'};
                        const res = resdata;
                        if(res.status==1){   

                          /*
                          console.log('stepData.current.step2_data.request.selected_more_cultural_reference_index');
                          console.log(stepData.current.step2_data.request.selected_more_cultural_reference_index);
                          */
                         
                          /*
                          console.log(stepData?.current?.step2_data?.response?.more_plot_cards_res);
                          console.log(stepData?.current?.step2_data?.response?.more_plot_cards_res?.generated_plot_cards);
                          console.log(stepData?.current?.step2_data?.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references);
                          return;
                          if(
                            stepData?.current?.step2_data?.response?.more_plot_cards_res !== undefined && 
                            stepData?.current?.step2_data?.response?.more_plot_cards_res?.generated_plot_cards !== undefined &&
                            stepData?.current?.step2_data?.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references !== undefined
                          )
                          {
                            */
                          const morePlotCardsRes = stepData?.current?.step2_data?.response?.more_plot_cards_res;
                          const exists = morePlotCardsRes && "generated_plot_cards" in morePlotCardsRes && "cultural_references" in morePlotCardsRes.generated_plot_cards;
                          //console.log('exists:'+exists);
                          if(exists && exists==true) 
                          {
                            stepData.current.step2_data.request.selected_more_cultural_reference_index = stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.current?.step2_data?.request?.selected_more_cultural_reference);

                            /*
                            console.log('stepData.current.step2_data.request.selected_more_cultural_reference_index');
                            console.log(stepData.current.step2_data.request.selected_more_cultural_reference_index);
                            console.log(stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_more_cultural_reference_index]);
                            */

                            //let morePlotCardsObj = stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_more_cultural_reference_index]['themes'];
                            let morePlotCardsObj = stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references;
                            let showMorePlotCardsObj = res.data.generated_plot_cards.cultural_references;
                            //console.log('------morePlotCardsObj----------');
                            //console.log(morePlotCardsObj);
                            //console.log('------showMorePlotCardsObj----------');
                            //console.log(showMorePlotCardsObj);
                            const mergedPlotCards = [...morePlotCardsObj, ...showMorePlotCardsObj];  

                            //stepData.current.step2_data.response=step2Result;
                            //stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_more_cultural_reference_index]['themes']=mergedPlotCards;
                            stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references=mergedPlotCards;
                          }else{
                            stepData.current.step2_data.response.more_plot_cards_res = res.data;
                            stepData.current.step2_data.response.more_plot_cards_status = res.status; 
                          } 

                          //console.log(stepData.current.step2_data.response.more_plot_cards_res);
                                             
                          //stepData.current.step2_data.more_cultural_reference_loading=false;
                          //stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['prompt_content']=morePlotCardObj?.data?.prompt_content;
                          //stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['prompt_content_edit_status']=0;
                                                   
                          //if(stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.current?.step2_data?.request?.selected_more_cultural_reference)>=0){
                            //stepData.current.step2_data.request.selected_more_cultural_reference_index = stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.current?.step2_data?.request?.selected_more_cultural_reference);
                          //}else if(stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.current?.step2_data?.request?.selected_more_cultural_reference)>=0){
                            //console.log('------stepData?.current?.step2_data?.request?.selected_more_cultural_reference----');
                            //console.log(stepData?.current?.step2_data?.request?.selected_more_cultural_reference);
                            stepData.current.step2_data.request.selected_more_cultural_reference_index = stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.current?.step2_data?.request?.selected_more_cultural_reference);
                          //}else{
                            //stepData.current.step2_data.request.selected_more_cultural_reference_index = '-1'; 
                          //}

                          //console.log('stepData.current.step2_data.request.selected_more_cultural_reference_index---out');
                          //console.log(stepData.current.step2_data.request.selected_more_cultural_reference_index);
                                                    
                          //stepData.current.step2_data.request.selected_more_cultural_reference_index = 0; 

                          if(stepData.current.step2_data.request.selected_more_cultural_reference_index>=0)
                          {
                            //stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_more_cultural_reference_index].loading = false;
                          }
      
                          stepData.current.step2_data.more_cultural_reference_loading=false;  
                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1); 

                          //----------- User Activity Tracking Code Start Here ------------        
                          setTimeout(() => {
                            userInputOutputTokensTracking({request_for:'show_more_cultural_references',input_tokens:res?.input_tokens,output_tokens:res?.output_tokens,prompt:res.data.prompt_content,request_json:submitData,response_json:res});
                          }, 100);                    
                          //----------- User Activity Tracking Code End Here ------------

                        }else{
                          //step2Result.more_plot_cards_res = res.message;  
                          //step2Result.more_plot_cards_status = res.status;                      
                          //stepData.current.step2_data.response=step2Result; 
                          //stepData.current.step2_data.response.more_plot_cards_res = res.message; 
                          //stepData.current.step2_data.response.more_plot_cards_status = res.status;  
                          //stepData.current.step2_data.response.more_plot_cards_res = 'Error'; 
                          //stepData.current.step2_data.response.more_plot_cards_status = 0;  

                          //more_plot_cards_res.more_plot_cards?.message
                          /*
                          stepData.current.step2_data.response.more_plot_cards_res={
                            "generated_plot_cards": {
                              "cultural_references": []
                            }
                          };
                          */

                          //stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references["status"]=res.status; 
                          //stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references["message"]=res.message;

                          //stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards.status=0;
                          //stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards.message='Error';

                          step2Result.message = res.message;  
                          step2Result.status = res.status;                      
                          stepData.current.step2_data.response=step2Result;
                                                          
                          stepData.current.step2_data.loading=false;  
                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1); 
                        }
                    });    
                                                 
                    //------------------------ More Plot Cards Dynamic Code End Here -------------------------- 

                    //------------------------ More Plot Cards Static Code Start Here -------------------------- 
                    //let objMoreCulturalReferencesIndex = stepData?.current?.step2_data?.request?.selected_more_cultural_reference_index;
                    /*
                    const morePlotCardObj = {
                      "data": {
                        "more_plot_cards": {
                          "plot_cards": [
                            {
                              "adjectives": [
                                "MYSTICAL",
                                "ENIGMATIC",
                                "POWERFUL"
                              ],
                              "description": "Delve into the secrets of ancient Egyptian magic and sorcery. Explore the mystical rituals, spells, and incantations used by priests and magicians to communicate with the gods and harness supernatural powers. Uncover the hidden knowledge of sacred scrolls and magical amulets that were believed to protect and empower their bearers.",
                              "theme": "Egyptian Magic and Sorcery"
                            },
                            {
                              "adjectives": [
                                "CELESTIAL",
                                "CYCLICAL",
                                "DIVINE"
                              ],
                              "description": "Immerse yourself in the cosmic beliefs of ancient Egyptians. Discover how they interpreted celestial events, tracked the movements of stars and planets, and aligned their monuments with astronomical phenomena. Explore the significance of solar and lunar cycles in their religious practices and daily lives, and how these celestial bodies were personified as deities.",
                              "theme": "Egyptian Astronomy and Cosmic Beliefs"
                            },
                            {
                              "adjectives": [
                                "TRANSFORMATIVE",
                                "SPIRITUAL",
                                "ETERNAL"
                              ],
                              "description": "Journey through the ancient Egyptian concept of the afterlife. Unravel the complex rituals and beliefs surrounding death, mummification, and the soul's passage to the underworld. Explore the Book of the Dead, the weighing of the heart ceremony, and the eternal cycle of rebirth that shaped Egyptian spirituality and funerary practices for millennia.",
                              "theme": "Egyptian Afterlife and Rebirth"
                            }
                          ]
                        },
                        "prompt_content": "They have asked for Generate a warm onboarding email for a new employee joining and For the cultural reference Ancient Egyptian Mythology, can you generate 3 more creative themes with description and adjectives similar to the table above? "
                      },
                      "status": 1
                    };
                    */

                    {
                     /*
                     "Ancient Egyptian Mythology",
                     "Classic Fairy Tales",
                     "World Cuisines",
                     "Historical Figures",
                     "Contemporary Art Movements",
                     "Traditional Folk Music"
                     */
                    }                   

                    // -------------------------------- New Working Code Start Here ----------------------------------- 
                    /*                
                    const res = {
                      "data": {
                          "generated_plot_cards": {
                              "cultural_references": [
                                {
                                    "name": "Ancient Egyptian Mythology",
                                    "themes": [
                                        {
                                            "adjectives": "Majestic, Divine, Prestigious",
                                            "description": "Inspired by the laurel wreaths worn by Greek gods and victorious athletes, this theme transforms the email into a grand Olympian announcement. The layout mimics an ancient scroll, with golden laurel borders framing the text. Each award category is represented by a different Greek deity, symbolizing the diverse talents being recognized.",
                                            "title": "111Olympian Laurels"
                                        },
                                        {
                                            "adjectives": "Heroic, Challenging, Triumphant",
                                            "description": "Drawing from Hercules' twelve labors, this theme presents each award as a monumental achievement. The email design features intricate illustrations of mythical beasts and obstacles, with recipients portrayed as modern-day heroes overcoming professional challenges. The overall aesthetic combines ancient Greek art styles with contemporary corporate elements.",
                                            "title": "Labors of Hercules"
                                        },
                                        {
                                            "adjectives": "Mystical, Prophetic, Enlightening",
                                            "description": "Inspired by the ancient Oracle of Delphi, this theme presents the awards as divine prophecies of success. The email layout resembles a misty temple, with each award revelation emerging from swirling clouds. The language used is poetic and enigmatic, adding an air of mystery and importance to each recognition.",
                                            "title": "Oracle of Delphi"
                                        }
                                    ]
                                },
                                {
                                  "name": "Classic Fairy Tales",
                                  "themes": [
                                      {
                                          "adjectives": "Majestic, Divine, Prestigious",
                                          "description": "Inspired by the laurel wreaths worn by Greek gods and victorious athletes, this theme transforms the email into a grand Olympian announcement. The layout mimics an ancient scroll, with golden laurel borders framing the text. Each award category is represented by a different Greek deity, symbolizing the diverse talents being recognized.",
                                          "title": "222Olympian Laurels"
                                      },
                                      {
                                          "adjectives": "Heroic, Challenging, Triumphant",
                                          "description": "Drawing from Hercules' twelve labors, this theme presents each award as a monumental achievement. The email design features intricate illustrations of mythical beasts and obstacles, with recipients portrayed as modern-day heroes overcoming professional challenges. The overall aesthetic combines ancient Greek art styles with contemporary corporate elements.",
                                          "title": "Labors of Hercules"
                                      },
                                      {
                                          "adjectives": "Mystical, Prophetic, Enlightening",
                                          "description": "Inspired by the ancient Oracle of Delphi, this theme presents the awards as divine prophecies of success. The email layout resembles a misty temple, with each award revelation emerging from swirling clouds. The language used is poetic and enigmatic, adding an air of mystery and importance to each recognition.",
                                          "title": "Oracle of Delphi"
                                      }
                                  ]
                                }
                              ]
                          },
                          "prompt_content": "They have asked for I want to write an email for an awards and recognitions and For the keywords email,awards and recognitions, can you generate creative themes that are inspired from diverse cultural references like Greek Mythology. Show up to 3 creative themes for each of the cultural references, with rich, detailed descriptions for each of them. Show it as a table please. In the table also include a column to highlight adjectives that best suit the theme."
                      },
                      "status": 1
                    }; 
                    

                    if(
                      stepData?.current?.step2_data?.response?.more_plot_cards_res !== undefined && 
                      stepData?.current?.step2_data?.response?.more_plot_cards_res?.generated_plot_cards !== undefined &&
                      stepData?.current?.step2_data?.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references !== undefined
                    )
                    {
                      //console.log('--------------------Innnn222222222222---------------------------------');
                      let morePlotCardsObj = stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references;
                      
                      //let showMorePlotCardsObj = res.data.generated_plot_cards.cultural_references[0]['themes'];
                      let showMorePlotCardsObj = res.data.generated_plot_cards.cultural_references[0];
                      showMorePlotCardsObj.name = stepData.current.step2_data.request.selected_more_cultural_reference;
                      //showMorePlotCardsObj.name.themes[0].title=showMorePlotCardsObj.name;  

                      const mergedPlotCards = [...morePlotCardsObj, showMorePlotCardsObj];  

                     

                      //stepData.current.step2_data.response=step2Result;
                      stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references=mergedPlotCards;
                    }else{
                      stepData.current.step2_data.response.more_plot_cards_res = res.data;
                      stepData.current.step2_data.response.more_plot_cards_status = res.status; 
                    }

                    stepData.current.step2_data.request.selected_more_cultural_reference_index = 0; 

                    stepData.current.step2_data.more_cultural_reference_loading=false;  
                    stepData.current.step2_data.loading=false; 
                    setData(stepData.current);
                    setIsLoading(false);     
                    setCount(count + 1); 
                    */

                    /*
                    if(!stepData?.current.step2_data?.response.more_plot_cards_res?.generated_plot_cards?.cultural_references)
                    {
                      stepData.current.step2_data.response.more_plot_cards_res = morePlotCardObj.data;
                    }else{
                      console.log('stepData?.current.step2_data?.response.more_plot_cards_res?.generated_plot_cards?.cultural_references1111111111111');
                      console.log(stepData?.current.step2_data?.response.more_plot_cards_res?.generated_plot_cards?.cultural_references);
                      const newStep = {
                        "name": "World Cuisines",
                        "themes": [
                            {
                                "adjectives": "Majestic, Divine, Prestigious",
                                "description": "Inspired by the laurel wreaths worn by Greek gods and victorious athletes, this theme transforms the email into a grand Olympian announcement. The layout mimics an ancient scroll, with golden laurel borders framing the text. Each award category is represented by a different Greek deity, symbolizing the diverse talents being recognized.",
                                "title": "222Olympian Laurels"
                            },
                            {
                                "adjectives": "Heroic, Challenging, Triumphant",
                                "description": "Drawing from Hercules' twelve labors, this theme presents each award as a monumental achievement. The email design features intricate illustrations of mythical beasts and obstacles, with recipients portrayed as modern-day heroes overcoming professional challenges. The overall aesthetic combines ancient Greek art styles with contemporary corporate elements.",
                                "title": "Labors of Hercules"
                            },
                            {
                                "adjectives": "Mystical, Prophetic, Enlightening",
                                "description": "Inspired by the ancient Oracle of Delphi, this theme presents the awards as divine prophecies of success. The email layout resembles a misty temple, with each award revelation emerging from swirling clouds. The language used is poetic and enigmatic, adding an air of mystery and importance to each recognition.",
                                "title": "Oracle of Delphi"
                            }
                        ]
                      };
  
                      //stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references = [...stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references, newStep];
                      console.log('Innnnnnnnnnnnnnnnnnnnnnnnnnnn');
                      console.log(stepData?.current.step2_data?.response.more_plot_cards_res?.generated_plot_cards?.cultural_references);
                    }
                    */
                    
                    /*
                    stepData.current.step2_data.response.more_plot_cards_res = morePlotCardObj.data;
                    stepData.current.step2_data.response.more_plot_cards_status = morePlotCardObj.status;                          
                    stepData.current.step2_data.more_cultural_reference_loading=true;
                    //stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['prompt_content']=morePlotCardObj?.data?.prompt_content;
                    //stepData.current.step2_data.response.more_plot_cards_res.more_plot_cards['prompt_content_edit_status']=0;

                    if(stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.current?.step2_data?.request?.selected_more_cultural_reference)>=0){
                      stepData.current.step2_data.request.selected_more_cultural_reference_index = stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.current?.step2_data?.request?.selected_more_cultural_reference);
                    }else if(stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.current?.step2_data?.request?.selected_more_cultural_reference)>=0){
                      stepData.current.step2_data.request.selected_more_cultural_reference_index = stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.current?.step2_data?.request?.selected_more_cultural_reference);
                    }else{
                      stepData.current.step2_data.request.selected_more_cultural_reference_index = '-1'; 
                    }

                    if(stepData.current.step2_data.request.selected_more_cultural_reference_index>=0)
                    {
                      //stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_more_cultural_reference_index].loading = false;
                    }

                    

                    //console.log('stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references');
                    //console.log(stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references);

                    stepData.current.step2_data.more_cultural_reference_loading=false;  
                    setData(stepData.current);
                    setIsLoading(false);     
                    setCount(count + 1);  
                    */        
                    // -------------------------------- New Working Code End Here -----------------------------------

                    /*
                    const newStep = {
                      "name": "Classic Fairy Tales"+Math.random(),
                      "themes": [
                          {
                              "adjectives": "Majestic, Divine, Prestigious",
                              "description": "Inspired by the laurel wreaths worn by Greek gods and victorious athletes, this theme transforms the email into a grand Olympian announcement. The layout mimics an ancient scroll, with golden laurel borders framing the text. Each award category is represented by a different Greek deity, symbolizing the diverse talents being recognized.",
                              "title": "222Olympian Laurels"
                          },
                          {
                              "adjectives": "Heroic, Challenging, Triumphant",
                              "description": "Drawing from Hercules' twelve labors, this theme presents each award as a monumental achievement. The email design features intricate illustrations of mythical beasts and obstacles, with recipients portrayed as modern-day heroes overcoming professional challenges. The overall aesthetic combines ancient Greek art styles with contemporary corporate elements.",
                              "title": "Labors of Hercules"
                          },
                          {
                              "adjectives": "Mystical, Prophetic, Enlightening",
                              "description": "Inspired by the ancient Oracle of Delphi, this theme presents the awards as divine prophecies of success. The email layout resembles a misty temple, with each award revelation emerging from swirling clouds. The language used is poetic and enigmatic, adding an air of mystery and importance to each recognition.",
                              "title": "Oracle of Delphi"
                          }
                      ]
                    };

                    console.log(data?.type);
                    console.log(data?.morePlotCardsCurrentData);                    
                    console.log(stepData.step2_data);
                    console.log(stepData.step2_data?.response?.more_plot_cards_res?.generated_plot_cards);
                    //console.log(stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.length);
                    //if(stepData.current.step2_data.response.more_plot_cards_res.length>0 && stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references.length<=0)
                    //if(stepData?.step2_data?.response?.more_plot_cards_res?.generated_plot_cards===undefined)
                    //{
                      let combinedDetails = '';
                      let oldMorePlotCardsCurrentData = data?.morePlotCardsCurrentData;
                      console.log('oldMorePlotCardsCurrentData::'+oldMorePlotCardsCurrentData.length);
                      console.log(oldMorePlotCardsCurrentData);
                      console.log(oldMorePlotCardsCurrentData.length);
                      if(oldMorePlotCardsCurrentData.length>0 && stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards!==undefined)
                      {
                        //combinedDetails = oldMorePlotCardsCurrentData.concat(morePlotCardObj.data);
                        //stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references = [...oldMorePlotCardsCurrentData, newStep];
                        stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[2]=newStep;
                        console.log('Innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn');
                        combinedDetails = morePlotCardObj.data.generated_plot_cards.cultural_references;
                      }else{
                        stepData.current.step2_data.response.more_plot_cards_res =  morePlotCardObj.data;
                      }                       
                      
                    //}                    
                                   
                    stepData.current.step2_data.response.more_plot_cards_status = morePlotCardObj.status; 
                    */

                    /*
                    console.log('Innnn--------------------');
                    console.log(stepData.current.step2_data.request.selected_more_cultural_reference);
                    console.log(stepData.current.step2_data.response?.more_plot_cards_res);
                    console.log(stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.current?.step2_data?.request?.selected_more_cultural_reference));
                    console.log(stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.current?.step2_data?.request?.selected_more_cultural_reference));

                    console.log('Innnn----1111----------');
                    */
                    
                    /*
                    let combinedDetails = stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references.concat(newStep);
                    console.log('---------------combinedDetails-----------------');
                    console.log(combinedDetails);
                    */
                    //stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references = [...stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references, newStep]; 
                    //stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references.length] = newStep;
                    //console.log(stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references.length);    

                    /*
                    if(stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.current?.step2_data?.request?.selected_more_cultural_reference)>=0){
                        stepData.current.step2_data.request.selected_more_cultural_reference_index = stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.current?.step2_data?.request?.selected_more_cultural_reference);
                        //console.log('Innnn----2222----------');
                    }else if(stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.current?.step2_data?.request?.selected_more_cultural_reference)>=0){
                        stepData.current.step2_data.request.selected_more_cultural_reference_index = stepData.current.step2_data.response?.more_plot_cards_res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.current?.step2_data?.request?.selected_more_cultural_reference);
                        //console.log('Innnn----3333----------');
                    }else{
                      stepData.current.step2_data.request.selected_more_cultural_reference_index = '-1'; 
                    }

                    stepData.current.step2_data.loading=false;                                    
                        
                    setData(stepData.current);
                    setIsLoading(false);     
                    setCount(count + 1); 
                    */
                    //------------------------ More Plot Cards Static Code End Here -------------------------- 
                                       
                    /*
                    res = {
                        "more_plot_cards": {
                            "plot_cards": [
                                {
                                    "adjectives": [
                                        "Ethereal",
                                        "Mystical",
                                        "Luminous",
                                        "Enchanted"
                                    ],
                                    "description": "In a realm where reality blends with dreams, celestial beings descend from the cosmos to guide mortals through a labyrinth of starlit pathways. Visitors traverse floating islands adorned with bioluminescent flora, while ancient wisdom whispers through shimmering auroras that paint the sky.",
                                    "theme": "Celestial Dreamscape"
                                },
                                {
                                    "adjectives": [
                                        "Mechanical",
                                        "Steampunk",
                                        "Intricate",
                                        "Clockwork"
                                    ],
                                    "description": "A world powered by gears and steam, where brass automatons roam cobblestone streets lit by gas lamps. Airships dock at towering spires, their propellers whirring against a smog-filled sky. Inventors tinker in workshops filled with cogs, pistons, and mysterious contraptions that defy the laws of physics.",
                                    "theme": "Clockwork Metropolis"
                                },
                                {
                                    "adjectives": [
                                        "Primordial",
                                        "Lush",
                                        "Prehistoric",
                                        "Untamed"
                                    ],
                                    "description": "Journey back to a time when dinosaurs ruled the Earth, where dense jungles teem with colossal ferns and cycads. Pterodactyls soar over bubbling tar pits and erupting volcanoes, while early mammals scurry beneath the feet of towering sauropods. The air is thick with the scent of prehistoric flora and the calls of long-extinct creatures.",
                                    "theme": "Jurassic Wilderness"
                                },
                                {
                                    "adjectives": [
                                        "Neon",
                                        "Cybernetic",
                                        "Futuristic",
                                        "Dystopian"
                                    ],
                                    "description": "A sprawling megalopolis where holographic advertisements illuminate rain-slicked streets. Cybernetically enhanced humans navigate a world of corporate espionage and underground resistance. Towering skyscrapers pierce the polluted sky, while in the depths of the city, hackers wage digital warfare in the ever-expanding virtual realm.",
                                    "theme": "Neo-Tokyo 2099"
                                }
                            ]
                        }
                    };
                   
                    stepData.current.step2_data.response.more_plot_cards_res = res;
                    //stepData.current.step2_data.response.more_plot_cards_status = res.status;
                    stepData.current.step2_data.response.more_plot_cards_status = 1;
                    stepData.current.step2_data.loading=false; 
                    
                    stepData.current.step2_data.loading=false;  
                    setData(stepData.current);
                    setIsLoading(false);     
                    setCount(count + 1);     
                    */    
                  
                }else if(data?.type==='show_more_theme'){

                  //stepData.current.step2_data.loading=true;
                  //setData(stepData.current); 

                  let selectedAdjectives = '';

                  let cultural_references_for_show_more_theme = '';
                  let title_for_show_more_theme = '';
                  if(stepData.current.step2_data.request.show_more_cultural_reference_status)
                  {
                    cultural_references_for_show_more_theme = stepData.current.step2_data.request.selected_more_cultural_reference;
                    title_for_show_more_theme = stepData.current.step2_data?.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_more_cultural_reference_index]['themes'].map(theme => theme.title);                  
                    
                    let objKeyIndex = stepData?.current?.step2_data?.request?.selected_future_adjectives.findIndex(obj => obj.plot_card.toLowerCase() === cultural_references_for_show_more_theme.toLowerCase());
                    if(objKeyIndex>-1)
                    {
                        let selected_adjectives_length = stepData?.current?.step2_data?.request?.selected_future_adjectives[objKeyIndex]['adjectives'].length;
                        if(selected_adjectives_length>0)
                        {
                          selectedAdjectives =stepData?.current?.step2_data?.request?.selected_future_adjectives[objKeyIndex]['adjectives'].join(', ');
                        }
                    }
                    
                  }else{
                    cultural_references_for_show_more_theme = stepData.current.step2_data.request.selected_cultural_reference;
                    title_for_show_more_theme = stepData.current.step2_data?.response.res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_cultural_reference_index]['themes'].map(theme => theme.title);                  
                    
                    let objKeyIndex = stepData?.current?.step2_data?.request?.selected_adjectives.findIndex(obj => obj.plot_card.toLowerCase() === cultural_references_for_show_more_theme.toLowerCase());
                    if(objKeyIndex>-1)
                    {
                        let selected_adjectives_length = stepData?.current?.step2_data?.request?.selected_adjectives[objKeyIndex]['adjectives'].length;
                        if(selected_adjectives_length>0)
                        {
                          selectedAdjectives =stepData?.current?.step2_data?.request?.selected_adjectives[objKeyIndex]['adjectives'].join(', ');
                        }
                    }
                  }

                  if(title_for_show_more_theme!=='' && title_for_show_more_theme.length>0)
                  {
                    title_for_show_more_theme = title_for_show_more_theme.join(',');
                  }                                    
                  
                  /*
                  if(stepData?.current?.step2_data?.request?.selected_adjectives?.length>0)
                  {
                    if(stepData?.current?.step2_data?.request?.selected_adjectives?.length>0)
                    {
                    console.log(stepData?.current?.step2_data?.request?.selected_adjectives?.length);
                  }
                  */
                  
                  /*
                  let selectedAdjectives = '';
                  if(
                      stepData?.current?.step2_data?.request?.selected_adjectives?.length>0 &&
                      stepData?.current?.step2_data?.request?.selected_adjectives[stepData?.current?.step2_data?.request?.selected_cultural_reference_index]
                    )
                  {
                    //selectedAdjectives = stepData.current.step2_data.request.selected_adjectives[stepData.current.step2_data.request.selected_cultural_reference_index]['adjectives'].join(', ');
                  }
                  return;
                  */

                  let selected_keywords_separated_by_comma = '';
                  if(stepData.current.step2_data.request.selected_topics.length>0)
                  {
                    let selectedKeywords = stepData.current.step2_data.request.selected_topics.flatMap(a => a['Subtopics']);  
                    selected_keywords_separated_by_comma = selectedKeywords.join(', ');
                  }
                                    
                  //let submitData = {"selected_keywords": selected_keywords_separated_by_comma,"cultural_references": cultural_references.join(', '),"future_cultural_references":"six","no_of_creative_themes":"five"};
                  let submitData = {};
                  submitData.session_id=kathaiSession?.expiry ? kathaiSession?.expiry : '';
                  submitData.user_id=tokenInfo && tokenInfo?.uid ? tokenInfo?.uid : '';
                  if(data?.type==='prompt') {
                    submitData.prompt_changed = data?.data?.promptContent;          
                    submitData.prompt_type = 1;
                  }else{
                    submitData.user_input=stepData.current.step1_data.request;
                    submitData.selected_keywords=selected_keywords_separated_by_comma;
                    submitData.cultural_references=cultural_references_for_show_more_theme;
                    submitData.title=title_for_show_more_theme;
                    submitData.selected_adjective=selectedAdjectives;
                    submitData.no_of_creative_themes="three";      
                    submitData.no_of_adjectives="3";                    
                    submitData.prompt_type = 0;                     
                  }
                  //submitData.action = 'G3tPL0TCaRdSF0rCrEa1I0NkAthA12025';
                                    
                  //--------------------------------- Plot Card Dynamic Code Start Here --------------------    
                                 
                  const result = post(url.GET_SHOW_MORE_PLOT_CARDS, submitData);
                  //const encryptData = encrypt(submitData); 
                  //const result = post(url.GET_SHOW_MORE_PLOT_CARDS,JSON.parse(encryptData.toString()));
                  result.then((resdata) => {
                      //const decryptData = decrypt(resdata);
                      //const res = JSON.parse(decryptData.toString());
                      //const res = {"status": 0,"message":'Server too busy. Please try again after few minutes.'};
                      const res = resdata;
                      if(res.status==1){                          
                          step2Result.res = res.data;
                          step2Result.status = res.status;

                          if(stepData.current.step2_data.request.show_more_cultural_reference_status){
                            let themeObj = stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_more_cultural_reference_index]['themes'];
                            let showMoreThemeObj = step2Result.res.generated_plot_cards.cultural_references[0]['themes'];
                            const mergedTheme = [...themeObj, ...showMoreThemeObj];      
                            //stepData.current.step2_data.response=step2Result;
                            stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_more_cultural_reference_index]['themes']=mergedTheme;
                          }else{
                            let themeObj = stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_cultural_reference_index]['themes'];
                            let showMoreThemeObj = step2Result.res.generated_plot_cards.cultural_references[0]['themes'];
                            const mergedTheme = [...themeObj, ...showMoreThemeObj];      
                            //stepData.current.step2_data.response=step2Result;
                            stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_cultural_reference_index]['themes']=mergedTheme;
                          }
                          step2Result.status = 1;
                          stepData.current.step2_data.loading=false;                                    
                      
                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1);   

                          //----------- User Activity Tracking Code Start Here ------------  
                          setTimeout(() => {
                            userInputOutputTokensTracking({request_for:'show_more_theme_for_cultural_references',input_tokens:res?.input_tokens,output_tokens:res?.output_tokens,prompt:res.data.prompt_content,request_json:submitData,response_json:res});
                          }, 100);                          
                          //----------- User Activity Tracking Code End Here ------------
                          
                      }else{

                          step2Result.message = res.message;  
                          step2Result.status = res.status;                      
                          //step2Result.message = 'There is no keyword matches to the input given.';
                          //step2Result.status = 0;
                          stepData.current.step2_data.response=step2Result;
                          
                          stepData.current.step2_data.loading=false;                                    
                      
                          setData(stepData.current);
                          setIsLoading(false);     
                          setCount(count + 1);   
                      }                                       
                  });    
                                               
                  //--------------------------------- Plot Card Dynamic Code End Here --------------------
                  
                  //--------------------------------- Plot Card Static Code Start Here -------------------- 
                  /*
                  step2Result.res = {
                    "generated_plot_cards": { 
                      "cultural_references": [
                        {
                          "name": "Greek Mythology",
                          "themes": [
                            {
                              "adjectives": "Compassionate, Reflective, Transformative",
                              "description": "Inspired by King Midas, this theme explores how kindness can be more valuable than material wealth. It emphasizes the importance of human connections and the transformative power of empathy, encouraging individuals to value relationships over possessions.",
                              "title": "The Golden Touch of Midas"
                            },
                            {
                              "adjectives": "Selfless, Enlightening, Progressive",
                              "description": "Drawing from Prometheus' act of giving fire to humanity, this theme celebrates ethical behavior that benefits others at personal cost. It encourages self-improvement through knowledge-sharing and acting for the greater good of society.",
                              "title": "Prometheus' Gift"
                            },
                            {
                              "adjectives": "Insightful, Strategic, Balanced",
                              "description": "Inspired by the goddess of wisdom, this theme focuses on developing emotional intelligence through strategic thinking and balanced decision-making. It emphasizes the importance of combining intellect with empathy in personal growth.",
                              "title": "Athena's Wisdom"
                            }
                          ]
                        },
                        {
                          "name": "Popular Books",
                          "themes": [
                            {
                              "adjectives": "Compassionate, Just, Understanding",
                              "description": "Inspired by Harper Lee's novel, this theme explores kindness and ethical behavior through the lens of empathy and standing up for what's right. It encourages individuals to see the world from others' perspectives and act with compassion.",
                              "title": "To Kill a Mockingbird's Empathy"
                            },
                            {
                              "adjectives": "Purposeful, Transformative, Spiritual",
                              "description": "Drawing from Paulo Coelho's book, this theme focuses on self-improvement as a journey of discovering and fulfilling one's personal legend. It emphasizes the importance of following one's heart and learning from life's experiences.",
                              "title": "The Alchemist's Personal Legend"
                            },
                            {
                              "adjectives": "Authentic, Defiant, Empowering",
                              "description": "Inspired by George Orwell's dystopian novel, this theme explores emotional intelligence as a form of resistance against oppression. It encourages individuals to maintain their humanity and empathy in challenging circumstances.",
                              "title": "1984's Emotional Rebellion"
                            }
                          ]
                        },
                        {
                          "name": "Nature",
                          "themes": [
                            {
                              "adjectives": "Generous, Supportive, Grounding",
                              "description": "Inspired by trees' role in ecosystems, this theme explores kindness as a nurturing force. It encourages individuals to provide support and resources to others, creating a network of mutual growth and sustainability.",
                              "title": "The Nurturing Tree"
                            },
                            {
                              "adjectives": "Collaborative, Communicative, Purposeful",
                              "description": "Drawing from bee behavior, this theme focuses on ethical conduct within communities. It emphasizes clear communication, collaboration, and working towards common goals for the benefit of all.",
                              "title": "The Honeybee's Dance"
                            },
                            {
                              "adjectives": "Transformative, Patient, Resilient",
                              "description": "Inspired by the butterfly's life cycle, this theme explores self-improvement as a process of profound transformation. It encourages embracing change, having patience during growth periods, and emerging stronger.",
                              "title": "The Butterfly's Metamorphosis"
                            }
                          ]
                        },
                        {
                          "name": "Well-Known Movies",
                          "themes": [
                            {
                              "adjectives": "Genuine, Unassuming, Impactful",
                              "description": "Inspired by the titular character, this theme explores how simple acts of kindness can have profound impacts. It encourages genuine, unpretentious goodwill and perseverance in the face of challenges.",
                              "title": "Forrest Gump's Simple Kindness"
                            },
                            {
                              "adjectives": "Courageous, Principled, Selfless",
                              "description": "Drawing from Schindler's actions, this theme focuses on ethical behavior in extreme circumstances. It emphasizes moral courage, standing up for what's right, and making a difference in others' lives, even at personal risk.",
                              "title": "Schindler's List of Ethics"
                            },
                            {
                              "adjectives": "Resilient, Patient, Transformative",
                              "description": "Inspired by Andy Dufresne's journey, this theme explores self-improvement as a long-term process requiring hope and perseverance. It encourages maintaining integrity and pursuing personal growth even in restrictive environments.",
                              "title": "The Shawshank Redemption's Hope"
                            }
                          ]
                        },
                        {
                          "name": "Pop Culture",
                          "themes": [
                            {
                              "adjectives": "Confident, Inspiring, Uplifting",
                              "description": "Inspired by Beyonc\u00e9's music and persona, this theme explores self-improvement through self-confidence and empowerment. It encourages individuals to embrace their strengths, support others, and use their voice for positive change.",
                              "title": "Beyonc\u00e9's Empowerment Anthem"
                            },
                            {
                              "adjectives": "Courageous, Responsible, Collaborative",
                              "description": "Drawing from Marvel superheroes, this theme focuses on ethical behavior and kindness in daily life. It emphasizes that heroism lies in small acts of courage, taking responsibility, and working together to overcome challenges.",
                              "title": "Marvel's Everyday Heroes"
                            },
                            {
                              "adjectives": "Compassionate, Generous, Influential",
                              "description": "Inspired by Oprah Winfrey's philosophy, this theme explores kindness and emotional intelligence as keys to success and fulfillment. It encourages active listening, empathy, and using one's platform to uplift others.",
                              "title": "Oprah's Golden Rule"
                            }
                          ]
                        }
                      ],
                      "future_cultural_references": [
                        "Ancient Egyptian Mythology",
                        "Classic Fairy Tales",
                        "World Cuisines",
                        "Historical Figures",
                        "Contemporary Art Movements",
                        "Traditional Folk Music"
                      ]
                    },
                    "prompt_content": "For the keywords Kindness, Ethical behavior, Self-improvement, Emotional intelligence, can you generate creative themes that are inspired from diverse cultural references like Greek Mythology, Popular Books, Nature, Well-Known Movies, Pop Culture. Show up to five creative themes for each of the cultural references, with rich, detailed descriptions for each of them. Also include a column to highlight adjectives that best suit the theme, with the first letter of each adjective capitalized. Additionally, can you recommend six other possible cultural references without description for the future?."
                  };

                  

                  console.log('-------------------------Moreeeeeeeeeeeeeeee--------------------------');

                  if(stepData.current.step2_data.request.show_more_cultural_reference_status){
                    let themeObj = stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_more_cultural_reference_index]['themes'];
                    let showMoreThemeObj = step2Result.res.generated_plot_cards.cultural_references[0]['themes'];
                    const mergedTheme = [...themeObj, ...showMoreThemeObj];      
                    //stepData.current.step2_data.response=step2Result;
                    stepData.current.step2_data.response.more_plot_cards_res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_more_cultural_reference_index]['themes']=mergedTheme;
                  }else{
                    let themeObj = stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_cultural_reference_index]['themes'];
                    let showMoreThemeObj = step2Result.res.generated_plot_cards.cultural_references[0]['themes'];
                    const mergedTheme = [...themeObj, ...showMoreThemeObj];      
                    //stepData.current.step2_data.response=step2Result;
                    stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_cultural_reference_index]['themes']=mergedTheme;
                  }
                  step2Result.status = 1;
                  stepData.current.step2_data.loading=false;   
                  
                  //step2Result.status = 1;
                  //stepData.current.step2_data.response=step2Result;
                  //stepData.current.step2_data.response.res.generated_plot_cards.cultural_references[stepData.current.step2_data.request.selected_cultural_reference_index]['themes']=mergedTheme;

                  //stepData.current.step2_data.loading=false;                                    
                      
                  setData(stepData.current);
                  setIsLoading(false);     
                  setCount(count + 1);    
                  */
                  //--------------------------------- Plot Card Static Code End Here --------------------                  
                    
                }else{
                         
                    stepData.current.step2_data.response=step2Result;                    
                    stepData.current.step2_data.request.show_more_cultural_reference_status=false;
                    stepData.current.step2_data.loading=true;

                    let selected_keywords_separated_by_comma = '';
                    if(stepData.current.step2_data.request.selected_topics.length>0)
                    {
                      let selectedKeywords = stepData.current.step2_data.request.selected_topics.flatMap(a => a['Subtopics']);  
                      selected_keywords_separated_by_comma = selectedKeywords.join(', ');
                    }
   
                    setStep1Status(false);
                    setStep2Status(false);
                    setStep3Status(true);

                                
                    let cultural_references_array = shuffleArrayValues(culturalReferences());
                    const cultural_references_data = cultural_references_array.shift(); // Removes the first element   
                    let cultural_references = [cultural_references_data];                
                    let additional_cultural_references = cultural_references_array;
                    
                    if(!stepData.current.step2_data.request.selected_more_cultural_reference) 
                    {         
                        stepData.current.step2_data.loading=true;
                        setData(stepData.current);        
                        
                        //let submitData = {"selected_keywords": selected_keywords_separated_by_comma,"cultural_references": cultural_references.join(', '),"future_cultural_references":"six","no_of_creative_themes":"five"};
                        let submitData = {};
                        submitData.session_id=kathaiSession?.expiry ? kathaiSession?.expiry : '';
                        submitData.user_id=tokenInfo && tokenInfo?.uid ? tokenInfo?.uid : '';
                        if(data?.type==='prompt') {
                          submitData.prompt_changed = data?.data?.promptContent;          
                          submitData.prompt_type = 1;
                        }else{
                          submitData.selected_keywords=selected_keywords_separated_by_comma;
                          submitData.cultural_references=cultural_references.join(', ');
                          submitData.future_cultural_references="six";
                          submitData.no_of_creative_themes="three";                          
                          submitData.prompt_type = 0;
                          submitData.user_input=stepData.current.step1_data.request; 
                        }
                        //submitData.action = 'G3tPL0TCaRdSF0rCrEa1I0NkAthA12025';
                        
                        //--------------------------------- Plot Card Dynamic Code Start Here --------------------                                                   
                        const result = post(url.GET_PLOT_CARDS, submitData);
                        //const encryptData = encrypt(submitData); 
                        //const result = post(url.GET_PLOT_CARDS,JSON.parse(encryptData.toString()));
                        result.then((resdata) => {
                            //const decryptData = decrypt(resdata);
                            //const res = JSON.parse(decryptData.toString());
                            //const res = {"status": 0,"message":'Server too busy. Please try again after few minutes.'};
                            const res = resdata;
                            if(res.status==1){
                                
                                step2Result.res = res.data;
                                step2Result.status = res.status;
                                stepData.current.step2_data.response=step2Result;

                                stepData.current.step2_data.request.cultural_references=cultural_references;
                                stepData.current.step2_data.request.additional_cultural_references=additional_cultural_references;
                                stepData.current.step2_data.request.selected_cultural_reference=cultural_references[0];  
                                    
                                //setTimeout(() => {
                                  if(stepData.current.step2_data.response?.res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.step2_data?.request?.selected_cultural_reference)>=0){
                                      stepData.current.step2_data.request.selected_cultural_reference_index = stepData.current.step2_data.response?.res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.step2_data?.request?.selected_cultural_reference);
                                  }else if(stepData.current.step2_data.response?.res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.step2_data?.request?.selected_cultural_reference)>=0){
                                      stepData.current.step2_data.request.selected_cultural_reference_index = stepData.current.step2_data.response?.res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.step2_data?.request?.selected_cultural_reference);
                                  }else{
                                      stepData.current.step2_data.request.selected_cultural_reference_index = '-1'; 
                                  }   
                                  
                                  //stepData.current.step2_data.request.selected_cultural_reference_index = 0; 
                                  //console.log(stepData.current.step2_data.request.selected_cultural_reference_index);

                                  stepData.current.step2_data.loading=false;  
                                  
                                  localStorage.setItem("default_messsage_loading_status_for_plot_cards", true);
                              
                                  setData(stepData.current);
                                  setIsLoading(false);     
                                  setCount(count + 1);   

                                  //----------- User Activity Tracking Code Start Here ------------
                                  userTracking({action:data.action,user_input:stepData.current.step1_data.request,selected_keywords:selected_keywords_separated_by_comma,cultural_references:cultural_references});
                                  
                                  setTimeout(() => {
                                    userInputOutputTokensTracking({request_for:'cultural_references',input_tokens:res?.input_tokens,output_tokens:res?.output_tokens,prompt:res.data.prompt_content,request_json:submitData,response_json:res});
                                  }, 100);                                  
                                  //----------- User Activity Tracking Code End Here ------------
                                //},1000);
                                
                            }else{

                                step2Result.message = res.message;  
                                step2Result.status = res.status;                      
                                //step2Result.message = 'There is no keyword matches to the input given.';
                                //step2Result.status = 0;
                                stepData.current.step2_data.response=step2Result;
                                
                                 stepData.current.step2_data.loading=false;                                    
                            
                                setData(stepData.current);
                                setIsLoading(false);     
                                setCount(count + 1);   
                            }                                       
                        });                                                                   
                        //--------------------------------- Plot Card Dynamic Code End Here --------------------
                           
                        
                        
                        //--------------------------------- Plot Card Static Code Start Here -------------------- 
                        /*         
                        //----------- User Activity Tracking Code Start Here ------------
                        userTracking({action:data.action,user_input:stepData.current.step1_data.request,selected_keywords:selected_keywords_separated_by_comma,cultural_references:cultural_references});
                        //----------- User Activity Tracking Code End Here ------------
                        step2Result.res = {
                          "generated_plot_cards": {
                            "cultural_references": [
                              {
                                "name": "Greek Mythology",
                                "themes": [
                                  {
                                    "adjectives": "Compassionate, Reflective, Transformative",
                                    "description": "Inspired by King Midas, this theme explores how kindness can be more valuable than material wealth. It emphasizes the importance of human connections and the transformative power of empathy, encouraging individuals to value relationships over possessions.",
                                    "title": "The Golden Touch of Midas"
                                  },
                                  {
                                    "adjectives": "Selfless, Enlightening, Progressive",
                                    "description": "Drawing from Prometheus' act of giving fire to humanity, this theme celebrates ethical behavior that benefits others at personal cost. It encourages self-improvement through knowledge-sharing and acting for the greater good of society.",
                                    "title": "Prometheus' Gift"
                                  },
                                  {
                                    "adjectives": "Insightful, Strategic, Balanced",
                                    "description": "Inspired by the goddess of wisdom, this theme focuses on developing emotional intelligence through strategic thinking and balanced decision-making. It emphasizes the importance of combining intellect with empathy in personal growth.",
                                    "title": "Athena's Wisdom"
                                  }
                                ]
                              },
                              {
                                "name": "Popular Books",
                                "themes": [
                                  {
                                    "adjectives": "Compassionate, Just, Understanding",
                                    "description": "Inspired by Harper Lee's novel, this theme explores kindness and ethical behavior through the lens of empathy and standing up for what's right. It encourages individuals to see the world from others' perspectives and act with compassion.",
                                    "title": "To Kill a Mockingbird's Empathy"
                                  },
                                  {
                                    "adjectives": "Purposeful, Transformative, Spiritual",
                                    "description": "Drawing from Paulo Coelho's book, this theme focuses on self-improvement as a journey of discovering and fulfilling one's personal legend. It emphasizes the importance of following one's heart and learning from life's experiences.",
                                    "title": "The Alchemist's Personal Legend"
                                  },
                                  {
                                    "adjectives": "Authentic, Defiant, Empowering",
                                    "description": "Inspired by George Orwell's dystopian novel, this theme explores emotional intelligence as a form of resistance against oppression. It encourages individuals to maintain their humanity and empathy in challenging circumstances.",
                                    "title": "1984's Emotional Rebellion"
                                  }
                                ]
                              },
                              {
                                "name": "Nature",
                                "themes": [
                                  {
                                    "adjectives": "Generous, Supportive, Grounding",
                                    "description": "Inspired by trees' role in ecosystems, this theme explores kindness as a nurturing force. It encourages individuals to provide support and resources to others, creating a network of mutual growth and sustainability.",
                                    "title": "The Nurturing Tree"
                                  },
                                  {
                                    "adjectives": "Collaborative, Communicative, Purposeful",
                                    "description": "Drawing from bee behavior, this theme focuses on ethical conduct within communities. It emphasizes clear communication, collaboration, and working towards common goals for the benefit of all.",
                                    "title": "The Honeybee's Dance"
                                  },
                                  {
                                    "adjectives": "Transformative, Patient, Resilient",
                                    "description": "Inspired by the butterfly's life cycle, this theme explores self-improvement as a process of profound transformation. It encourages embracing change, having patience during growth periods, and emerging stronger.",
                                    "title": "The Butterfly's Metamorphosis"
                                  }
                                ]
                              },
                              {
                                "name": "Well-Known Movies",
                                "themes": [
                                  {
                                    "adjectives": "Genuine, Unassuming, Impactful",
                                    "description": "Inspired by the titular character, this theme explores how simple acts of kindness can have profound impacts. It encourages genuine, unpretentious goodwill and perseverance in the face of challenges.",
                                    "title": "Forrest Gump's Simple Kindness"
                                  },
                                  {
                                    "adjectives": "Courageous, Principled, Selfless",
                                    "description": "Drawing from Schindler's actions, this theme focuses on ethical behavior in extreme circumstances. It emphasizes moral courage, standing up for what's right, and making a difference in others' lives, even at personal risk.",
                                    "title": "Schindler's List of Ethics"
                                  },
                                  {
                                    "adjectives": "Resilient, Patient, Transformative",
                                    "description": "Inspired by Andy Dufresne's journey, this theme explores self-improvement as a long-term process requiring hope and perseverance. It encourages maintaining integrity and pursuing personal growth even in restrictive environments.",
                                    "title": "The Shawshank Redemption's Hope"
                                  }
                                ]
                              },
                              {
                                "name": "Pop Culture",
                                "themes": [
                                  {
                                    "adjectives": "Confident, Inspiring, Uplifting",
                                    "description": "Inspired by Beyonc\u00e9's music and persona, this theme explores self-improvement through self-confidence and empowerment. It encourages individuals to embrace their strengths, support others, and use their voice for positive change.",
                                    "title": "Beyonc\u00e9's Empowerment Anthem"
                                  },
                                  {
                                    "adjectives": "Courageous, Responsible, Collaborative",
                                    "description": "Drawing from Marvel superheroes, this theme focuses on ethical behavior and kindness in daily life. It emphasizes that heroism lies in small acts of courage, taking responsibility, and working together to overcome challenges.",
                                    "title": "Marvel's Everyday Heroes"
                                  },
                                  {
                                    "adjectives": "Compassionate, Generous, Influential",
                                    "description": "Inspired by Oprah Winfrey's philosophy, this theme explores kindness and emotional intelligence as keys to success and fulfillment. It encourages active listening, empathy, and using one's platform to uplift others.",
                                    "title": "Oprah's Golden Rule"
                                  }
                                ]
                              }
                            ],
                            "future_cultural_references": [
                              "Ancient Egyptian Mythology",
                              "Classic Fairy Tales",
                              "World Cuisines",
                              "Historical Figures",
                              "Contemporary Art Movements",
                              "Traditional Folk Music"
                            ]
                          },
                          "prompt_content": "For the keywords Kindness, Ethical behavior, Self-improvement, Emotional intelligence, can you generate creative themes that are inspired from diverse cultural references like Greek Mythology, Popular Books, Nature, Well-Known Movies, Pop Culture. Show up to five creative themes for each of the cultural references, with rich, detailed descriptions for each of them. Also include a column to highlight adjectives that best suit the theme, with the first letter of each adjective capitalized. Additionally, can you recommend six other possible cultural references without description for the future?."
                        };
                        step2Result.status = 1;
                        stepData.current.step2_data.response=step2Result;

                        stepData.current.step2_data.request.cultural_references=cultural_references;
                        stepData.current.step2_data.request.selected_cultural_reference=cultural_references[0];
                        
                        //console.log(stepData.current.step2_data.request.selected_cultural_reference);
                        
                        //console.log('-------------------11111111111111111-----------------');
                        if(stepData.current.step2_data.response?.res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.step2_data?.request?.selected_cultural_reference)>=0){
                            stepData.current.step2_data.request.selected_cultural_reference_index = stepData.current.step2_data.response?.res?.generated_plot_cards?.cultural_references?.findIndex(s => s.category == stepData?.step2_data?.request?.selected_cultural_reference);
                            //console.log('-------------------222222222222222-----------------'+stepData.current.step2_data.request.selected_cultural_reference_index);
                        }else if(stepData.current.step2_data.response?.res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.step2_data?.request?.selected_cultural_reference)>=0){
                            stepData.current.step2_data.request.selected_cultural_reference_index = stepData.current.step2_data.response?.res?.generated_plot_cards?.cultural_references?.findIndex(s => s.name == stepData?.step2_data?.request?.selected_cultural_reference);
                            //console.log('-------------------3333333333333333-----------------');
                        }else{
                            stepData.current.step2_data.request.selected_cultural_reference_index = '-1'; 
                            //console.log('-------------------44444444444444444-----------------');
                        }

                        stepData.current.step2_data.loading=false;                                    
                            
                        setData(stepData.current);
                        setIsLoading(false);     
                        setCount(count + 1);    
                        */
                        //--------------------------------- Plot Card Static Code End Here --------------------          
                    }
                }        
            }        
        }else{
            handleHeaderGreyBar(0);
            handleRemoveDefaultSlideBar(1);
            setStep1Status(false);
            setStep2Status(true);
            setStep3Status(false);
            setStep4Status(false);
            stepData.current.step2_data.response=''; 
            setData(stepData.current);   
        }     
    }else if(data.action==='step3' || data.action==='back_to_step3'){
      if(data.action==='step3')
      {
          
        if(data?.tone)
        {
          let selected_keywords_separated_by_comma = '';
          if(stepData.current.step2_data.request.selected_topics.length>0)
          {
            let selectedKeywords = stepData.current.step2_data.request.selected_topics.flatMap(a => a['Subtopics']);  
            selected_keywords_separated_by_comma = selectedKeywords.join(', ');
          }

          let step3Result = {};
          let creative_theme = stepData.current.step3_data.request.creative_theme;
          let theme_description = stepData.current.step3_data.request.theme_description;
          let theme_adjectives = stepData.current.step3_data.request.theme_adjectives;
          
          stepData.current.step3_data.tone_loading=true;
          let previousTone = stepData.current.step3_data.other_possible_tones;
          stepData.current.step3_data.other_possible_tones = data.tone;        
          let other_possible_tones_array = JSON.parse(JSON.stringify(stepData?.current.step3_data?.response?.data?.generate_email?.other_possible_tones));
          other_possible_tones_array.push(stepData?.current.step3_data?.response?.data?.generate_email?.tone);
          let objCurrentToneKeyIndex = other_possible_tones_array.findIndex(value => value === data.tone);
          if(objCurrentToneKeyIndex>-1){
            other_possible_tones_array.splice(objCurrentToneKeyIndex,1);                  
          } 
         
          if(previousTone)
          {
            let objPreviousToneKeyIndex = other_possible_tones_array.findIndex(value => value === previousTone);
            if(objPreviousToneKeyIndex>-1){
              other_possible_tones_array.splice(objPreviousToneKeyIndex,1);                  
            }
          }
        
          /*
          let submitData = {
            "additional_tones": stepData.current.step3_data.other_possible_tones,
            "creative_theme": creative_theme,
            "theme_description": theme_description,
            "No_of_Tones": "four",
            "other_possible_tones":  other_possible_tones_array.join(', ')
          };  
          */

          let submitData = {};
          submitData.session_id=kathaiSession?.expiry ? kathaiSession?.expiry : '';
          submitData.user_id=tokenInfo && tokenInfo?.uid ? tokenInfo?.uid : '';
          if(data?.type==='prompt') {
            submitData.prompt_changed = data?.data?.promptContent;          
            submitData.prompt_type = 1;
          }else{
            submitData.selected_keywords=selected_keywords_separated_by_comma;
            submitData.additional_tones=stepData.current.step3_data.other_possible_tones;
            submitData.creative_theme=creative_theme;
            submitData.theme_description=theme_description;
            submitData.No_of_Tones = "three";
            submitData.other_possible_tones = other_possible_tones_array.join(', ');
            submitData.prompt_type = 0;
            submitData.user_input=stepData.current.step1_data.request; 
          }
          //submitData.action = 'GEN3RAT6EMA1lW1THA3DIT234ONALT0NESF0rCrEa1I0NkAthA12025';

          const result = post(url.GENERATE_EMAIL_WITH_ADDITIONAL_TONES, submitData);
          //const encryptData = encrypt(submitData); 
          //const result = post(url.GENERATE_EMAIL_WITH_ADDITIONAL_TONES,JSON.parse(encryptData.toString()));
          result.then((resdata) => {
            //const decryptData = decrypt(resdata);
            //const res = JSON.parse(decryptData.toString());
            const res = resdata;
            if(res.status==1)
            {
                stepData.current.step3_data.response.data.generate_email.email = res.data.generate_email.email;
                //step3Result.data = res.data;
                stepData.current.step3_data.response.tone_status = res.status;

                //Update the Prompt Content
                stepData.current.step3_data.response.data.prompt_content = res.data.prompt_content;
            }else{
                step3Result.message = res.message;
                step3Result.status = res.status;
                //step3Result.message = 'There is no keyword matches to the input given to generate email.';
                //step3Result.status = 0;    
                //stepData.current.step3_data.response=step3Result;    
                stepData.current.step3_data.response.tone_message = res.message;  
                stepData.current.step3_data.response.tone_status = res.status;  
                
                //stepData.current.step3_data.response.tone_message = 'There is no keyword matches to the input given to generate email.';  
                //stepData.current.step3_data.response.tone_status = 0; 
            }

            stepData.current.step3_data.tone_loading=false;
                     
            setData(stepData.current);
            setIsLoading(false);     
            setCount(count + 1);                         
          });

        }else{

          setStep1Status(false);
          setStep2Status(false);
          setStep3Status(false);
          setStep4Status(true);
          
          //--------------------------------- Email Dynamice Code Start Here -------------------- 
          //console.log('---------------stepData.current--------------');
          //console.log(stepData.current);
                       
          if((!stepData.current.step3_data.response.data && !stepData.current.step3_data.response.tones) || (data.action_type==='refresh'))
          {
            let step3Result = {};
            stepData.current.step3_data.response=step3Result;  
            stepData.current.step3_data.loading=true;
  
            let selected_keywords_separated_by_comma = '';
            if(stepData.current.step2_data.request.selected_topics.length>0)
            {
              let selectedKeywords = stepData.current.step2_data.request.selected_topics.flatMap(a => a['Subtopics']);  
              selected_keywords_separated_by_comma = selectedKeywords.join(', ');
            } 
  
            let plot_card = stepData.current.step3_data.request.plot_card;
            let creative_theme = stepData.current.step3_data.request.creative_theme;
            let theme_description = stepData.current.step3_data.request.theme_description;   
            let theme_adjectives = stepData.current.step3_data.request.theme_adjectives;           
            
            let submitData = {};
            submitData.session_id=kathaiSession?.expiry ? kathaiSession?.expiry : '';
            submitData.user_id=tokenInfo && tokenInfo?.uid ? tokenInfo?.uid : '';
            if(data?.type==='prompt') {
              submitData.prompt_changed = data?.data?.promptContent;          
              submitData.prompt_type = 1;
            }else{
              submitData.selected_keywords=selected_keywords_separated_by_comma;
              submitData.creative_theme=creative_theme;
              submitData.theme_description=theme_description;
              submitData.No_of_Tones = "four";            
              submitData.prompt_type = 0;
              submitData.primary_tones="Professional and formal";
              submitData.user_input=stepData.current.step1_data.request;
            }
            submitData.action='G3NeRA7E3MaILF0rCrEa1I0NkAthA12025';

            const result = post(url.GENERATE_EMAIL, submitData);
            //const encryptData = encrypt(submitData); 
            //const result = post(url.GENERATE_EMAIL,JSON.parse(encryptData.toString()));
            result.then((resdata) => {
              //const decryptData = decrypt(resdata);
              //const res = JSON.parse(decryptData.toString());
              //const res = {"status": 0,"message":'Server too busy. Please try again after few minutes.'};
              const res = resdata;
              if(res.status==1)
              {
                  step3Result.data = res.data;
                  step3Result.status = res.status;
                  
                  stepData.current.step3_data.response=step3Result; 

                  localStorage.setItem("default_messsage_loading_status_for_generate_email", true);
                  
                  //----------- User Activity Tracking Code Start Here ------------
                  if(data?.action_type && data?.action_type==='refresh'){
                  }else{
                    userTracking({action:data.action,user_input:stepData.current.step1_data.request,selected_keywords:selected_keywords_separated_by_comma,plot_card:plot_card,theme:creative_theme,description:theme_description,characteristics:theme_adjectives});
                  }
                  //----------- User Activity Tracking Code End Here ------------

                  //----------- User Activity Tracking Code Start Here ------------
                  setTimeout(() => {
                    userTracking({action:data.action,user_input:stepData.current.step1_data.request,selected_keywords:selected_keywords_separated_by_comma,theme:creative_theme,description:theme_description,characteristics:theme_adjectives,email_subject:stepData?.current?.step3_data?.response?.data?.generate_email?.email?.subject,email_body:stepData?.current?.step3_data?.response?.data?.generate_email?.email?.body,mail_tone:stepData?.current?.step3_data?.response?.data?.generate_email?.tone,action_type:'email'});

                    setTimeout(() => {
                      userInputOutputTokensTracking({request_for:'generate_email',input_tokens:res?.input_tokens,output_tokens:res?.output_tokens,prompt:res.data.prompt_content,request_json:submitData,response_json:res});
                    }, 100);
                    
                  }, 2000);

                  
                  //----------- User Activity Tracking Code End Here ------------
                  
              }else{
  
                  step3Result.message = res.message;
                  step3Result.status = res.status;

                  stepData.current.step3_data.response=step3Result; 
                  
                  //step3Result.message = 'There is no keyword matches to the input given to generate email.';
                  //step3Result.status = 0;                                  
              }
  
              stepData.current.step3_data.loading=false;
              
              
              setData(stepData.current);
              setIsLoading(false);     
              setCount(count + 1);                         
            });    
          }       
                         
          //--------------------------------- Email Dynamic Code Start Here --------------------

          
         //--------------------------------- Email Static Code Start Here -------------------- 
         /*
         let step3Result = {};
         stepData.current.step3_data.response=step3Result;  
         stepData.current.step3_data.loading=true;
          
         let selected_keywords_separated_by_comma = '';
         if(stepData.current.step2_data.request.selected_topics.length>0)
         {
          let selectedKeywords = stepData.current.step2_data.request.selected_topics.flatMap(a => a['Subtopics']);  
          selected_keywords_separated_by_comma = selectedKeywords.join(', ');
         }         

         let creative_theme = stepData.current.step3_data.request.creative_theme;
         let theme_description = stepData.current.step3_data.request.theme_description; 
         let theme_adjectives = stepData.current.step3_data.request.theme_adjectives;          
          
         let submitData = {};
         submitData.selected_keywords=selected_keywords_separated_by_comma;
         submitData.creative_theme=creative_theme;
         submitData.theme_description=theme_description;
         submitData.No_of_Tones = "three";            
         submitData.prompt_type = 0;
         step3Result.data = {
              "generate_email": {
                  "email": {
                      "body": "Dear Colleagues,\n\nI hope this email finds you well. Today, we have an interesting twist to our Employee of the Month recognition.\n\nOur usual celebration of outstanding performance takes an unexpected turn as we acknowledge someone who has become 'The Talk of the Day' for an unusual reason. This individual has gained notoriety for consistently speaking about a particular incident, seemingly for the sake of mentioning it.\n\nWhile this may not align with our typical criteria for recognition, it has certainly captured the attention of many. It serves as a reminder that our actions and words, no matter how repetitive, can leave a lasting impression.\n\nLet's use this as an opportunity to reflect on how we communicate and the impact our words can have in our professional environment.\n\nThank you for your attention to this matter.\n\nBest regards,\nHR Department",
                      "subject": "Employee of the Month Announcement: The Talk of the Day"
                  },
                  "other_possible_tones": [
                      "Humorous and light-hearted",
                      "Sarcastic and ironic",
                      "Concerned and cautionary"
                  ],
                  "tone": "Professional and formal"
              },
              "genrated_emails_additional": {
                  "Concerned and cautionary": {
                      "email": {
                          "body": "Dear Team,\n\nI hope this email finds you well. I am writing to address a recent incident during our Employee of the Month recognition ceremony that has raised some concerns.\n\nIt has come to our attention that the recipient of the award may have been involved in a situation that contradicts our company's values and policies. While we celebrate achievements in sports, cultural activities, and professional accomplishments, we must also uphold our standards of discipline and integrity.\n\nWe are currently investigating the matter thoroughly. In the meantime, I urge everyone to refrain from speculation or gossip. Let's remember that our recognition programs are designed to motivate and inspire, not to create division or controversy.\n\nMoving forward, we will be reviewing our nomination and selection processes to ensure they align with our corporate values and ethical standards. Your input in this process will be valuable, and we welcome any suggestions for improvement.\n\nThank you for your understanding and cooperation during this time. Let's continue to focus on our collective goals and maintain the positive, action-oriented culture that defines our organization.\n\nBest regards,\n[Your Name]\nHuman Resources Director",
                          "subject": "Caution: Addressing Concerns About Recent Employee Recognition Incident"
                      },
                      "tone": "Concerned and cautionary"
                  },
                  "Humorous and light-hearted": {
                      "email": {
                          "body": "Dear Team,\n\nHold onto your seats, folks! It's time for our 'Employee of the Month' announcement, and boy, do we have a talker!\n\nMeet John 'The Chatterbox' Smith, our latest award recipient. John's claim to fame? He can talk about anything... and I mean ANYTHING! From the intricate details of our company's recycling policy to the proper technique for stapling documents, John's got an opinion.\n\nLegend has it, John once talked so much during a fire drill that the fire gave up and put itself out. Now that's what I call extinguishing a crisis with words!\n\nIn all seriousness (just kidding, there's no seriousness here), John's ability to keep conversations going has inadvertently led to some great ideas in our brainstorming sessions. Who knew that a 30-minute monologue about the office plants could lead to our new eco-friendly initiative?\n\nSo, let's raise our coffee mugs to John! May his words continue to flow like a river... preferably not during our lunch breaks.\n\nCongratulations, John! Your 'Employee of the Month' plaque comes with a complimentary set of throat lozenges. You're welcome!\n\nKeep talking the talk (and occasionally walking the walk),\n\nYour amused HR Team",
                          "subject": "The Talk of the Day: Our 'Employee of the Month' Who Talks the Talk!"
                      },
                      "tone": "Humorous and light-hearted"
                  },
                  "Sarcastic and ironic": {
                      "email": {
                          "body": "Dear Colleagues,\n\nIt's that time again when we celebrate mediocrity disguised as excellence. Please join me in congratulating our latest 'Employee of the Month,' John Doe, for his remarkable ability to talk endlessly about nothing.\n\nJohn has truly outdone himself this time, regaling us with tales of his 'heroic' efforts during the recent office supply crisis. Who knew that finding a working stapler could be such an epic adventure?\n\nLet's all take a moment to appreciate John's unparalleled skill in turning a 30-second anecdote into a 30-minute saga. His ability to make mountains out of molehills is truly inspirational.\n\nAs a reward for his 'outstanding' contribution, John will receive a certificate suitable for framing (or papier-mâché, whichever he prefers) and a reserved parking spot closest to the dumpsters.\n\nLet's give John a round of applause – but not too enthusiastically, lest we encourage more of the same.\n\nSincerely,\nYour totally impressed management team",
                          "subject": "Congratulations to Our 'Employee of the Month' - The Master of Empty Words"
                      },
                      "tone": "Sarcastic and ironic"
                  }
              },
              "prompt_content": "They have asked for I want to write an email for an awards and recognitions and For the keywords email,awards and recognitions, Employee of the month, email series, sports, culturals, discipline,corporate email, action and adventure, can you generate an email using the creative theme of the talk of the day such that it This one will talks about a person who talks for a namesake of a particular incident. After the email is generating, also show me the tone as Professinal and formal of this email. In addition to that recommend 3 other possible tones in which this email can be modified."
          };

          //----------- User Activity Tracking Code Start Here ------------
          userTracking({action:data.action,user_input:stepData.current.step1_data.request,selected_keywords:selected_keywords_separated_by_comma,theme:creative_theme,description:theme_description,characteristics:theme_adjectives});
          //----------- User Activity Tracking Code End Here ------------

          step3Result.status = 1; 
          
          stepData.current.step3_data.loading=false;
          stepData.current.step3_data.response=step3Result;          
          
          setData(stepData.current);
          setIsLoading(false);     
          setCount(count + 1);     
          
          //console.log('stepData?.current?.step3_data?.response?.data?.generate_email?.email?.body');
          //console.log(stepData?.current?.step3_data?.response?.data?.generate_email?.email?.subject);
          //console.log(stepData?.current?.step3_data?.response?.data?.generate_email?.email?.body);
          //console.log(stepData?.current?.step3_data?.response?.data?.generate_email?.tone);
          
          
          //----------- User Activity Tracking Code Start Here ------------
          setTimeout(() => {
          userTracking({action:data.action,user_input:stepData.current.step1_data.request,selected_keywords:selected_keywords_separated_by_comma,theme:creative_theme,description:theme_description,characteristics:theme_adjectives,email_subject:stepData?.current?.step3_data?.response?.data?.generate_email?.email?.subject,email_body:stepData?.current?.step3_data?.response?.data?.generate_email?.email?.body,mail_tone:stepData?.current?.step3_data?.response?.data?.generate_email?.tone,action_type:'email'});
          }, 1000);
          //----------- User Activity Tracking Code End Here ------------
          */
          //--------------------------------- Email Static Code Start Here --------------------

        }         

        /*
        step3Result.data = {
            "generate_email": {
                "email": {
                    "body": "Dear Team, As we gather to recognize our Employee of the Month, I'd like to introduce a creative theme that perfectly captures the essence of our celebration: The Wind of Change. Just as the wind ushers in new seasons and transforms landscapes, our outstanding employees bring fresh perspectives and drive transformation within our company. Today, we honor an individual whose innovative ideas and dedication have been a powerful force in shaping the future of our team. Like a refreshing breeze, their contributions have invigorated our projects, inspired colleagues, and propelled us towards new horizons. This employee's influence has been felt across departments, much like how the wind touches every corner of our world. Their ability to challenge the status quo and introduce novel solutions has been instrumental in our recent successes. As we celebrate their achievements, let us also reflect on how each of us can be a catalyst for positive change in our own spheres of influence. Join me in congratulating our Employee of the Month, whose spirit embodies the Wind of Change, continuously pushing us to evolve, adapt, and excel in an ever-changing business landscape. Their commitment to innovation and progress serves as an inspiration to us all. Let's carry this momentum forward, allowing the winds of change to guide us towards even greater accomplishments. Congratulations once again to our deserving Employee of the Month!",
                    "subject": "The Wind of Change: Celebrating Our Employee of the Month"
                },
                "other_possible_tones": [
                    "Formal and Professional",
                    "Enthusiastic and Energetic",
                    "Reflective and Thoughtful"
                ],
                "tone": "Inspirational"
            }
        };
        */

        /*
        step3Result.data = {
          "generate_email": {
            "email": {
                "body": "Dear Team,\n\nIn the world of sports, be it cricket, football, or volleyball, we often witness moments of extraordinary courage, unwavering determination, and true sportsmanship. These qualities remind me of the noble Gryffindor House from Harry Potter, known for their bravery and strength in the face of adversity.\n\nToday, I want to recognize and celebrate these very qualities in each of you. Like the members of Gryffindor, you have consistently demonstrated remarkable courage and determination in our workplace. Whether facing challenging projects, tight deadlines, or unexpected obstacles, you have stood strong, much like Gryffindor students confronting danger.\n\nYour sportsmanship in handling difficult situations with dignity, even when tensions run high and anger threatens to surface, is truly commendable. You've shown that true strength lies not just in winning, but in how we conduct ourselves during the game – be it on the field or in our professional lives.\n\nJust as Harry and his friends united to overcome seemingly insurmountable challenges, you have come together as a team, supporting one another and pushing through barriers. Your Gryffindor-like spirit has not only led to individual growth but has also strengthened our entire organization.\n\nLet's continue to embody these values of courage, determination, and sportsmanship in everything we do. Remember, it's not just about the end goal, but the integrity and dignity with which we pursue it.\n\nThank you for being our real-world Gryffindors. Your bravery and team spirit make our workplace truly magical.\n\nProudly,\n[Your Name]",
                "subject": "Celebrating Our Team's Gryffindor Spirit in Sports and Beyond"
            },
            "other_possible_tones": [
                "Motivational",
                "Appreciative",
                "Encouraging",
                "Empowering"
            ],
            "tone": "Inspirational"
          }
        };

        step3Result.status = 1;

        stepData.current.step3_data.loading=false;
        stepData.current.step3_data.response=step3Result;          
        
        setData(stepData.current);
        setIsLoading(false);     
        setCount(count + 1);       
        */                  

      }else{
        handleHeaderGreyBar(1);
        setStep1Status(false);
        setStep2Status(false);
        setStep3Status(true);
        setStep4Status(false);
      }          
    }
    
  }

  const userTracking = (data={}) => {
    
    let values = {};
    values.session=kathaiSession?.expiry ? kathaiSession?.expiry : '';
    values.prompt_id=userPromptID ? userPromptID : '';
    values.keyword_id=userKeywordID ? userKeywordID : '';
    values.plot_card_id=currentUserPlotCardID.current;
    //currentUserPlotCardID.current = userPlotCardID ? userPlotCardID : '';
    values.email_id=userEmailID ? userEmailID : '';

    if(data?.action_type=='email')
    {
      values.stepData = stepData.current;
    }    

    values.request_action = 'SendU53r1RaCk1ngforCCCE@Katha12025';

    let submitData = Object.assign({}, data, values);
   
    const encryptData = encrypt(submitData);      
    const res = post(user_url.USER_TRACKING,JSON.parse(encryptData.toString()));
    //const res = post(user_url.USER_TRACKING,submitData);
    res.then((data) => {
      const responseData = decrypt(data);
      const responseDetails = JSON.parse(responseData);
      //const responseDetails = data;
      if(responseDetails.status==1)
      {
        setUserPromptID(responseDetails.promptID);
        setUserKeywordID(responseDetails.keywordID);
        setUserPlotCardID(responseDetails.plotCardID);
        setUserEmailID(responseDetails.emailID);

        currentUserPromptID.current = responseDetails.promptID;
        currentUserKeywordID.current = responseDetails.keywordID;
        currentUserPlotCardID.current = responseDetails.plotCardID;
        currentUserEmailID.current = responseDetails.emailID;
        //currentUserPlotCardID.current = responseDetails.plotCardID;
      }      
    });
  };

  const userInputOutputTokensTracking = (data={}) => {
    
    let values = {};
    values.session=kathaiSession?.expiry ? kathaiSession?.expiry : '';
    /*
    values.prompt_id=userPromptID ? userPromptID : '';
    values.keyword_id=userKeywordID ? userKeywordID : '';
    values.plot_card_id=currentUserPlotCardID.current;
    values.email_id=userEmailID ? userEmailID : '';
    */
    
    values.prompt_id=currentUserPromptID.current ? currentUserPromptID.current : userPromptID ? userPromptID : '';
    values.keyword_id=currentUserKeywordID.current ? currentUserKeywordID.current : userKeywordID ? userKeywordID : '';
    values.plot_card_id=currentUserPlotCardID.current ? currentUserPlotCardID.current : userPlotCardID ? userPlotCardID : '';
    values.email_id=currentUserEmailID.current ? currentUserEmailID.current : userEmailID ? userEmailID : '';

    values.action = 'SendU53r1Npu10p3T1RaCk1ngforCCCE@Katha12025';

    let submitData = Object.assign({}, data, values);
   
    const encryptData = encrypt(submitData);      
    const res = post(user_url.USER_INPUT_OUTPUT_TOKENS_TRACKING,JSON.parse(encryptData.toString()));
    /*   
    res.then((data) => {
      const responseData = decrypt(data);
      const responseDetails = JSON.parse(responseData);         
    });
    */
  };  

  const [startDate, setStartDate] = useState(new Date());
  return <>
    {
      step1Status && 
      <Suspense fallback={""}><Step1 stepData={data} setData={setData} onActive={setActiveChild} ref={childRefForStep1} parentFunction={callbackData}/></Suspense>
    }
    {
      step2Status && 
      <Suspense fallback={""}><Step2 stepData={data} setData={setData} onActive={setActiveChild} count={count} ref={childRefForStep2} parentFunction={callbackData}/></Suspense>
    }
    {
      step3Status && 
      <Suspense fallback={""}><Step3 countValue={count} stepData={data} setData={setData} onActive={setActiveChild} count={count} ref={childRefForStep3} parentFunction={callbackData}/></Suspense>
    }
    {
      step4Status && 
      <Suspense fallback={""}><Step4 stepData={data} setData={setData} onActive={setActiveChild} count={count} userEmailID={userEmailID} ref={childRefForStep4} parentFunction={callbackData}/></Suspense>
    } 
    <Suspense fallback={""}><Authentication ref={childContinueOnMobileRef} parentFunction={handleComfirm} /></Suspense>   
  </>;
};

export default Dashboard; 