import React, { useState, useEffect, useRef, Suspense } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import { removeAuthToken } from '../../helpers/jwt-token-access/auth-token';

import { encrypt,decrypt } from "../../common/Crypto";
import * as url from '../../helpers/api-url/user_url_helper';
import { post } from '../../helpers/api/api_helper.js';

import logo from '../../assets/images/logo.png'
import mlogo from '../../assets/images/popup-logo.svg'
import gle from '../../assets/images/gle-icon.svg'
import icon7 from '../../assets/images/add-m.svg'
import icon8 from '../../assets/images/fullscreen.svg'
import icon9 from '../../assets/images/noti.svg'
import icon10 from '../../assets/images/menu.svg'
import icon13 from '../../assets/images/star-glyphs.svg'
import icon17 from '../../assets/images/notification.svg'
import icon18 from '../../assets/images/smiley-crying.svg'
import icon19 from '../../assets/images/sad-face.svg'
import icon20 from '../../assets/images/straight-face.svg'
import icon21 from '../../assets/images/happy-face.svg'
import icon22 from '../../assets/images/happy-face-smiley.svg'
import icon23 from '../../assets/images/check-inbox.svg'
import icon24 from '../../assets/images/unlock-img.svg'
import icon26 from '../../assets/images/header-logo.svg'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import * as feedback_url from '../../helpers/api-url/feedback_url_helper';
import * as auth_url from '../../helpers/api-url/authentication_url_helper';
import { setAuthToken } from '../../helpers/jwt-token-access/auth-token';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { faUser, faClock, faCalendarCheck, faBookmark, faRectangleList, faChartBar, faStar, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faKey, faArrowRightFromBracket, faCheck, faPencil, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useFormik, Field, Formik, Form  } from 'formik';
import * as Yup from 'yup';

import bootstrapForm from 'react-bootstrap/Form';

import { useAuth } from "../../components/Context/AuthContext"; // Adjust path as needed

import LanguageSwitcher from "../../components/Common/LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
const PopupBox = React.lazy(() => import("../../components/Common/PopupBox/PopupBox"));
const Authentication = React.lazy(() => import("../../components/Common/Authentication/Authentication"));

const Header = () => {

  const childRef = useRef(null);
  const { session, loginSession, logoutSession, isAuthenticated, tokenInfo, login, logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  //const [tokenInfo, setTokenInfo] = useState();
  const [profileMenuStatus, setProfileMenuStatus] = useState(false);
  const profileMenuStatusCurrent = useRef(0);
  const childPopupBoxRef = useRef(null);
  const [showLogin, setShowLogin] = useState(false);
  const handleLoginClose = () => setShowLogin(false);

  const [showSignUp, setShowSignUp] = useState(false);
  const handleSignUpClose = () => setShowSignUp(false);
  
  const [showFeedback, setShowFeedback] = useState(false);
  const handleFeedbackClose = () => setShowFeedback(false);
  const [showFeedbackRating, setShowFeedbackRating] = useState();
  const handleFeedbackRating = (arg,cmt) => { 
    setShowFeedbackRating(arg);
    if(arg<=3 && !cmt.trim())
    {
      setFeedbackSubmitStatus(0);
    }else{
      setFeedbackSubmitStatus(1);
    }    
  };
  
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState();  
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(); 
  const [feedbackSubmitStatus, setFeedbackSubmitStatus] = useState(0);   

  const addBodyClass = () => {
    document.body.classList.toggle('open'); // Add the class to the body
  }
  
  useState(() => {
    if(!session)
    {
      loginSession();          
    }   
  },[]);

  
  useState(() => {    
    //setTokenInfo(getTokenInfo());
    //const currentTime = 1742375692.856;
    //const tokenInfoExpiryTime = 1742355693.856;
    const currentTime = Date.now() / 1000; // Convert to seconds
    const tokenInfoExpiryTime = tokenInfo.exp;
    if (tokenInfoExpiryTime < currentTime) {
      logout();
    }
    
  }, [tokenInfo]);

  /*
  useState(() => {    
    setTokenInfo(getTokenInfo());
  }, [tokenInfo]);
  */

  const handleClick = (data) => {
    navigate("/" + data);
  };

  const handleSignoutt = (index) => {
    //localStorage.removeItem('token')
    if (window.confirm("Are you sure do you want to Logout?")) {
      removeAuthToken();
      logout();
      //navigate("/sign-in");
      navigate("/dashboard");
    }
  };

  const handleGenerateNewIdea = () => {
    navigate("/dashboard");
    //childPopupBoxRef.current.popupOpen('Are you sure do you want to Generate new idea?',{'boxType':'confirm','data':'redirect'});
  };

  const handleComfirm = (arg) => {   
    childRef.current.popupClose();
    if(arg=='signout')
    {
      removeAuthToken();
      logout();
      navigate("/dashboard");
    }    
  }

  const handleProfileMenu = () => {
    if (profileMenuStatusCurrent.current) {
      profileMenuStatusCurrent.current = false;
    } else {
      profileMenuStatusCurrent.current = true;
    }
    setProfileMenuStatus(profileMenuStatusCurrent.current);
  }

  const handleLogin = () => {
    childRef.current.signIn();
  }

  const handleSignUp = () => {
    childRef.current.signUp();
  }

  const handleSignout = () => {
    //logout();
    childRef.current.signOut();
  } 
    
  return <>
    <div class="header sticky-top no-sidebar">
      <div class="head-lft-logo" id="head-lft-logo-div"><img key="26" src={icon26} alt="" /></div>
      <div class="head-rgt">
        
      <div id="head-rgt-lft-id">&nbsp;</div>
        <div id="generate-new-idea-id">
          <Button variant="primary idea-btn" onClick={handleGenerateNewIdea}><strong>Generate new idea</strong> <span><img src={icon13} alt="" /></span></Button>
        </div>
        <div class="main-menu" style={{ display: (isAuthenticated ? 'block' : 'none') }}>         
          <ul onClick={handleProfileMenu}>
            <li class={(profileMenuStatus ? 'active' : '')}>
              <div class="n-cnt">
                <a href="javascript:void(0);">{tokenInfo?.DisplayShortName ? tokenInfo?.DisplayShortName : ''} <span></span></a>
                <ul class="dropdown">
                  {
                    /*
                    <li><a href="javascript:void(0);" onClick={() => handleClick('user/profile')} title="Profile">Profile</a></li>
                    <li><a href="javascript:void(0);" onClick={() => handleClick('user/change-password')} title="Change Password">Change Password</a></li>
                    */
                  } 
                  <li>{tokenInfo?.email}</li>  
                  <li><a href="javascript:void(0);" onClick={() => handleSignout()} title="Sign out" class="sign-out">Sign out <strong></strong></a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        {
          !isAuthenticated &&
          <div class="login-menu">
            <ul>
              <li><Button variant="primary sign-btn" onClick={() => handleSignUp()}><strong>Sign up</strong> <span><FontAwesomeIcon icon={faUserPlus} style={{ color: '#000', fontSize: '20px', marginLeft: '1px' }} /></span></Button></li>
              <li><Button variant="primary login-btn" onClick={() => handleLogin()}><strong>Login</strong> <span><FontAwesomeIcon icon={faUser} style={{ color: '#000', fontSize: '20px', marginLeft: '1px' }} /></span></Button></li>
              {
                /*
                <li>
                  <div class="dropdown">
                    <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle text-center" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                                    
                      <p>{tokenInfo?.DisplayName} <br/> <span>{tokenInfo?.Role}</span></p>
                    </a>
                    <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                      <li><a class="dropdown-item" href="javascript:void(0);" onClick={() => handleClick('user/user-info/profile')} title={t('header.Profile.text')}>{t('header.Profile.text')}</a></li>
                      <li><hr class="dropdown-divider" /></li>
                      <li><a class="dropdown-item" href="javascript:void(0);" onClick={() => handleClick('user/change-password')} title={t('header.ChangePassword.text')}>{t('header.ChangePassword.text')}</a></li>
                      <li><hr class="dropdown-divider" /></li>
                      <li><a class="dropdown-item" href="javascript:void(0);" onClick={() => handleSignout()} title={t('header.signOut.text')}>{t('header.signOut.text')}</a></li>
                    </ul>
                  </div>
                </li>
                */
              }
            </ul>
          </div>
        }
        
      </div>
      <Suspense fallback={""}><PopupBox ref={childPopupBoxRef} parentFunction={handleComfirm}/></Suspense>
    </div>

    <Suspense fallback={""}><Authentication ref={childRef} parentFunction={handleComfirm} /></Suspense>
  </>
  //}  
}

export default Header;