import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getTokenInfo } from '../../helpers/jwt-token-access/auth-token';

const Footer = () => {
    const { t } = useTranslation();
    const [tokenInfo, setTokenInfo] = useState();

    useState(() => {      
        setTokenInfo(getTokenInfo());                      
    },[tokenInfo]);

    return (
        <div className="footer">  
            <p className='small'>{t('footer.footerTextPoweredBy.text')} {tokenInfo?.CompanyName} | {t('footer.footerTextAllRightReserved.text')}</p>
        </div>
    )
}

export default Footer;