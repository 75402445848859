//LOGIN
export const SEND_OTP = "/site/send-otp";
export const VALIDATE_OTP = "/site/validate-otp";
export const ACTIVATION_STATUS = "/site/activation-status";

export const SEND_SIGN_UP_OTP = "/site/send-sign-up-otp";
export const VALIDATE_SIGN_UP_OTP = "/site/validate-sign-up-otp";

export const SIGN_IN_WITH_GOOGLE = "/site/sign-in-with-google";

//REGISTRATION
export const SEND_LINK = "/site/send-link";
export const VALIDATE_LINK = "/site/validate-link";

export const LOGIN = "/site/login";
export const CHANGE_PASSWORD = "/master/user/change-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";