import { jwtDecode } from "jwt-decode";

export function getAuthToken() {
  return localStorage.getItem("token");
  //return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MzAxMTgwMDYuNTI1ODY1LCJuYmYiOjE3MzAxMTgwMDYuNTI1ODY1LCJleHAiOjMwMjYxMTgwMDYuNTI1ODY1LCJ1aWQiOjEsImVtYWlsIjoiYWRtaW5AYWVzLmluIiwiRGlzcGxheU5hbWUiOiJNb25pIFJveSIsInJlc291cmNlX2FjY2VzcyI6MSwiUm9sZSI6IkFkbWluIiwiQ29tcGFueU5hbWUiOiJBRVMiLCJydWxlcyI6eyJyZXNvdXJjZV9hY2Nlc3MiOjEsInByaXZpbGVnZXMiOltdfSwicm9sZV9pZCI6MX0.3CnHqSCROmrxLKpl2xZmlIZkd7YjPU1nIzQGF3G31lI';
}

export function getTokenInfo() {
  try {
    const token = getAuthToken();     
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
}

export function setAuthToken(token) {
  localStorage.setItem('token',token);
}

export function removeAuthToken() {
  localStorage.removeItem('token');
}
