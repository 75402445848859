import React, { useState, useRef, Suspense, useEffect  } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import bootstrapForm from 'react-bootstrap/Form';
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';

import icon01 from '../../assets/images/contact-arw.svg';
import icon02 from '../../assets/images/thank-img.svg';
import icon03 from '../../assets/images/error-icon.svg';

import { useFormik, Field, Formik, Form  } from 'formik';
import * as Yup from 'yup';

import * as url from '../../helpers/api-url/common_url_helper';
import { encrypt,decrypt } from "../../common/Crypto";
import { post } from '../../helpers/api/api_helper.js';
import { getMessage,getValidationRequiredMessage,getValidationCharacterLimitMessage } from "../../common/Config";

const ContactUs = () => {

  const [showError, setShowError] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {    
    document.body.classList.remove('no-sidebar');
    document.getElementById("head-rgt-lft-id").style.display='none'; 
  },[]);

  useEffect(() => {
    const handleResize = () => {
      const winHeight = window.innerHeight;
      if(winHeight>824)
      {        
        document.getElementById('main-area').style.minHeight = (winHeight-101)+'px'; // header : 24px, footer:35px;
      }else{
        document.getElementById('main-area').style.minHeight = '700px';        
      }    
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  const validationSchema = Yup.object({
    name: Yup.string().trim().max(255, "Name cannot exceed 255 characters").required('Name is required'),
    email: Yup.string().trim().max(255, "Email cannot exceed 255 characters").required('Email is required').email('Invalid email address'),
    comment: Yup.string().trim().max(500, 'Comment').required('Comment is required')
  });

  const initialValues = {     
    name: '',
    email: '',
    comment: ''   
  };

  const handleSubmit = async (values, { setFieldError, setSubmitting, resetForm }) => {
    setIsLoading(true);
    values.action='C0n1AcTA13mA1LCrEa1I0NkAthA12025';    
    const encryptData = encrypt(values);      
    const res = post(url.CONTACT_US,JSON.parse(encryptData.toString()));
    res.then((data) => {
     
      const decryptData = decrypt(data);
      const decryptedData = JSON.parse(decryptData.toString());      
      if (decryptedData.status) {
        resetForm();
        setIsLoading(false);  
        setSuccessMessage(decryptedData.message);
        setTimeout(function(){          
          setSuccessMessage();
        },5000);
        /*
        childPopupBoxRef.current.changeAltMessage(getMessage(data.message,{'name':'Change Password'}),{'className': 'alert-success'});
        setTimeout(function(){          
          childPopupBoxRef.current.popupClose();
        },1500);
        */
      } else {  
        if(data?.message && Object.keys(data.message).length>0)
        {
          Object.entries(data.message).map(([key, value]) => {
            //console.log(key+'---'+value)
            setFieldError(key,value);
          });
        } 
        //setIsLoading(false);                     
        setShowError(1);
        setErrorMessage(data.password);
      }        
    });
  };

  const handleComfirm = (row) => {

  };

  function validateEmail(value) {
    let error;
    if (!value) {
      error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }

  return <>
    <Container fluid id="main-area">
      <div className="p-10">
        <Formik
            htmlFor="formData"
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            /*
            onSubmit={(values, { setFieldError }) => {
              //onSubmit(values,setFieldError)
            }}
            */
          >
          {({ errors, touched, values, setFieldValue,resetForm  }) => (
          <Form id="formData">
            <Col lg={12}>
            <div className="get-touch">  
              <h2>Get in Touch <span><img src={icon01} alt="" /></span></h2>
              <p>Have a question or want to learn more? Send me a message and I'll<br></br> get back to you as soon as possible.</p>
              <div className="get-touch-inner">          
                <ul>
                    <li>
                      <Field
                        name="name"
                        id="name"
                        type="text"                                  
                        className={`form-control ${touched.name && errors.name ? 'is-invalid' : ''}`}
                        placeholder="Your name"
                      />                               
                      {touched.name && errors.name ? (
                        <div className="invalid-feedback">{errors.name}</div>
                      ) : null}                                   
                  </li>
                  <li>
                    <Field
                      name="email"
                      id="email"
                      type="text"
                      validate={validateEmail} 
                      className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                      placeholder="Your email"
                    />
                    {touched.email && errors.email ? (
                      <div className="invalid-feedback">{errors.email}</div>
                    ) : null}  
                  </li>
                
                  <li>
                    <Field
                      as="textarea"
                      rows="2"
                      name="comment"
                      id="comment"
                      className={`form-control ${touched.comment && errors.comment ? 'is-invalid' : ''}`}
                      placeholder="Your message"
                    />
                    {touched.comment && errors.comment ? (
                      <div className="invalid-feedback">{errors.comment}</div>
                    ) : null}
                  </li>

                  <li>
                    <Button variant="primary" className='submit' type="submit" form="formData" title="Submit" disabled={isLoading===true}>
                      Submit
                      {
                        (isLoading===true) &&
                        <div class="spinner-border spinner-border-sm" role="status"><span class="sr-only"></span></div>
                      }
                    </Button>               
                    </li>

                    </ul>
                    {
                      successMessage && 
                      <div class="thank-msg">
                        <span><img src={icon02} alt="" /></span>
                        <p>{successMessage}</p>
                      </div>                     
                    } 
                    {
                      errorMessage &&
                      <div class="thank-msg error">
                        <span><img src={icon03} alt="" /></span>
                        <p>Error Message</p>
                      </div>
                    }                       
                  </div>
                </div>       
            </Col>
          </Form>
          )}
        </Formik>
      </div>
    </Container>    
    </>;
};

export default ContactUs; 