let subUrl = "";
var serviceUrl = "";

serviceUrl = "/master";

export const LIST_USER = serviceUrl + subUrl + "/user/index";
export const VIEW_USER = serviceUrl + subUrl + "/user/view";
export const ADD_USER = serviceUrl + subUrl + "/user/create";
export const UPDATE_USER = serviceUrl + subUrl + "/user/update";
export const DELETE_USER = serviceUrl + subUrl + "/user/delete";
export const ACTIVE_DEACTIVE_USER = serviceUrl + subUrl + "/user/common-update";

export const SIGN_IN_USER = subUrl + "/site/signup";

export const USER_TRACKING = subUrl + "/site/user-tracking";

export const USER_INPUT_OUTPUT_TOKENS_TRACKING = subUrl + "/site/user-input-output-tokens-tracking";

