import React, { useState, useRef, Suspense, useEffect  } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import bootstrapForm from 'react-bootstrap/Form';
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';

import icon01 from '../../assets/images/contact-arw.svg';
import icon02 from '../../assets/images/thank-img.svg';

import { useFormik, Field, Formik, Form  } from 'formik';
import * as Yup from 'yup';

import * as url from '../../helpers/api-url/common_url_helper';
import { encrypt,decrypt } from "../../common/Crypto";
import { post } from '../../helpers/api/api_helper.js';
import { getMessage,getValidationRequiredMessage,getValidationCharacterLimitMessage } from "../../common/Config";

const Favourites = () => {

  useEffect(() => {    
    document.body.classList.remove('no-sidebar');
    document.getElementById("head-rgt-lft-id").style.display='none';    
  },[]);

  useEffect(() => {
    const handleResize = () => {
      const winHeight = window.innerHeight;
      if(winHeight>824)
      {        
        document.getElementById('main-area').style.minHeight = (winHeight-101)+'px'; // header : 24px, footer:35px;
      }else{
        document.getElementById('main-area').style.minHeight = '700px';        
      }    
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  

  return <>
    <Container fluid id="main-area">
      <Row className="p-10">
        <Col lg={12}>
          <div className="get-touch">
            <h2>Favourites</h2>  
            <p>Favourites will be available soon. Stay tuned for updates!</p>            
          </div>       
        </Col>          
      </Row>
    </Container>    
    </>;
};

export default Favourites; 