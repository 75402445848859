import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Select from "react-dropdown-select";
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";

import icon16 from '../../../assets/images/send-icon.svg';
import icon17 from '../../../assets/images/vector.svg';


import { faUser, faClock, faCalendarCheck, faBookmark, faRectangleList, faChartBar, faHeart, faCopy,  faEdit } from '@fortawesome/free-regular-svg-icons';
import { faKey, faArrowRightFromBracket,faCheck, faPencil, faChevronLeft, faArrowRotateRight, faShareFromSquare, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Step4 = () => {
  const { t } = useTranslation();
  const options = [
    {
      value: 1,
      label: "Leanne Graham"
    },
    {
      value: 2,
      label: "Ervin Howell"
    }
  ];
  const [startDate, setStartDate] = useState(new Date());
  return <>
    <Container fluid id="main-area">
      <Row className="p-10 main-lft">
        <Col lg={12}>
          <Card className='border-0 p-15'> 
          
          <div class="award-inner">
          
          <div class="edit-sec">
          I want to send an email for awards and recognitions...
          <span><FontAwesomeIcon icon={faPencil} style={{color:'#000000', fontSize:'20px', marginLeft:'1px'}} /></span>
          </div>

          <div class="edit-sec">
          <input type="text" placeholder="I want to send an email for awards and recognitions..."></input>
          <span><img src={icon16} alt="" /></span>
          </div>

         <div class="award-inner-pad">
          <div class="main-title"><span>K</span> <div class="award-rgt">Here's a sample email for an awards and recognition event using the "Athena’s Counsel" theme. <strong><img src={icon17} alt="" /></strong></div></div>

          <Scrollbars className="scroll-div">
            <div class="subject-sec">
              <div class="tone-lst">
                <h3>Tone :</h3>
                <ul>
                  <li class="active">Celebratory and Motivational</li>
                  <li>Formal and Professional</li>
                  <li>Heartfelt and Appreciative</li>
                  <li>Inspirational and Reflective</li>
                </ul>
              </div>

              <div class="subject-cnt">
                <h6>Subject: Honoring Courage and Determination: The Gryffindor Award for [Employee's Name]!</h6>
                <h5>Dear Team,</h5>
                <p>As we continue to recognize the outstanding contributions of our team members, we are excited to introduce a new creative theme for this month’s Employee of the Month award—The Gryffindor Award. Inspired by the courageous and determined characters of Gryffindor House in Harry Potter, this award highlights the bravery, resilience, and unwavering determination shown by an exceptional employee who stands strong in the face of challenges and adversity.</p>
                <p>Today, we are proud to present <span>[Employee’s Name]</span> with The Gryffindor Award, recognizing their incredible strength, courage, and dedication to our company. Much like the members of Gryffindor House, [Employee’s Name] has consistently demonstrated bravery and resilience, rising to every challenge with a spirit that inspires everyone around them.</p>
                <p>Through their determination and leadership, [Employee’s Name] has not only overcome difficult obstacles but has also led the team through complex projects with confidence and composure. Whether it was [mention specific project or challenge] or <span>[mention another key contribution], [Employee’s Name]</span> showed the kind of fortitude that drives our team forward and sets a shining example for others.</p>
                <p>This recognition is more than just an acknowledgment of hard work—it’s a celebration of the courage that <span>[Employee’s Name] </span> embodies daily. Just as Gryffindors stand firm in their values and rise to every occasion, <span>[Employee’s Name]</span> has led with heart and conviction, making them a true hero of our team. Please join me in congratulating , <span>[Employee’s Name]</span> for their well-deserved recognition as our Gryffindor Award recipient! Their courage and determination are an inspiration to us all, and we look forward to many more successes together.</p>
              </div>
            </div>
          </Scrollbars>

          </div>
            <div class="show-btn full-width">
            <div class="show-lft"><button type="button" class="btn btn-primary go-back">Go back <span><FontAwesomeIcon icon={faChevronLeft} style={{color:'#fff', fontSize:'14px', marginLeft:'5px'}} /></span></button></div>
            <div class="show-rgt">
               <button type="button" class="btn btn-primary share-btn"><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_40000133_5244)">
<path d="M14.5835 1.59747C13.6451 1.61207 12.7272 1.87402 11.9224 2.35687C11.1177 2.83971 10.4546 3.52636 10.0001 4.34747C9.54566 3.52636 8.88257 2.83971 8.07782 2.35687C7.27308 1.87402 6.35517 1.61207 5.41679 1.59747C3.92091 1.66247 2.51155 2.31685 1.49661 3.41767C0.481678 4.51848 -0.0563308 5.97624 0.000128002 7.47247C0.000128002 11.2616 3.98846 15.4 7.33346 18.2058C8.08031 18.8334 9.02459 19.1775 10.0001 19.1775C10.9757 19.1775 11.9199 18.8334 12.6668 18.2058C16.0118 15.4 20.0001 11.2616 20.0001 7.47247C20.0566 5.97624 19.5186 4.51848 18.5036 3.41767C17.4887 2.31685 16.0793 1.66247 14.5835 1.59747ZM11.596 16.9308C11.1493 17.307 10.5841 17.5132 10.0001 17.5132C9.41617 17.5132 8.85097 17.307 8.40429 16.9308C4.12263 13.3383 1.66679 9.89164 1.66679 7.47247C1.60983 6.41807 1.9721 5.38392 2.6746 4.59556C3.37709 3.8072 4.36282 3.3286 5.41679 3.26414C6.47077 3.3286 7.45649 3.8072 8.15899 4.59556C8.86149 5.38392 9.22376 6.41807 9.16679 7.47247C9.16679 7.69349 9.25459 7.90545 9.41087 8.06173C9.56715 8.21801 9.77911 8.30581 10.0001 8.30581C10.2211 8.30581 10.4331 8.21801 10.5894 8.06173C10.7457 7.90545 10.8335 7.69349 10.8335 7.47247C10.7765 6.41807 11.1388 5.38392 11.8413 4.59556C12.5438 3.8072 13.5295 3.3286 14.5835 3.26414C15.6374 3.3286 16.6232 3.8072 17.3257 4.59556C18.0282 5.38392 18.3904 6.41807 18.3335 7.47247C18.3335 9.89164 15.8776 13.3383 11.596 16.9275V16.9308Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_40000133_5244">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
</span></button>
               <button type="button" class="btn btn-primary share-btn"><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.1328 10C18.7113 9.99449 18.3545 10.3101 18.3086 10.7292C17.9196 15.3289 13.8754 18.7424 9.27564 18.3534C4.67587 17.9643 1.26244 13.9201 1.6515 9.32031C2.04056 4.72055 6.08474 1.30711 10.6845 1.69613C12.676 1.86457 14.5413 2.74105 15.942 4.16664H13.3403C12.8801 4.16664 12.507 4.53973 12.507 4.99996C12.507 5.4602 12.8801 5.83328 13.3403 5.83328H16.7928C17.6473 5.83281 18.3399 5.14023 18.3403 4.28578V0.83332C18.3403 0.373086 17.9672 0 17.507 0C17.0468 0 16.6737 0.373086 16.6737 0.83332V2.565C12.5606 -1.11035 6.24681 -0.755508 2.57146 3.35758C-1.10389 7.47066 -0.749049 13.7844 3.36404 17.4598C7.47712 21.1351 13.7909 20.7803 17.4662 16.6672C18.8949 15.0683 19.7712 13.0521 19.9653 10.9166C20.0081 10.4552 19.6687 10.0464 19.2072 10.0036C19.1825 10.0013 19.1577 10.0001 19.1328 10Z" fill="black"/>
</svg>
</span></button>
               <button type="button" class="btn btn-primary share-btn"><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_40000133_5255)">
<path d="M14.1667 15.8333C14.1667 18.1333 12.3 20 10 20H4.16667C1.86667 20 0 18.1333 0 15.8333V9.99999C0 7.69999 1.86667 5.83332 4.16667 5.83332H5C5.45833 5.83332 5.83333 6.20832 5.83333 6.66665C5.83333 7.12499 5.45833 7.49999 5 7.49999H4.16667C2.79167 7.49999 1.66667 8.62499 1.66667 9.99999V15.8333C1.66667 17.2083 2.79167 18.3333 4.16667 18.3333H10C11.375 18.3333 12.5 17.2083 12.5 15.8333C12.5 15.375 12.875 15 13.3333 15C13.7917 15 14.1667 15.375 14.1667 15.8333ZM19.275 4.90832L14.7667 0.249987C14.45 -0.0833461 13.9167 -0.0916794 13.5917 0.233321C13.2667 0.558321 13.25 1.08332 13.575 1.40832L17.8583 5.82499H10.8333C8.53333 5.82499 6.66667 7.69165 6.66667 9.99165V14.1583C6.66667 14.6167 7.04167 14.9917 7.5 14.9917C7.95833 14.9917 8.33333 14.6167 8.33333 14.1583V9.99165C8.33333 8.61665 9.45833 7.49165 10.8333 7.49165H17.85L13.5667 11.9083C13.25 12.2417 13.25 12.7667 13.5833 13.0833C13.7417 13.2417 13.9583 13.3167 14.1667 13.3167C14.375 13.3167 14.6 13.2333 14.7667 13.0667L19.2667 8.42499C20.2417 7.44999 20.2417 5.86665 19.275 4.89999V4.90832Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_40000133_5255">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
</span></button>
              <button type="button" class="btn btn-primary copy">Copy <span><FontAwesomeIcon icon={faCopy} style={{color:'#fff', fontSize:'14px', marginLeft:'5px'}} /></span></button></div>
            </div>
          </div> 
            
          </Card>
        </Col>
      </Row>
    </Container></>;
};

export default Step4; 