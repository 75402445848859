import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import translationEN from "./translations/en/common.json";
import translationEN1 from "./translations/en/translation.json";
import translationSP from "./translations/sp/common.json";
import translationSP1 from "./translations/sp/translation.json";
import translationGR from "./translations/sp/common.json";
import translationGR1 from "./translations/gr/translation.json";
import translationIT from "./translations/it/common.json";
import translationIT1 from "./translations/it/translation.json";
import translationRS from "./translations/rs/common.json";
import translationRS1 from "./translations/rs/translation.json";

// the translations
const resources = {
  en: {
    translation: { ...translationEN, ...translationEN1}
  },
  sp: {
    translation: { ...translationSP, ...translationSP1}
  },
  gr: {
    translation: { ...translationGR, ...translationGR1}
  },
  it: {
    translation: { ...translationIT, ...translationIT1},
  },
  rs: {
    translation: { ...translationRS, ...translationRS1},
  }, 
}

const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en")
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
